import {
    GET_CIVIL_RESOURCE_PLANNING, GET_CIVIL_PROJECT_NAMES, GET_CIVIL_PROCESS_NAMES,
    GET_CIVIL_TASK_NAMES, GET_CIVIL_RESOURCE_NAMES,
    GET_CIVIL_RESOURCE_TYPE_PLANNING,
    GET_CIVIL_BUDGET_NAMES,
    GET_CIVIL_PROJECT, GET_CIVIL_PROJECT_PLANNIG_STAGE,
    GET_CIVIL_PROJECT_TEAM_ROLE, GET_CIVIL_PROJECT_PLAN_LIST,
    GET_CIVIL_PROJECT_PLAN_TEAM, GET_CIVIL_PROJECT_SITE_LOCATION,
    GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT, GET_CIVIL_WBS,
    GET_CIVIL_PROJECT_PROCESS_PLAN, GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS,
    GET_CIVIL_PROJECT_PROCESS_TASK, GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK,
    GET_TASK_DEPENDENCY_TYPE, GET_CIVIL_PROJECT_ROLE, GET_CIVIL_PROJECT_PLAN_FILTER_STAGE,
    GET_CIVIL_PROJECT_PROCESS_TASK_PLAN, GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY,
    GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY, GET_CIVIL_MATERIAL,
    GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL, GET_CIVIL_PROJECT_ACTIVITY_MASTER, GET_CIVIL_PROJECT_TASK_UOM, GET_CIVIL_PROJECT_WBS,
    GET_CIVIL_PROJECT_TASK, GET_CIVIL_PROCESS_M, GET_CIVIL_RESOURCE, GET_CIVIL_RESOURCE_CATEGORY,
    GET_CIVIL_PROJECT_RESOURCE_1, GET_CIVIL_OBJECT_PRODUCTIVITY,
    GET_CIVIL_RESOURCE_ALL, GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE, GET_CIVIL_PROJECT_ACTIVITY_DETAIL, GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY,

    GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY, GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE,
    GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY,
    GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE, GET_CIVIL_PROJECT_RESOURCE_EXPORT,
    COPY_CIVIL_PROJECT_RESOURCE, GET_CIVIL_OBJECT_PRODUCTIVITY_EXPORT, COPY_CIVIL_PROJECT_PRODUCTIVITY,
    GET_CIVIL_RESOURCE_EXPORT,
    GET_CIVIL_PROJECT_PLAN_LIST_EXPORT, GET_CIVIL_PROJECT_TASK_UOM_EXPORT, GET_CIVIL_PROCESS_M_EXPORT, GET_CIVIL_PROJECT_TASK_EXPORT,
    GET_CIVIL_PROJECT_ROLE_EXPORT, GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE_EXPORT,
    GET_CIVIL_PROJECT_ACTIVITY_EXPORT, GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT, 
    GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT,GET_CIVIL_PROJECT_BUDGET,
    GET_CIVIL_PROJECT_WBS_DETAIL,GET_CIVIL_PROJECT_PLAN_PENDING,GET_CIVIL_PROJECT_SCHEDULE,GET_CIVIL_RESOURCE_IDENTIFICATION,GET_CIVIL_MATERIAL_FOR_ACTIVITY

} from './types';

const initialStateChart = {
    labels: [],
    datasets: [],
};
const initialState = {
    civilResourcePlanning: initialStateChart,
    civilResourceTypePlanning: initialStateChart,
    civilBudgetPlanning: initialStateChart,
    getProjectNames: [],
    getProcessNames: [],
    getTaskNames: [],
    getResourceNames: [],
    // courtCasePendingReminderCount : 0,
    // pendingLiaisonReports : [], 
    // pendingLiaisonReportsCount : 0,
    getCivilProjects: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectPlanningStage: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectTeamRole: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectPlanList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectPlanTeam: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectSiteLocation: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectPlanFilterProject: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilWbs: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectProcessPlan: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectProcessPlanFilterProcess: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectProcessTask: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getTaskDependencyType: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectTaskPlanFilterTask: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectRole: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    createCivilProjectRole: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    updateCivilProjectRole: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectPlanFilterStage: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectProcessTaskPlan: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectProcessTaskPlanLocationQuantity: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectTaskMaterialCategory: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectTaskMaterial: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectTaskPlanMaterial: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectActivityMaster: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectTaskUOM: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectWbs: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectTask: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProcess: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilResource: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilResourceCategory: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectResource: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectResourceProductivity: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilResourceAll: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectTaskPlanResource: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectActivityDetail: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectDependableActivity: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

    getCivilProjectResourceFilterResourceCategory: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectResourceFilterResource: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectResourceProductivityFilterResourceCategory: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectResourceProductivityFilterResource: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectResourceExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    copyCivilProjectResource: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectResourceProductivityExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    copyCivilResourceProductivity: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilResourceExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectPlanListExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectTaskUOMExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProcesslistExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectTaskExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectRoleExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectTaskPlanResourceExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectActivityExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectTaskPlanQuantityExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectTaskPlanCivilResourceExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectActivityBudget:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilProjectWbsDetail:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0 
    },
    getCivilProjectPlanPending:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0 
    },
    getCivilProjectSchedule:{
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0 
    },
    getCivilResourceIdentification: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCivilMaterialForActivity: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },

}

const Report_reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_CIVIL_RESOURCE_PLANNING:
            return Object.assign({}, state, { civilResourcePlanning: payload });
        case GET_CIVIL_RESOURCE_TYPE_PLANNING:
            return Object.assign({}, state, { civilResourceTypePlanning: payload });
        case GET_CIVIL_PROJECT_NAMES:
            return Object.assign({}, state, { getProjectNames: payload });
        case GET_CIVIL_PROCESS_NAMES:
            return Object.assign({}, state, { getProcessNames: payload });
        case GET_CIVIL_TASK_NAMES:
            return Object.assign({}, state, { getTaskNames: payload });
        case GET_CIVIL_RESOURCE_NAMES:
            return Object.assign({}, state, { getResourceNames: payload });
        case GET_CIVIL_BUDGET_NAMES:
            return Object.assign({}, state, { civilBudgetPlanning: payload });
        case GET_CIVIL_PROJECT:
            return Object.assign({}, state, { getCivilProjects: payload });
        case GET_CIVIL_PROJECT_PLANNIG_STAGE:
            return Object.assign({}, state, { getCivilProjectPlanningStage: payload });
        case GET_CIVIL_PROJECT_TEAM_ROLE:
            return Object.assign({}, state, { getCivilProjectTeamRole: payload });
        case GET_CIVIL_PROJECT_PLAN_LIST:
            return Object.assign({}, state, { getCivilProjectPlanList: payload });
        case GET_CIVIL_PROJECT_PLAN_TEAM:
            return Object.assign({}, state, { getCivilProjectPlanTeam: payload });
        case GET_CIVIL_PROJECT_SITE_LOCATION:
            return Object.assign({}, state, { getCivilProjectSiteLocation: payload });
        case GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT:
            return Object.assign({}, state, { getCivilProjectPlanFilterProject: payload });
        case GET_CIVIL_WBS:
            return Object.assign({}, state, { getCivilWbs: payload });
        case GET_CIVIL_PROJECT_PROCESS_PLAN:
            return Object.assign({}, state, { getCivilProjectProcessPlan: payload });
        case GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS:
            return Object.assign({}, state, { getCivilProjectProcessPlanFilterProcess: payload });
        case GET_CIVIL_PROJECT_PROCESS_TASK:
            return Object.assign({}, state, { getCivilProjectProcessTask: payload });
        case GET_TASK_DEPENDENCY_TYPE:
            return Object.assign({}, state, { getTaskDependencyType: payload });
        case GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK:
            return Object.assign({}, state, { getCivilProjectTaskPlanFilterTask: payload });
        case GET_CIVIL_PROJECT_ROLE:
            return Object.assign({}, state, { getCivilProjectRole: payload });
        case GET_CIVIL_PROJECT_PLAN_FILTER_STAGE:
            return Object.assign({}, state, { getCivilProjectPlanFilterStage: payload });
        case GET_CIVIL_PROJECT_PROCESS_TASK_PLAN:
            return Object.assign({}, state, { getCivilProjectProcessTaskPlan: payload });
        case GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY:
            return Object.assign({}, state, { getCivilProjectProcessTaskPlanLocationQuantity: payload });
        case GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY:
            return Object.assign({}, state, { getCivilProjectTaskMaterialCategory: payload });
        case GET_CIVIL_MATERIAL:
            return Object.assign({}, state, { getCivilProjectTaskMaterial: payload });
        case GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL:
            return Object.assign({}, state, { getCivilProjectTaskPlanMaterial: payload });
        case GET_CIVIL_PROJECT_ACTIVITY_MASTER:
            return Object.assign({}, state, { getCivilProjectActivityMaster: payload });
        case GET_CIVIL_PROJECT_TASK_UOM:
            return Object.assign({}, state, { getCivilProjectTaskUOM: payload });
        case GET_CIVIL_PROJECT_WBS:
            return Object.assign({}, state, { getCivilProjectWbs: payload });
        case GET_CIVIL_PROJECT_TASK:
            return Object.assign({}, state, { getCivilProjectTask: payload });
        case GET_CIVIL_PROCESS_M:
            return Object.assign({}, state, { getCivilProcess: payload });
        case GET_CIVIL_RESOURCE:
            return Object.assign({}, state, { getCivilResource: payload });
        case GET_CIVIL_RESOURCE_CATEGORY:
            return Object.assign({}, state, { getCivilResourceCategory: payload });
        case GET_CIVIL_PROJECT_RESOURCE_1:
            return Object.assign({}, state, { getCivilProjectResource: payload });
        case GET_CIVIL_OBJECT_PRODUCTIVITY:
            return Object.assign({}, state, { getCivilProjectResourceProductivity: payload });
        case GET_CIVIL_RESOURCE_ALL:
            return Object.assign({}, state, { getCivilResourceAll: payload });
        case GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE:
            return Object.assign({}, state, { getCivilProjectTaskPlanResource: payload });
        case GET_CIVIL_PROJECT_ACTIVITY_DETAIL:
            return Object.assign({}, state, { getCivilProjectActivityDetail: payload });
        case GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY:
            return Object.assign({}, state, { getCivilProjectDependableActivity: payload });

        case GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY:
            return Object.assign({}, state, { getCivilProjectResourceFilterResourceCategory: payload });
        case GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE:
            return Object.assign({}, state, { getCivilProjectResourceFilterResource: payload });
        case GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY:
            return Object.assign({}, state, { getCivilProjectResourceProductivityFilterResourceCategory: payload });
        case GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE:
            return Object.assign({}, state, { getCivilProjectResourceProductivityFilterResource: payload });
        case GET_CIVIL_PROJECT_RESOURCE_EXPORT:
            return Object.assign({}, state, { getCivilProjectResourceExport: payload });
        case COPY_CIVIL_PROJECT_RESOURCE:
            return Object.assign({}, state, { copyCivilProjectResource: payload });
        case GET_CIVIL_OBJECT_PRODUCTIVITY_EXPORT:
            return Object.assign({}, state, { getCivilProjectResourceProductivityExport: payload });
        case COPY_CIVIL_PROJECT_PRODUCTIVITY:
            return Object.assign({}, state, { copyCivilResourceProductivity: payload });
        case GET_CIVIL_RESOURCE_EXPORT:
            return Object.assign({}, state, { getCivilResourceExport: payload });
        case GET_CIVIL_PROJECT_PLAN_LIST_EXPORT:
            return Object.assign({}, state, { getCivilProjectPlanListExport: payload });
        case GET_CIVIL_PROJECT_TASK_UOM_EXPORT:
            return Object.assign({}, state, { getCivilProjectTaskUOMExport: payload });
        case GET_CIVIL_PROCESS_M_EXPORT:
            return Object.assign({}, state, { getCivilProcesslistExport: payload });
        case GET_CIVIL_PROJECT_TASK_EXPORT:
            return Object.assign({}, state, { getCivilProjectTaskExport: payload });
        case GET_CIVIL_PROJECT_ROLE_EXPORT:
            return Object.assign({}, state, { getCivilProjectRoleExport: payload });
        case GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE_EXPORT:
            return Object.assign({}, state, { getCivilProjectTaskPlanResourceExport: payload });
        case GET_CIVIL_PROJECT_ACTIVITY_EXPORT:
            return Object.assign({}, state, { getCivilProjectActivityExport: payload });
        case GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT:
            return Object.assign({}, state, { getCivilProjectTaskPlanQuantityExport: payload });
        case GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT:
            return Object.assign({}, state, { getCivilProjectTaskPlanCivilResourceExport: payload });
        case GET_CIVIL_PROJECT_BUDGET:
            return Object.assign({}, state, { getCivilProjectActivityBudget: payload });
        case GET_CIVIL_PROJECT_WBS_DETAIL:
            return Object.assign({}, state, { getCivilProjectWbsDetail: payload });
        case GET_CIVIL_PROJECT_PLAN_PENDING:
            return Object.assign({}, state, { getCivilProjectPlanPending: payload });
        case GET_CIVIL_PROJECT_SCHEDULE:
            return Object.assign({}, state, { getCivilProjectSchedule: payload });
        case GET_CIVIL_RESOURCE_IDENTIFICATION:
            return Object.assign({}, state, { getCivilResourceIdentification: payload });
        case GET_CIVIL_MATERIAL_FOR_ACTIVITY:
            return Object.assign({}, state, { getCivilMaterialForActivity: payload });
        default:
            return state;
    }
}

export default Report_reducer;