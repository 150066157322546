import * as React from 'react';
export const routes = [
	{
		path: '/',
		Component: React.lazy(() => import('../../Pages/Dashboard/UserDashboard')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/userDashboard',
		Component: React.lazy(() => import('../../Pages/Dashboard/UserDashboard')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/assignment',
		Component: React.lazy(() => import('../../Pages/Dashboard/Assignment')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/history',
		Component: React.lazy(() => import('../../Pages/Dashboard/History')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/evaluation',
		Component: React.lazy(() => import('../../Pages/Dashboard/Evaluation')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/papers',
		Component: React.lazy(() => import('../../Pages/Dashboard/Papers')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/exams',
		Component: React.lazy(() => import('../../Pages/Dashboard/Exams')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/sensorData',
		Component: React.lazy(() => import('../../Pages/Dashboard/Sales')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/crm',
		Component: React.lazy(() => import('../../Pages/Dashboard/Crm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/marketing',
		Component: React.lazy(() => import('../../Pages/Dashboard/Marketing')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/procurment',
		Component: React.lazy(() => import('../../Pages/Dashboard/Procurment')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/civil',
		Component: React.lazy(() => import('../../Pages/Dashboard/Civil')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/civil-progress',
		Component: React.lazy(() => import('../../Pages/Dashboard/CivilProgress')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/civil-process-progress',
		Component: React.lazy(() => import('../../Pages/Dashboard/CivilProcessProgress')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/profitable-report',
		Component: React.lazy(() => import('../../Pages/Reports')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/liaison',
		Component: React.lazy(() => import('../../Pages/Liaison')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/login',
		Component: React.lazy(() => import('../../Pages/Login')),
		exact: true,
		isNavbar: false
	},
	{
		path: '/nextHearing/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/CourtCase')),
		exact: true,
		isNavbar: false
	},
	{
		path: '/courtCase/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/CourtReminder')),
		exact: true,
		isNavbar: false
	},
	{
		path: '/liaison-reports/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/Liaison')),
		exact: true,
		isNavbar: false
	},
	{
		path: '/liaison-fallowups-reports/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/Task')),
		exact: true,
		isNavbar: false
	},
	{
		path: '/liaison_kns_report/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/LiaisonKnsReport')),
		exact: true,
		isNavbar: false
	},
	{
		path: '/liaison_shc_report/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/liaisonShcReport')),
		exact: true,
		isNavbar: false
	},
	{
		path: '/liaison_all_report/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/LiaisonAllReport')),
		exact: true,
		isNavbar: false
	},
	{
		path: '/liaison_own_report/:reportDate/:processOwnerID',
		Component: React.lazy(() => import('../../Pages/Reports/LiaisonOwnReport')),
		exact: true,
		isNavbar: false
	},
	{
		path: '/profitable',
		Component: React.lazy(() => import('../../Pages/Reports/Profitable')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/task-wise-quantity/:reportDate',
		Component: React.lazy(() => import('../../Pages/Reports/TaskWiseQuantity')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/progress',
		Component: React.lazy(() => import('../../Pages/Civil/ProjectProgress')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/budget',
		Component: React.lazy(() => import('../../Pages/Civil/ProjectBudget')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/resource-nmr-planning',
		Component: React.lazy(() => import('../../Pages/Civil/ManPowerPlanning')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/resource-nmr-planning-details',
		Component: React.lazy(() => import('../../Pages/Civil/ManPowerDetailPlanning')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/resource-machine-planning',
		Component: React.lazy(() => import('../../Pages/Civil/MachinePlanning')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/resource-machine-planning-details',
		Component: React.lazy(() => import('../../Pages/Civil/MachinDetailPlanning')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/resource-type-planning',
		Component: React.lazy(() => import('../../Pages/Civil/MachinePlanning')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/resource-planning',
		Component: React.lazy(() => import('../../Pages/Civil/ResourcePlanning')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-task-actual-machine',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskActualMachine')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-task-actual-machine/edit',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskActualMachine/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-task-actual-machine/new',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskActualMachine/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-task-actual-nmr',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskActualNMR')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-task-actual-nmr/edit',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskActualNMR/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-task-actual-nmr/new',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskActualNMR/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-task-actual-contract-work',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskActualContractWork')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-task-actual-contract-work/edit',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskActualContractWork/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-task-actual-contract-work/new',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskActualContractWork/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/proposed',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProposedProject')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/up-coming',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/UpcomingProject')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/up-coming/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/UpcomingProject/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/up-coming/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/UpcomingProject/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/on-going',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/OngoingProject')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/on-going/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/OngoingProject/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/on-going/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/OngoingProject/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/completed',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CompletedProject')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/completed/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CompletedProject/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/completed/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CompletedProject/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/dropped',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/DroppedProject')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/dropped/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/DroppedProject/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/dropped/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/DroppedProject/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/proposed/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProposedProject/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/flow',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFlow')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/inspection',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectInspection')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/plan',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectPlan')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/plan/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectPlan/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/task-plan',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectTaskPlan')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/task-plan/supplementary-deeds',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectTaskPlan/SupplementaryDeeds')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/task-plan/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectTaskPlan/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/approval-pending',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectTaskApprovalPending')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-file',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectSurveyNo')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-file/ProjectSurveyNoForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectSurveyNo/ProjectSurveyNoForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-file/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectSurveyNo/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-file/Review',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectSurveyNo/Review')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-process-plan',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonProcessPlan')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-process-plan/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonProcessPlan/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-process-plan/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonProcessPlan/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-process-plan-application/applicationSplitModal',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonProcessPlanApplication/ApplicationSplitModal')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-process-plan/StartDateEditModal',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonProcessPlan/StartDateEditModal')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-process-plan-application',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonProcessPlanApplication')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-process-plan-application/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonProcessPlanApplication/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-task-plan',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonTaskPlan')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-task-plan/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonTaskPlan/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-task-plan/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonTaskPlan/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-documentation',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonDocumentation')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-documentation/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonDocumentation/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-documentation/documentCheckList',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonDocumentation/DocumentCheckListForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-documentation/ownerAssign',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonDocumentation/OwnerAssignForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-documentation-approval',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonDocumentationApproval')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liason-documentation-approval/approval',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiasonDocumentationApproval/ApprovalModal')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-survey/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectSurveyNo/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/task-plan/approvals',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectTaskPlan/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/survey-no-pending',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/SurveyNumberTaskPending')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/survey-no/approvals',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/SurveyNumberTaskPending/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-Process-Applicability',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessApplicability/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/task-plan-document',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/TaskPlanDocument/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-profitable',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/Profitable')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/notifications',
		Component: React.lazy(() => import('../../Pages/Notifications')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/milestone',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/MileStone')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/authorization',
		Component: React.lazy(() => import('../../Pages/Authorization')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/authorization/new',
		Component: React.lazy(() => import('../../Pages/Authorization/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-owner',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandOwner')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-owner/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandOwner/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-owner/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandOwner/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-owner/details',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandOwner/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-owner/newAccounts',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandOwner/landOwnerAccountModal')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-owner/accountEdit',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandOwner/landOwnerAccountEditModal')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-file-acquisition-info',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFIleAcquisitionInfo')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-file-acquisition-info/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFIleAcquisitionInfo/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-file-acquisition-info/approvals',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFIleAcquisitionInfo/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-file-acquisition-info/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFIleAcquisitionInfo/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-broker',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandBroker')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-broker/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandBroker/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-broker/details',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandBroker/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-broker/accountEdit',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandBroker/landBrokerAccountEditModal')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-broker/newAccounts',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandBroker/landBrokerAccountModal')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-broker/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandBroker/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/feasibility-report',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReport')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/feasibility-report/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReport/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/feasibility-report/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReport/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/feasibility-report/approval',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReport/Approval')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/feasibility-report/printPdf',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReport/printPdf')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/feasibility-report-general',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReportGeneral')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/feasibility-report-general/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReportGeneral/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/feasibility-report-general/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/FeasibilityReportGeneral/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-fund',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFund')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-fund/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFund/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-fund/Review',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFund/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-broker',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectBroker')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-broker/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectBroker/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-payment',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandPayment')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-payment/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandPayment/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-payment/brokerPayment',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandPayment/brokerPaymentForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-payment/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandPayment/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-payment/governmentFeeForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandPayment/governmentFeeForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-payment/advancePaymentForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandPayment/advancePaymentForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/basic-document',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/BasicDocument')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/basic-document/form',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/BasicDocument/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/basic-document/basicDocumentForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/BasicDocument/basicDocumentForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/accounts/account-payable',
		Component: React.lazy(() => import('../../Pages/Accounts/AccountPayable/index')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/accounts/account-payable/new',
		Component: React.lazy(() => import('../../Pages/Accounts/AccountPayable/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/accounts/account-payable/ReviewForm',
		Component: React.lazy(() => import('../../Pages/Accounts/AccountPayable/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/accounts/paid-account',
		Component: React.lazy(() => import('../../Pages/Accounts/PaidAccount/index')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/accounts/paid-account/ReviewForm',
		Component: React.lazy(() => import('../../Pages/Accounts/PaidAccount/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/civil/civil-project',
		Component: React.lazy(() => import('../../Pages/Dashboard/CivilProject/ProjectProgress')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/civil/civil-project/projectProgressDetail',
		Component: React.lazy(() => import('../../Pages/Dashboard/CivilProject/projectProgressDetail')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/civil/civil-project/projectProgressTaskDetail',
		Component: React.lazy(() => import('../../Pages/Dashboard/CivilProject/taskProgressDetail')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/proposed/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProposedProject/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/proposed/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProposedProject/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-company-map',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectCompanyMap')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-company-map/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectCompanyMap/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-company-map/approvals',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectCompanyMap/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-report',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonReport')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/test/test',
		Component: React.lazy(() => import('../../Pages/Test/Test')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/test/test2',
		Component: React.lazy(() => import('../../Pages/Test/Test2')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/test/testPrint',
		Component: React.lazy(() => import('../../Pages/TestPDFPrint')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/profile',
		Component: React.lazy(() => import('../../Pages/Profile/UserProfile')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/profile/editForm',
		Component: React.lazy(() => import('../../Pages/Profile/UserProfile/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/birthdayWish',
		Component: React.lazy(() => import('../../Pages/Greetings/Birthday')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/workAnniversary',
		Component: React.lazy(() => import('../../Pages/Greetings/WorkAnniversary')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/consolidated-report',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ConsolidatedReport')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/consolidated-report/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ConsolidatedReport/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/documentation-report',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/DocumentationReport')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/apf-task',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfTask')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/apf-task/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfTask/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/apf-task/Review',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfTask/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/apf-summary',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfSummary')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/apf-query',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfQuery')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/apf-query/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfQuery/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/apf-query/Review',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfQuery/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-dispute',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandDispute')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-dispute/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandDispute/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-dispute/Review',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandDispute/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-dispute/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandDispute/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/court-case/',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CourtCase')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/court-case/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CourtCase/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/court-case/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CourtCase/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/court-case/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CourtCase/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-investment',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectInvestment')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-query',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectQuery')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-query/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectQuery/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/project-summary',
		Component: React.lazy(() => import('../../Pages/Dashboard/ProjectSummary')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/document-rework-report',
		Component: React.lazy(() => import('../../Pages/Dashboard/DocumentReworkReport')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/project-task-report',
		Component: React.lazy(() => import('../../Pages/Dashboard/ProjectTaskReport')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/training',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/Training')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/training/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/Training/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/land-dispute/landDisputeForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandDispute/landDisputeForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/court-case/courtCaseForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/CourtCase/courtCaseForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/apf-task/apfTaskForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ApfTask/apfTaskForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/projectNamingForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProposedProject/projectNamingForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/trainingContent',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/TraningContent')),
		exact: true,
		isNavbar: true
	},
	// {
	// 	path: '/business-development/liaison-payment',
	// 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment')),
	// 	exact: true,
	// 	isNavbar : true
	// },
	// {
	// 	path: '/business-development/liaison-payment/new',
	// 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/form')),
	// 	exact: true,
	// 	isNavbar : true
	// },
	// {
	// 	path: '/business-development/liaison-payment/view',
	// 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/ReviewForm')),
	// 	exact: true,
	// 	isNavbar : true
	// },
	// {
	// 	path: '/business-development/liaison-payment/addPaymentRequest',
	// 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/addPaymentRequest')),
	// 	exact: true,
	// 	isNavbar : true
	// },
	// {
	// 	path: '/business-development/liaison-payment/approval',
	// 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/Approval')),
	// 	exact: true,
	// 	isNavbar : true
	// },
	// {
	// 	path: '/business-development/liaison-payment/issue',
	// 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/Issue')),
	// 	exact: true,
	// 	isNavbar : true
	// },
	// {
	// 	path: '/business-development/liaison-payment/settlementRequest',
	// 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/settlementRequest')),
	// 	exact: true,
	// 	isNavbar : true
	// },
	// {
	// 	path: '/business-development/liaison-payment/settlementApproval',
	// 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/settlementApproval')),
	// 	exact: true,
	// 	isNavbar : true
	// },
	// {
	// 	path: '/business-development/liaison-payment/settlementSettle',
	// 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/settlementSettle')),
	// 	exact: true,
	// 	isNavbar : true
	// },
	// {
	// 	path: '/business-development/liaison-payment/detail',
	// 	Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonPayment/Detail')),
	// 	exact: true,
	// 	isNavbar : true
	// },
	{
		path: '/business-development/loan',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/Loan')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/loan/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/Loan/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/investment',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/Investment')),
		exact: true,
		isNavbar: true
	},
	// Master
	{
		path: '/business-development/liaison-process-list',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessMaster')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-process-list/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessMaster/Form')),
		exact: true,
		isNavbar: true
	},
	// Master End
	{
		path: '/business-development/liaison-process-plan',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessPlan')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-process-plan/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessPlan/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-process-plan/StartDateEditModal',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessPlan/StartDateEditModal')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-process-plan/SurveyAddModel',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessPlan/SurveyAddModel')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-process-plan/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessPlan/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-process-plan/applicationSplitModal',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessPlan/ApplicationSplitModal')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-task-plan',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonTaskPlan')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-task-plan/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonTaskPlan/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-task-plan/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonTaskPlan/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-process-plan-approval',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessPlanApproval')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-process-plan-approval/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessPlanApproval/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-process-plan-approval/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessPlanApproval/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/liaison/legal-task',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LegalTask')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/liaison/legal-task/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LegalTask/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/liaison/legal-task/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LegalTask/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/liaison/legal-task/legalTaskForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LegalTask/legalTaskForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/liaison/legal-task/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LegalTask/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/admin/newsletter',
		Component: React.lazy(() => import('../../Pages/Admin/Newsletter')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/admin/newsletter/new',
		Component: React.lazy(() => import('../../Pages/Admin/Newsletter/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/ProjectSurveyMapping',
		Component: React.lazy(() => import('../../Pages/Dashboard/ProjectSurveyMapping')),
		exact: true,
		isNavbar: true
	},
	// ---dashboard new --
	{
		path: '/dashboard/bd',
		Component: React.lazy(() => import('../../Pages/Dashboard/BusinessDevelopment')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/liaison',
		Component: React.lazy(() => import('../../Pages/Dashboard/Liaison')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/documentation',
		Component: React.lazy(() => import('../../Pages/Dashboard/Documentation')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/ProjectFileDeed',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFileDeeds')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/ProjectFileDeed/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFileDeeds/ProjectFileDeedReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/ProjectFileDeed/Form',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectFileDeeds/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/reports/bd-consolidated-report',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ConsolidatedReport/BDConsolidatedReport')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/dashboard/pendingTask',
		Component: React.lazy(() => import('../../Pages/Dashboard/PendingTask')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/Project')),
		exact: true,
		isNavbar: true
	},
	// {
	// 	path: '/master/bdMaster/ProjectTaskDependency',
	// 	Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskDependency')),
	// 	exact: true,
	// 	isNavbar: true
	// },
	// {
	// 	path: '/master/bdMaster/ProjectTaskDependency/new',
	// 	Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskDependency/form')),
	// 	exact: true,
	// 	isNavbar: true
	// },
	// {
	// 	path: '/master/bdMaster/ProjectTaskDependency/edit',
	// 	Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskDependency/Reviewform')),
	// 	exact: true,
	// 	isNavbar: true
	// },
	// _______________________Sadanand______________
	{
		path: "/business-development/toBeAcquiredFile",
		Component: React.lazy(() =>
			import("../../Pages/BusinessDevelopment/ToBeAcquiredFile")
		),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/business-development/toBeAcquiredFile/ReviewForm",
		Component: React.lazy(() =>
			import("../../Pages/BusinessDevelopment/ToBeAcquiredFile/ReviewForm")
		),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/business-development/toBeAcquiredFile/new",
		Component: React.lazy(() =>
			import("../../Pages/BusinessDevelopment/ToBeAcquiredFile/form")
		),
		exact: true,
		isNavbar: true,
	},
	{
		path: '/business-development/liaison-process-plan/bulkOwnerAssign',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessPlan/LiaisonProcessOwnerAssignForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/loan-process',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LoanProcess')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/loan-process/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LoanProcess/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/loan-task-plan',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LoanTaskPlan')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/loan-task-plan/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LoanTaskPlan/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-process-plan/updateBulk',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonProcessPlan/LiaisonProcessUpdateBulkForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/loan-process/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LoanProcess/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/delayed-process',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/DelayedProcess')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/delayed-process/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/DelayedProcess/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	//   BD Master
	{
		path: '/bd-masters/BdIssueType',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/BdIssueType')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/BdIssueType/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/BdIssueType/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/BdIssueType/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/BdIssueType/Reviewform')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LiaisonProcessCategory',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcessCategory')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LiaisonProcessCategory/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcessCategory/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LiaisonProcessCategory/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcessCategory/Reviewform')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/ProjectTask',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTask')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/ProjectTask/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTask/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/LiaisonProcess/Edit-Review-form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTask/EditReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectTaskCategory',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskCategory')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectTaskCategory/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskCategory/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectTaskCategory/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskCategory/Reviewform')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectTaskDependency',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskDependency')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectTaskDependency/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskDependency/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectTaskDependency/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskDependency/Reviewform')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/PlanningAuthority',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/PlanningAuthority')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/PlanningAuthority/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/PlanningAuthority/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/PlanningAuthority/Reviewform',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/PlanningAuthority/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LiaisonTaskCategory',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonTaskCategory')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LiaisonTaskCategory/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonTaskCategory/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LiaisonTaskCategory/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonTaskCategory/Reviewform')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/LiaisonTask',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonTask')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/LiaisonTask/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonTask/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/LiaisonTask/Review-form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonTask/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/delayed-liaison-task-plan',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/DelayedLiaisonTaskPlan')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/delayed-liaison-task-plan/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/DelayedLiaisonTaskPlan/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/project-file/split-sy-no',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProjectSurveyNo/SurveySplitModal')),
		exact: true,
		isNavbar: true
	},
	// HR  module
	// {
	// 	path: "/HR/PaymentRequestedBy",
	// 	Component: React.lazy(() =>
	// 	  import("../../Pages/HR/PettyCash/PaymentRequestedBy")
	// 	),
	// 	exact: true,
	// 	isNavbar: true,
	//   },
	//   {
	// 	path: "/HR/PaymentRequestedBy/form",
	// 	Component: React.lazy(() => import("../../Pages/HR/PettyCash/form")),
	// 	exact: true,
	// 	isNavbar: true,
	//   },
	//   {
	// 	path: "/HR/Paymentissue",
	// 	Component: React.lazy(() =>
	// 	  import("../../Pages/HR/PettyCash/PaymentIssueby")
	// 	),
	// 	exact: true,
	// 	isNavbar: true,
	//   },
	//   {
	// 	path: "/HR/SettlementApprovalsBy",
	// 	Component: React.lazy(() =>
	// 	  import("../../Pages/HR/PettyCash/SettlementApprovalsBy")
	// 	),
	// 	exact: true,
	// 	isNavbar: true,
	//   },
	{
		path: "/HR/VehicleFuelRequest",
		Component: React.lazy(() => import("../../Pages/HR/VehicleFuelRequest")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/VehicleFuelRequest/new",
		Component: React.lazy(() =>
			import("../../Pages/HR/VehicleFuelRequest/form")
		),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/VehicleService",
		Component: React.lazy(() => import("../../Pages/HR/VehicleService")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/VehicleService/new",
		Component: React.lazy(() => import("../../Pages/HR/VehicleService/form")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/VehicleService/update",
		Component: React.lazy(() => import("../../Pages/HR/VehicleService/ReviewForm")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/VehicleService/print",
		Component: React.lazy(() => import("../../Pages/HR/VehicleService/VechialServicePrint")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/AttendanceReport",
		Component: React.lazy(() => import("../../Pages/HR/EmployeeAttendance")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/AttendanceReport/new",
		Component: React.lazy(() =>
			import("../../Pages/HR/EmployeeAttendance/form")
		),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/EmployeeCheckin",
		Component: React.lazy(() => import("../../Pages/HR/EmployeeCheckin")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/EmployeeCheckin/Maps",
		Component: React.lazy(() => import("../../Pages/HR/EmployeeCheckin/googleMaps")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/DailyAttendanceReport",
		Component: React.lazy(() =>
			import("../../Pages/HR/EmployeeAttendanceDetails")
		),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/LeaveApplication",
		Component: React.lazy(() => import("../../Pages/HR/LeaveApplication")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/LeaveApplication/new",
		Component: React.lazy(() => import("../../Pages/HR/LeaveApplication/form")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/AddAttendance",
		Component: React.lazy(() => import("../../Pages/HR/ImportAttendance")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/AttendanceExport",
		Component: React.lazy(() => import("../../Pages/HR/ExportAttendance")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/EmployeeLoginList",
		Component: React.lazy(() =>
			import("../../Pages/HR/EmployeeLocationApprovals")
		),
		exact: true,
		isNavbar: true,
	},
	{
		path: '/hr/task',
		Component: React.lazy(() => import('../../Pages/HR/Task')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/hr/task/addtask',
		Component: React.lazy(() => import('../../Pages/HR/Task/addtask')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/hr/task/view',
		Component: React.lazy(() => import('../../Pages/HR/Task/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: "/HR/LeaveSummary",
		Component: React.lazy(() => import("../../Pages/HR/EmployeeLeaveSummary")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/CompOffLeaveSummary",
		Component: React.lazy(() => import("../../Pages/HR/EmployeeCompOffLeaveSummary")),
		exact: true,
		isNavbar: true,
	},
	{
		path: '/business-development/plan-approval-report',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/PlanApprovalReport')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-master/LiaisonProcess',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcess')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-master/LiaisonProcess/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcess/LiaisonProcessForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-master/LiaisonProcess/Edit-form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcess/EditReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-plan',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectPlan')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/bd-kpi-report',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/BD_KPI_REPORT')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-plan/new',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectPlan/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-plan/team-planner',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectPlan/TeamPlanner')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-plan/locations',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectPlan/Locations')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-plan-wbs',
		Component: React.lazy(() => import('../../Pages/Civil/WorkBreakDownStructure')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-task-plan-activity',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectTaskPlanActivity')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/proposed/project-merger',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/ProposedProject/ProjectMerger')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-task-plan-activity/new',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectTaskPlanActivity/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-task-plan-activity/quantityPlanning',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectTaskPlanActivity/QuantityPlanning')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-task-plan-activity/materialPlanning',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectTaskPlanActivity/MaterialPlanning')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-task-plan-activity/resourceIdentification',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectTaskPlanActivity/ResourceIdentification')),
		exact: true,
		isNavbar: true
	},
	// Nikhil
	{
		path: '/bd-masters/PaymentMilestone',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/PaymentMilestone/index')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/PaymentMilestone/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/PaymentMilestone/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/PaymentMilestone/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/PaymentMilestone/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-plan-wbs/new',
		Component: React.lazy(() => import('../../Pages/Civil/WorkBreakDownStructure/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectTaskRejectionReason',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskRejectionReason')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectTaskRejectionReason/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskRejectionReason/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectTaskRejectionReason/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskRejectionReason/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/AgreementType',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/AgreementType')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/AgreementType/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/AgreementType/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/AgreementType/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/AgreementType/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/CourtCaseType',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/CourtCaseType')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/CourtCaseType/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/CourtCaseType/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/CourtCaseType/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/CourtCaseType/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/CourtEstablishment',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/CourtEstablishment')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/CourtEstablishment/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/CourtEstablishment/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/CourtEstablishment/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/CourtEstablishment/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/CourtCaseStatus',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/CourtCaseStatus')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/CourtCaseStatus/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/CourtCaseStatus/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/CourtCaseStatus/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/CourtCaseStatus/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/RegistrationFees',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/RegistrationFee')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/RegistrationFees/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/RegistrationFee/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/RegistrationFees/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/RegistrationFee/EditForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/RegistrationFeesRate',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/RegistrationFeesRate')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/RegistrationFeesRate/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/RegistrationFeesRate/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/RegistrationFeesRate/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/RegistrationFeesRate/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/Liaison_Process_Exception',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcessException')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/Liaison_Process_Exception/Form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcessException/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/Liaison_Process_Exception/Edit_Form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcessException/EditForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/LiaisonProcessDependency',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcessDependency')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/LiaisonProcessDependency/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcessDependency/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/LiaisonProcessDependency/Edit_form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcessDependency/EditForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/apf_panel',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ApfPanel')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/apf_panel/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ApfPanel/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/apf_panel/Edit_from',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ApfPanel/EditForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/apfTask',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ApfTask')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/apfTask/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ApfTask/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/apfTask/form-edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ApfTask/Editform')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/master',
		Component: React.lazy(() => import('../../Pages/Master/MasterList/BdMaster')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/Bank',
		Component: React.lazy(() => import('../../Pages/Master/MasterList/BankMaster')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/Liaison',
		Component: React.lazy(() => import('../../Pages/Master/MasterList/LiaisonMaster')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/Legal',
		Component: React.lazy(() => import('../../Pages/Master/MasterList/LegalMaster')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/General',
		Component: React.lazy(() => import('../../Pages/Master/MasterList/GeneralMaster')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/Civil-Master/Civil',
		Component: React.lazy(() => import('../../Pages/Master/MasterList/CivilMaster')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/project_document_type',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectDocumentType')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/project_document_type/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectDocumentType/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/project_document_type/edit_form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectDocumentType/EditForm')),
		exact: true,
		isNavbar: true
	},
	// 
	{
		path: '/BD-Master/TaskDelayReason',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/TaskDelayReason')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/TaskDelayReason/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/TaskDelayReason/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/TaskDelayReason/Edit_from',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/TaskDelayReason/EditForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/BankMaster',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/BankMaster')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/BankMaster/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/BankMaster/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/BankMaster/form_edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/BankMaster/EditForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/LoanTask',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LoanTask')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/LoanTask/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LoanTask/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/LoanTask/edit_form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LoanTask/EditForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil-master/wbs',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/WBS')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-activity',
		Component: React.lazy(() => import('../../Pages/Civil/Activity')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectTaskTransaction',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskTransaction')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectTaskTransaction/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskTransaction/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectTaskTransaction/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectTaskTransaction/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectDropReason',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectDropReason')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectDropReason/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectDropReason/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectDropReason/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectDropReason/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LoanPanel',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LoanPanel')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LoanPanel/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LoanPanel/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LoanPanel/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LoanPanel/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LoanProcess',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LoanProcess')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LoanProcess/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LoanProcess/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LoanProcess/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LoanProcess/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/BdTaskApproval',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/BdTaskApproval')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/BdTaskApproval/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/BdTaskApproval/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/BdTaskApproval/Edit_form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/BdTaskApproval/EditForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-plan/project-charter-detail',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectPlan/ProjectCharterPrint')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-plan/edit',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectPlan/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/wbs',
		Component: React.lazy(() => import('../../Pages/Civil/WBS')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/wbs/new',
		Component: React.lazy(() => import('../../Pages/Civil/WBS/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/SubRegistrarOfficeList',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/SubRegistrarOffice')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/SubRegistrarOfficeList/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/SubRegistrarOffice/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/SubRegistrarOfficeList/Edit_form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/SubRegistrarOffice/EditForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/BdSubRegistrarMapping',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/SubRegistrarMapping')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/BdSubRegistrarMapping/form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/SubRegistrarMapping/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/BdSubRegistrarMapping/Edit_form',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/SubRegistrarMapping/EditForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-activity/new',
		Component: React.lazy(() => import('../../Pages/Civil/Activity/ActivityAddForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LiaisonProcessApplicabilityReason',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcessApplicabilityReason')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LiaisonProcessApplicabilityReason/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcessApplicabilityReason/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LiaisonProcessApplicabilityReason/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LiaisonProcessApplicabilityReason/edit')),
		exact: true,
		isNavbar: true
	},
	// sadanand civil master
	{
		path: '/civil/object-output-list',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/ObjectOutputList')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/object-output-list/new',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/ObjectOutputList/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/object-output-list/edit',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/ObjectOutputList/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-process',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilProjectProcess')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-process/new',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilProjectProcess/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-process/edit',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilProjectProcess/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-task',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilProjectTask')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-task/new',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilProjectTask/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-task/edit',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilProjectTask/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-role',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilProjectRole')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-role/new',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilProjectRole/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-role/edit',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilProjectRole/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-task-uom',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilProjectTaskUOM')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-task-uom/new',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilProjectTaskUOM/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-task-uom/edit',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilProjectTaskUOM/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-resource-type',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilResourceType')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-resource-type/new',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilResourceType/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-resource-type/edit',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/CivilResourceType/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/projectResource',
		Component: React.lazy(() => import('../../Pages/Civil/CivilResource')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/projectResource/new',
		Component: React.lazy(() => import('../../Pages/Civil/CivilResource/form')),
		exact: true,
		isNavbar: true
	},
	// {
	// 	path: '/civil/projectResource/new-material',
	// 	Component: React.lazy(() => import('../../Pages/Civil/CivilResource/materialForm')),
	// 	exact: true,
	// 	isNavbar: true
	// },
	{
		path: '/civil/projectResource/edit',
		Component: React.lazy(() => import('../../Pages/Civil/CivilResource/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/BD-Master/Accounts',
		Component: React.lazy(() => import('../../Pages/Master/MasterList/AccountMaster')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/landlord-meeting',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandLordMeeting')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/landlord-meeting/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandLordMeeting/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/landlord-meeting/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandLordMeeting/Review')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/landlord-meeting/edit',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandLordMeeting/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/object-output-list/copy',
		Component: React.lazy(() => import('../../Pages/Master/CivilMaster/ObjectOutputList/Projectcopy')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/projectResource/copy',
		Component: React.lazy(() => import('../../Pages/Civil/CivilResource/Projectcopy')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/HR/RoleFunctionality',
		Component: React.lazy(() => import('../../Pages/HR/RoleFunctionality')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/HR/RoleFunctionality/new',
		Component: React.lazy(() => import('../../Pages/HR/RoleFunctionality/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/HR/RoleFunctionality/edit',
		Component: React.lazy(() => import('../../Pages/HR/RoleFunctionality/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: "/HR/PublicHoliday",
		Component: React.lazy(() => import("../../Pages/HR/PublicHoliday")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/PublicHoliday/new",
		Component: React.lazy(() => import("../../Pages/HR/PublicHoliday/form")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/PublicHoliday/edit",
		Component: React.lazy(() => import("../../Pages/HR/PublicHoliday/edit")),
		exact: true,
		isNavbar: true,
	},
	{
		path: '/civil/civil-project-plan/project-charter-add',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectPlan/ProjectCharterAddForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-task-plan-activity/quantity-planning-form',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectTaskPlanActivity/QuantityPlanningFromTaskPlan')),
		exact: true,
		isNavbar: true
	},
	{
		path: "/doc-edit",
		Component: React.lazy(() => import("../../Pages/HR/docs-edit")),
		exact: true,
		isNavbar: true,
	},
	{
		path: '/bd-masters/ProjectZone',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectZone')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectZone/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectZone/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/ProjectZone/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/ProjectZone/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LandLordMeetingParticular',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LandLordMeetingParticular')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LandLordMeetingParticular/new',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LandLordMeetingParticular/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/bd-masters/LandLordMeetingParticular/edit',
		Component: React.lazy(() => import('../../Pages/Master/BDMaster/LandLordMeetingParticular/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: "/bd-masters/LandLordMeetingStage",
		Component: React.lazy(() => import("../../Pages/Master/BDMaster/LandLordMeetingStage")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/bd-masters/LandLordMeetingStage/form",
		Component: React.lazy(() => import("../../Pages/Master/BDMaster/LandLordMeetingStage/Form")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/bd-masters/LandLordMeetingStage/Edit_form",
		Component: React.lazy(() => import("../../Pages/Master/BDMaster/LandLordMeetingStage/FormEdit")),
		exact: true,
		isNavbar: true,
	},
	{
		path: '/hr/SalaryAdvanceRequest',
		Component: React.lazy(() => import('../../Pages/HR/SalaryAdvanceRequest')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/hr/SalaryAdvanceRequest/Form',
		Component: React.lazy(() => import('../../Pages/HR/SalaryAdvanceRequest/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/hr/SalaryAdvanceRequest/Edit_Form',
		Component: React.lazy(() => import('../../Pages/HR/SalaryAdvanceRequest/EditForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/hr/HrOutPass',
		Component: React.lazy(() => import('../../Pages/HR/Hroutpass')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/hr/HrOutPass/Form',
		Component: React.lazy(() => import('../../Pages/HR/Hroutpass/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/hr/HrOutPass/Edit_Form',
		Component: React.lazy(() => import('../../Pages/HR/Hroutpass/EditForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: "/hr/SalaryAdvanceApproval",
		Component: React.lazy(() => import("../../Pages/HR/SalaryAdvanceApprove")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/hr/SalaryAdvanceApproval/form",
		Component: React.lazy(() => import("../../Pages/HR/SalaryAdvanceApprove/Form")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/hr/SalaryAdvanceIssue",
		Component: React.lazy(() => import("../../Pages/HR/SalaryAdvanceIssue")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/hr/SalaryAdvanceIssue/form",
		Component: React.lazy(() => import("../../Pages/HR/SalaryAdvanceIssue/Form")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/PettyCash",
		Component: React.lazy(() => import("../../Pages/HR/PettyCash")),
		exact: true,
	},
	{
		path: "/HR/PettyCash/form",
		Component: React.lazy(() => import("../../Pages/HR/PettyCash/form")),
		exact: true,
	},
	{
		path: '/civil/activity-resource',
		Component: React.lazy(() => import('../../Pages/Civil/ActivityResource')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/MOUDateUpdate',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/MOUDateUpdate')),
		exact: true,
		isNavbar: true
	},
	// {
	// 	path: '/civil/activity-resource/new',
	// 	Component: React.lazy(() => import('../../Pages/Civil/ActivityResource/form')),
	// 	exact: true,
	// 	isNavbar: true
	// },
	// {
	// 	path: '/civil/activity-resource/edit',
	// 	Component: React.lazy(() => import('../../Pages/Civil/ActivityResource/ReviewForm')),
	// 	exact: true,
	// 	isNavbar: true
	// },
	{
		path: '/civil/civil-Task-Budget',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskBudget')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-Task-Budget/add',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskBudget/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-Task-Budget/detail',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskBudget/CivilTaskBudgetDetail')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-task-wise-budget',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskWiseBudgetReport')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-task-wise-budget/add',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskWiseBudgetReport/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-task-wise-budget/update',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskWiseBudgetReport/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-work-order',
		Component: React.lazy(() => import('../../Pages/Civil/WorkOrder')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-work-order/add',
		Component: React.lazy(() => import('../../Pages/Civil/WorkOrder/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-work-order/update',
		Component: React.lazy(() => import('../../Pages/Civil/WorkOrder/ReviewForm')),
		exact: true,
		isNavbar: true
	},

	{
		path: '/civil/civil-project-activity/budget-approval',
		Component: React.lazy(() => import('../../Pages/Civil/Activity/BudgetSendForApproval')),
		exact: true,
		isNavbar: true
	},

	{
		path: '/civil/civil-project-budget',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectBudget')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-wbs-budget',
		Component: React.lazy(() => import('../../Pages/Civil/CivilWbsBudget')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-activity-budget',
		Component: React.lazy(() => import('../../Pages/Civil/CivilTaskBudget')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-bills',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectBill')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-bills/edit',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectBill/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-dependency-exception',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonDependencyException')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-dependency-exception/add',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonDependencyException/Form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/liaison-dependency-exception/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LiaisonDependencyException/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/enquiry-project',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/EnquiryProject')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/enquiry-project/ReviewForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/EnquiryProject/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/enquiry-project/editForm',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/EnquiryProject/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-schedule',
		Component: React.lazy(() => import('../../Pages/Civil/CivilProjectSchedule')),
		exact: true,
		isNavbar: true
	},

	{
		path: '/business-development/landlord-meeting-report',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandLordMeetingReport')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/landlord-meeting-report/new',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandLordMeetingReport/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/landlord-meeting-report/view',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandLordMeetingReport/Review')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/business-development/landlord-meeting-report/edit',
		Component: React.lazy(() => import('../../Pages/BusinessDevelopment/LandLordMeetingReport/editForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-activity/gantt-trial',
		Component: React.lazy(() => import('../../Pages/Civil/GanttSyncfusion/Gantt')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/civil/civil-project-activity/gantt-trial2',
		Component: React.lazy(() => import('../../Pages/Civil/GanttSyncfusion/Gantt2')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/HR/Designation',
		Component: React.lazy(() => import('../../Pages/HR/Designation')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/HR/Designation/new',
		Component: React.lazy(() => import('../../Pages/HR/Designation/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/HR/Designation/edit',
		Component: React.lazy(() => import('../../Pages/HR/Designation/edit')),
		exact: true,
		isNavbar: true
	},
	{
		path: "/HR/Department",
		Component: React.lazy(() => import("../../Pages/HR/Department")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/Department/new",
		Component: React.lazy(() => import("../../Pages/HR/Department/form")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/HR/Department/edit",
		Component: React.lazy(() => import("../../Pages/HR/Department/edit")),
		exact: true,
		isNavbar: true,
	},




	// Education Routes   

	{
		path: '/education/assignment',
		Component: React.lazy(() => import('../../Pages/Education/Assignment')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/history',
		Component: React.lazy(() => import('../../Pages/Education/History')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/evaluation',
		Component: React.lazy(() => import('../../Pages/Education/Evaluation')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/papers',
		Component: React.lazy(() => import('../../Pages/Education/Papers')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/exams',
		Component: React.lazy(() => import('../../Pages/Education/Exams')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/subject',
		Component: React.lazy(() => import('../../Pages/Education/Subject')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/subject/new',
		Component: React.lazy(() => import('../../Pages/Education/Subject/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/subject/edit',
		Component: React.lazy(() => import('../../Pages/Education/Subject/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/department',
		Component: React.lazy(() => import('../../Pages/Education/Department')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/department/new',
		Component: React.lazy(() => import('../../Pages/Education/Department/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/department/edit',
		Component: React.lazy(() => import('../../Pages/Education/Department/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/course-category',
		Component: React.lazy(() => import('../../Pages/Education/CourseCategory')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/course-category/new',
		Component: React.lazy(() => import('../../Pages/Education/CourseCategory/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/course-category/edit',
		Component: React.lazy(() => import('../../Pages/Education/CourseCategory/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/course-level',
		Component: React.lazy(() => import('../../Pages/Education/CourseLevel')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/course-level/new',
		Component: React.lazy(() => import('../../Pages/Education/CourseLevel/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/course-level/edit',
		Component: React.lazy(() => import('../../Pages/Education/CourseLevel/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/course-exam-type',
		Component: React.lazy(() => import('../../Pages/Education/CourseExamType')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/course-exam-type/new',
		Component: React.lazy(() => import('../../Pages/Education/CourseExamType/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/course-exam-type/edit',
		Component: React.lazy(() => import('../../Pages/Education/CourseExamType/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	//abhi
	{
		path: "/education/course",
		Component: React.lazy(() => import("../../Pages/Education/Course")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/course/new",
		Component: React.lazy(() => import("../../Pages/Education/Course/form")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/course/edit",
		Component: React.lazy(() => import("../../Pages/Education/Course/ReviewForm")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/student",
		Component: React.lazy(() => import("../../Pages/Education/Student")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/student/new",
		Component: React.lazy(() => import("../../Pages/Education/Student/form")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/student/ReviewForm",
		Component: React.lazy(() => import("../../Pages/Education/Student/ReviewForm")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/student/editForm",
		Component: React.lazy(() => import("../../Pages/Education/Student/editForm")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/student/print",
		Component: React.lazy(() => import("../../Pages/Education/Student/studentPrint")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/exam-result",
		Component: React.lazy(() => import("../../Pages/Education/ExamResult")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/exam-result/new",
		Component: React.lazy(() => import("../../Pages/Education/ExamResult/form")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/exam-result/ReviewForm",
		Component: React.lazy(() => import("../../Pages/Education/ExamResult/ReviewForm")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/exam-result/print",
		Component: React.lazy(() => import("../../Pages/Education/ExamResult/ExamResultPrint")),
		exact: true,
		isNavbar: true,
	},
	{
		path: '/education/exam-grade',
		Component: React.lazy(() => import('../../Pages/Education/GradeLevel')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/grade-level/new',
		Component: React.lazy(() => import('../../Pages/Education/GradeLevel/form')),
		exact: true,
		isNavbar: true
	},
	{
		path: '/education/grade-level/edit',
		Component: React.lazy(() => import('../../Pages/Education/GradeLevel/ReviewForm')),
		exact: true,
		isNavbar: true
	},
	{
		path: "/education/user",
		Component: React.lazy(() => import("../../Pages/Education/User")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/user/new",
		Component: React.lazy(() => import("../../Pages/Education/User/form")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/user/edit",
		Component: React.lazy(() => import("../../Pages/Education/User/ReviewForm")),
		exact: true,
		isNavbar: true,
	},
	{
		path: "/education/dynamic-form/form",
		Component: React.lazy(() => import("../../Pages/Education/DynamicForm/form")),
		exact: true,
		isNavbar: true,
	},
]
