export const GET_PROJECT_AUTHORITY = 'GET_PROJECT_AUTHORITY';
export const GET_BD_USER = 'GET_BD_USER';
export const GET_PROJECT_PHASE = 'GET_PROJECT_PHASE';
export const GET_DISTRICT = 'GET_DISTRICT';
export const GET_TALUK = 'GET_TALUK';
export const GET_HOBLI = 'GET_HOBLI';
export const GET_PROJECT_BROKERS = 'GET_PROJECT_BROKERS';
export const GET_PROJECT_LIST = 'GET_PROJECT_LIST';
export const GET_VILLAGE_LIST = 'GET_VILLAGE_LIST';
export const GET_PROJECT_FLOW_LIST = 'GET_PROJECT_FLOW_LIST';
export const GET_PROJECT_OWNERS_NAMES = 'GET_PROJECT_OWNERS_NAMES';
export const GET_PROJECT_NAMES = 'GET_PROJECT_NAMES';
export const GET_PROJECT_NAMES1 = 'GET_PROJECT_NAMES1';
export const GET_PROJECT_TASK_STATUS = 'GET_PROJECT_TASK_STATUS';
export const GET_PROJECT_DELAY_REASON = 'GET_PROJECT_DELAY_REASON';
export const GET_DOCUMENT_TYPES = 'GET_DOCUMENT_TYPES';
export const GET_PROJECT_TASK_PLAN_DETAILS = 'GET_PROJECT_TASK_PLAN_DETAILS';
export const GET_PROJECT_PLAN = 'GET_PROJECT_PLAN';
export const GET_PROJECT_FILE_LIST = 'GET_PROJECT_FILE_LIST';
export const GET_LAND_OWNER_NAMES = 'GET_LAND_OWNER_NAMES';
export const GET_LIASON_TASK_PLAN = 'GET_LIASON_TASK_PLAN';
export const GET_LIASON_PROCESS_PLAN = 'GET_LIASON_PROCESS_PLAN';
export const GET_PROJECT_BD_OWNER = 'GET_PROJECT_BD_OWNER';
export const GET_PROJECT_LEGAL_OWNER = 'GET_PROJECT_LEGAL_OWNER';
export const GET_LIASON_DOCUMENT_TASK_PLAN = 'GET_LIASON_DOCUMENT_TASK_PLAN';
export const GET_PROJECT_SURVEY_NO_TASK_APPROVAL = 'GET_PROJECT_SURVEY_NO_TASK_APPROVAL';
export const GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS = 'GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS';
export const GET_MY_WEB_NOTIFICATION = 'GET_MY_WEB_NOTIFICATION';
export const GET_UPCOMING_PROJECT_LIST = 'GET_UPCOMING_PROJECT_LIST';
export const GET_AUTHORIZATION = 'GET_AUTHORIZATION';
export const GET_FUNCTIONALITY = 'GET_FUNCTIONALITY';
export const GET_MENU_PERMISSION = 'GET_MENU_PERMISSION';
export const GET_PROJECT_PLAN_FILE_LIST = 'GET_PROJECT_PLAN_FILE_LIST';
export const GET_LIAISON_CATEGORY_LIST = 'GET_LIAISON_CATEGORY_LIST';
export const GET_LIAISON_PROCESS_LIST = 'GET_LIAISON_PROCESS_LIST';
export const GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT = 'GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT';
//New
export const GET_LAND_OWNER_LIST = 'GET_LAND_OWNER_LIST';
export const GET_SURVEY_NO_LIST = 'GET_SURVEY_NO';
export const GET_SURVEY_DETAIL_LIST = 'GET_SURVEY_DETAIL';
export const GET_FILE_ACQUISITION_INFO_LIST = 'GET_FILE_ACQUISITION_INFO';
export const GET_ACQUISITION_MODE_LIST = 'GET_ACQUISITION_MODE_LIST';
export const GET_ACQUISITION_INFO_DETAIL = 'GET_ACQUISITION_INFO_DETAIL';
export const GET_PROJECT_LIST_WITH_EXTENT = 'GET_PROJECT_LIST_WITH_EXTENT';
export const GET_FEASIBILITY_ITEM_LIST = 'GET_FEASIBILITY_ITEM_LIST';
export const GET_FEASIBILITY_REPORT_LIST = 'GET_FEASIBILITY_REPORT_LIST';
export const GET_FEASIBILITY_REPORT_DETAIL = 'GET_FEASIBILITY_REPORT_DETAIL';
export const GET_LAND_OWNER_DETAIL = 'GET_LAND_OWNER_DETAIL';
export const GET_LAND_BROKER_LIST = 'GET_LAND_BROKER_LIST';
export const GET_LAND_BROKER_DETAIL = 'GET_LAND_BROKER_DETAIL';
export const GET_LAND_OWNNER_VILLAGE_LIST = 'GET_LAND_OWNNER_VILLAGE_LIST';
export const GET_LIAISON_DOCUMENT_CATEGORY_LIST = 'GET_LIAISON_DOCUMENT_CATEGORY_LIST';
export const GET_PROJECT_FUND_SOURCE_LIST = 'GET_PROJECT_FUND_SOURCE_LIST';
export const GET_PROJECT_FUND_REQUIREMENT_DETAIL = 'GET_PROJECT_FUND_REQUIREMENT_DETAIL';
export const GET_PROJECT_FUND_LIST = 'GET_PROJECT_FUND_LIST';
export const GET_PROJECT_FUND_DETAIL = 'GET_PROJECT_FUND_DETAIL';
export const GET_PROJECT_FILE_PAYEMENT_DETAIL = 'GET_PROJECT_FILE_PAYEMENT_DETAIL';
export const GET_BD_PAYMENT_LIST = 'GET_BD_PAYMENT_LIST';
export const GET_BD_PAYMENT_DETAIL = 'GET_BD_PAYMENT_DETAIL';
export const GET_BD_PAYMENT_MILESTONE = 'GET_BD_PAYMENT_MILESTONE';
export const GET_PROJECT_BROKER_LIST = 'GET_PROJECT_BROKER_LIST';
export const GET_PROJECT_BROKER_DETAIL = 'GET_PROJECT_BROKER_DETAIL';
export const GET_PROJECT_BROKER_PAYMENT_INFO = 'GET_PROJECT_BROKER_PAYMENT_INFO';
export const GET_PROJECT_LAND_OWNER = 'GET_PROJECT_LAND_OWNER';
export const GET_PROJECT_OWNER_SURVEY_NUMBER_LIST = 'GET_PROJECT_OWNER_SURVEY_NUMBER_LIST';
export const GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL = 'GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL';
export const GET_LIAISON_PROCESS_PLAN_FILE = 'GET_LIAISON_PROCESS_PLAN_FILE';
export const GET_LIASON_PROCESS_PLAN_APPLICATION = 'GET_LIASON_PROCESS_PLAN_APPLICATION';
export const GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL = 'GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL';
export const GET_PROJECTC_MILESTONE = 'GET_PROJECTC_MILESTONE';
export const GET_BD_REGISTRATION_ITEM = 'GET_BD_REGISTRATION_ITEM';
export const GET_SUBREGISTER_OFFICE = 'GET_SUBREGISTER_OFFICE';
export const GET_PROJECT_FILE_DOCUMENT = 'GET_PROJECT_FILE_DOCUMENT';
export const GET_DEPENDENT_RELATIONSHIP = 'GET_DEPENDENT_RELATIONSHIP';
export const GET_BD_PAYMENT_ADVANCE_DETAIL = 'GET_BD_PAYMENT_ADVANCE_DETAIL';
export const GET_PROJECT_DETAIL = 'GET_PROJECT_DETAIL';
export const GET_PROJECT_DROP_REASON_LIST = 'GET_PROJECT_DROP_REASON_LIST';
export const GET_COMPANY_PROJECT_MAP = 'GET_COMPANY_PROJECT_MAP';
export const GET_PROJECT = 'GET_PROJECT';
export const GET_COMPANY = 'GET_COMPANY';
export const GET_DROPPED_PROJECT_LIST = 'GET_DROPPED_PROJECT_LIST';
export const GET_INSERTED_LAND_OWNER_DATA = 'GET_INSERTED_LAND_OWNER_DATA';
export const GET_INSERTED_LAND_BROKER_DATA = 'GET_INSERTED_LAND_BROKER_DATA';
export const GET_LAND_OWNER_ACCOUNT_DETAIL = 'GET_LAND_OWNER_ACCOUNT_DETAIL';
export const GET_LAND_BROKER_ACCOUNT_DETAIL = 'GET_LAND_BROKER_ACCOUNT_DETAIL';
export const GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST = 'GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST';
export const GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST = 'GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST';
export const GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST = 'GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const UPDATE_EMPLOYEE_PROFILE = 'UPDATE_EMPLOYEE_PROFILE';
export const GET_BIRTHDAY = "GET_BIRTHDAY";
export const GET_EMPLOYEE_WORK_ANNIVERSARY = "GET_EMPLOYEE_WORK_ANNIVERSARY";
export const GET_PROJECT_LIST_FILTER = "GET_PROJECT_LIST_FILTER";
export const GET_PROJECT_UPCOMING_FILTER = "GET_PROJECT_UPCOMING_FILTER";
export const GET_CIVIL_PROJECT_FILTER = "GET_CIVIL_PROJECT_FILTER";
export const GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL = "GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL";
export const GET_PROJECT_FILE_FILTER = "GET_PROJECT_FILE_FILTER";
export const GET_LIAISON_PROCESS_FILTER = "GET_LIAISON_PROCESS_FILTER";
export const GET_APF_PANEL = "GET_APF_PANEL";
export const GET_APF_PENDING_PROJECT_FILE = "GET_APF_PENDING_PROJECT_FILE";
export const GET_APF_TASK_PLAN = "GET_APF_TASK_PLAN";
export const GET_APF_TASK_PLAN_DETAIL = "GET_APF_TASK_PLAN_DETAIL";
export const GET_APF_TASK_PLAN_PROJECT_FILTER = "GET_APF_TASK_PLAN_PROJECT_FILTER";
export const GET_APF_TASK_PLAN_PANEL_FILTER = "GET_APF_TASK_PLAN_PANEL_FILTER";
export const GET_APF_TASK_PLAN_PROJECT_FILE_FILTER = "GET_APF_TASK_PLAN_PROJECT_FILE_FILTER";
export const GET_APF_TASK_PLAN_TASK_FILTER = "GET_APF_TASK_PLAN_TASK_FILTER";
export const GET_APF_TASK_FILTER = "GET_APF_TASK_FILTER";
export const GET_APF_TASK_PLAN_QUERY = "GET_APF_TASK_PLAN_QUERY";
export const CREATE_APF_TASK_PLAN_QUERY = "CREATE_APF_TASK_PLAN_QUERY";
export const GET_APF_TASK_PLAN_QUERY_DETAIL = "GET_APF_TASK_PLAN_QUERY_DETAIL";
export const GET_LIAISON_DOCUMENTATION_REPORT = "GET_LIAISON_DOCUMENTATION_REPORT";
export const GET_BD_LAND_DISPUTE_TYPE = "GET_BD_LAND_DISPUTE_TYPE";
export const GET_BD_LAND_DISPUTE_LIST = "GET_BD_LAND_DISPUTE_LIST";
export const GET_BD_LAND_DISPUTE_DETAIL = "GET_BD_LAND_DISPUTE_DETAIL";
export const GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER = "GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER";
export const GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER = "GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER";
export const GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER = "GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER";
export const GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER = "GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER";
export const GET_COURT_CASE_TYPE = "GET_COURT_CASE_TYPE";
export const GET_COURT_CASE_STATUS = "GET_COURT_CASE_STATUS";
export const GET_COURT_CASE_JURISDICTION = "GET_COURT_CASE_JURISDICTION";
export const GET_DIRECTOR = "GET_DIRECTOR";
export const GET_COURT_CASE = "GET_COURT_CASE";
export const GET_COURT_CASE_DETAIL = "GET_COURT_CASE_DETAIL";
export const GET_TRAINING_CONTENT = "GET_TRAINING_CONTENT";
export const GET_BD_PROJECT_TASK_REJECTION_REASON = "GET_BD_PROJECT_TASK_REJECTION_REASON";
export const GET_BD_AGREEMENT_TYPE = "GET_BD_AGREEMENT_TYPE";
export const GET_BD_PROJECT_REPORT = "GET_BD_PROJECT_REPORT";
export const GET_BD_PROJECT_TASK_REPORT = "GET_BD_PROJECT_TASK_REPORT";
export const GET_BD_PROJECT_TASK_REPORT_EXPORT = "GET_BD_PROJECT_TASK_REPORT_EXPORT";
export const GET_PROJECT_PROPOSED_NAME = "GET_PROJECT_PROPOSED_NAME";
// export const GET_TRAINING_CONTENT = "GET_TRAINING_CONTENT";
export const GET_TRAINING = "GET_TRAINING";
export const GET_LIAISON_PROCESS_PLAN_PROJECT = "GET_LIAISON_PROCESS_PLAN_PROJECT";
export const GET_LIAISON_PROCESS_PLAN_PROCESS = "GET_LIAISON_PROCESS_PLAN_PROCESS";
export const GET_LIAISON_PAYMENT_LIST = "GET_LIAISON_PAYMENT_LIST";
export const GET_LIAISON_PAYMENT_DETAIL = "GET_LIAISON_PAYMENT_DETAIL";
export const GET_LIAISON_PAYMENT_SURVEY_NUMBER = "GET_LIAISON_PAYMENT_SURVEY_NUMBER";
export const GET_LIAISON_PAYMENT_TASK_PLAN = "GET_LIAISON_PAYMENT_TASK_PLAN";
export const GET_FUNCTIONALITY_PERMISSION_DETAIL = "GET_FUNCTIONALITY_PERMISSION_DETAIL";
export const GET_LIAISON_PAYMENT_FILTER_PROJECT = "GET_LIAISON_PAYMENT_FILTER_PROJECT";
export const GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY = "GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY";
export const GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER = "GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER";
export const GET_PROJECT_TASK_PLAN_FILTER_TASK = "GET_PROJECT_TASK_PLAN_FILTER_TASK";
export const UPDATE_LIAISON_TASK_PLAN_START = "UPDATE_LIAISON_TASK_PLAN_START";
export const GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING = "GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING";
export const GET_LIAISON_APPLICABLE_PROCESS_PLAN = "GET_LIAISON_APPLICABLE_PROCESS_PLAN";
export const GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT = 'GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT';
export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL = "GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL";
export const GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL = "GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL";
export const GET_PROJECT_TASK_PLAN_FILTER_TASK_CATEGORY = "GET_PROJECT_TASK_PLAN_FILTER_TASK_CATEGORY";
export const GET_LIAISON_PROCESS_PLAN_APPLICATION_DETAIL_1 = "GET_LIAISON_PROCESS_PLAN_APPLICATION_DETAIL_1";
export const GET_LIAISON_TASK_PLAN_DETAIL = "GET_LIAISON_TASK_PLAN_DETAIL";
export const GET_LIAISON_PROCESS_PLAN_PENDING_DETAIL = "GET_LIAISON_PROCESS_PLAN_PENDING_DETAIL";
export const GET_LIAISON_TASK = "GET_LIAISON_TASK";
export const GET_LIAISON_PROCESS_PLAN_PENDING_TASK = "GET_LIAISON_PROCESS_PLAN_PENDING_TASK";
export const GET_LIAISON_PROCESS_OPTIONAL = "GET_LIAISON_PROCESS_OPTIONAL";
export const GET_LIAISON_PROCESS_SURVEY_NUMBER_OPTIONAL = "GET_LIAISON_PROCESS_SURVEY_NUMBER_OPTIONAL";
export const GET_LIAISON_PROCESS_PLAN = "GET_LIAISON_PROCESS_PLAN";
export const GET_LIAISON_PROCESS_PLAN_APPROVAL = "GET_LIAISON_PROCESS_PLAN_APPROVAL";
export const GET_LIAISON_PROCESS_PLAN_DETAIL = "GET_LIAISON_PROCESS_PLAN_DETAIL";
export const GET_LIAISON_PROCESS_PLAN_FILTER_PROJECT = "GET_LIAISON_PROCESS_PLAN_FILTER_PROJECT";
export const GET_LIAISON_PROCESS_PLAN_FILTER_PROCESS = "GET_LIAISON_PROCESS_PLAN_FILTER_PROCESS";
export const GET_NEWS_LETTER = "GET_NEWS_LETTER";
export const GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_FILE = "GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_FILE";
export const GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_CATEGORY = "GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_CATEGORY";
export const GET_LIAISON_PROCESS_PLAN_EXPORT = "GET_LIAISON_PROCESS_PLAN_EXPORT";
export const GET_PROJECT_ZONE = "GET_PROJECT_ZONE";
export const GET_LIAISON_PROCESS = "GET_LIAISON_PROCESS";
export const GET_LIAISON_PROCESS_CATEGORY1 = "GET_LIAISON_PROCESS_CATEGORY1"
export const GET_COURT_CASE_HANDLED_BY = "GET_COURT_CASE_HANDLED_BY";
export const GET_BD_LAND_DISPUTE_HANDLED_BY = "GET_BD_LAND_DISPUTE_HANDLED_BY";
export const GET_BD_LEGAL_TASK_LIST_EXPORT = "GET_BD_LEGAL_TASK_LIST_EXPORT";
export const GET_BD_LAND_DISPUTE_EXPORT = "GET_BD_LAND_DISPUTE_EXPORT";
export const GET_COURT_CASE_EXPORT = "GET_COURT_CASE_EXPORT";
export const GET_COURT_CASE_PROJECT_FILTER = "GET_COURT_CASE_PROJECT_FILTER";
export const GET_PROJECT_DEED = "GET_PROJECT_DEED";
export const GET_BD_PROJECT_TASK = "GET_BD_PROJECT_TASK";
export const GET_BD_PROJECT_CONSOLIDATED_REPORT = "GET_BD_PROJECT_CONSOLIDATED_REPORT";
export const GET_PROJECT_FILE_ACQUISITION_STATUS_FILTER = "GET_PROJECT_FILE_ACQUISITION_STATUS_FILTER";
export const GET_BD_PROJECT_CONSOLIDATED_REPORT_PRINT = "GET_BD_PROJECT_CONSOLIDATED_REPORT_PRINT";
export const GET_BD_FILE_ACQUISITION_REASON = "GET_BD_FILE_ACQUISITION_REASON";
export const GET_PROJECT_LEGAL_OWNER_FILTER = "GET_PROJECT_LEGAL_OWNER_FILTER";
export const GET_PROJECT_BD_OWNER_FILTER = "GET_PROJECT_BD_OWNER_FILTER";
export const GET_LIAISON_PROCESS_PLAN_APPLICABILITY_REASON = "GET_LIAISON_PROCESS_PLAN_APPLICABILITY_REASON";
export const GET_LOAN_PANEL = "GET_LOAN_PANEL";
export const GET_LOAN_APPLICATION_ELIGIBLE_PROJECT = "GET_LOAN_APPLICATION_ELIGIBLE_PROJECT";
export const GET_LOAN_APPLICATION_ELIGIBLE_PROJECT_FILE = "GET_LOAN_APPLICATION_ELIGIBLE_PROJECT_FILE";
export const GET_LOAN_PROCESS_PLAN = "GET_LOAN_PROCESS_PLAN";
export const GET_LOAN_TASK_PLAN = "GET_LOAN_TASK_PLAN";
export const GET_LOAN_TASK_PLAN_DETAIL = "GET_LOAN_TASK_PLAN_DETAIL";
export const GET_PROJECT_LIAISON_OWNER_FILTER = "GET_PROJECT_LIAISON_OWNER_FILTER";
export const GET_LOAN_PROCESS_PLAN_DETAIL = "GET_LOAN_PROCESS_PLAN_DETAIL";
export const GET_LOAN_PROCESS_PLAN_PROJECT_FILTER = "GET_LOAN_PROCESS_PLAN_PROJECT_FILTER";
export const GET_LOAN_PROCESS_PLAN_PROCESS_FILTER = "GET_LOAN_PROCESS_PLAN_PROCESS_FILTER";
export const GET_LOAN_PROCESS_PLAN_BANK_FILTER = "GET_LOAN_PROCESS_PLAN_BANK_FILTER";
export const GET_LOAN_PROCESS_PLAN_OWNER_FILTER = "GET_LOAN_PROCESS_PLAN_OWNER_FILTER";
export const GET_LIAISON_PROCESS_PLAN_DELAYED = "GET_LIAISON_PROCESS_PLAN_DELAYED";
export const GET_LIAISON_PROCESS_PLAN_DELAYED_EXPORT = "GET_LIAISON_PROCESS_PLAN_DELAYED_EXPORT";
// -------------Sadanand ___________
export const GET_PROJECT_FILE_ACQUISITION_PENDING = "getProjectFileAcquisitionPending";
export const GET_ACQUIRED_FILE_EXPORT = "GET_ACQUIRED_FILE_EXPORT";
export const GET_FILE_ACQUISITION_INFO_EXPORT = "GET_FILE_ACQUISITION_INFO_EXPORT";
export const GET_PROJECT_FILE_EXPORT = "GET_PROJECT_FILE_EXPORT";
export const GET_PROJECT_FILE_ACQUISTION_PENDING_DETAIL = "GET_PROJECT_FILE_ACQUISTION_PENDING_DETAIL";
export const GET_PROJECT_FILE_ACQUISTION_HANDLED_BY_FILTER = "GET_PROJECT_FILE_ACQUISTION_HANDLED_BY_FILTER";
export const GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT = "GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT";
export const GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_FILTER_PROJECT = "GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_FILTER_PROJECT";
export const GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_EXPORT = "GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_EXPORT";
export const GET_PROJECT_MOU_REPORT_KPI = "GET_PROJECT_MOU_REPORT_KPI";
export const GET_PROJECT_MOU_REPORT_KPI_EXPORT = "GET_PROJECT_MOU_REPORT_KPI_EXPORT";
// BD Master
export const GET_BD_LAND_DISPUTE_TYPE_MASTER = "GET_BD_LAND_DISPUTE_TYPE_MASTER";
export const GET_LIAISON_PROCESS_CATEGORY_MASTER = "GET_LIAISON_PROCESS_CATEGORY_MASTER";
export const GET_BD_PROJECT_TASK_CATEGORY_MASTER = "GET_BD_PROJECT_TASK_CATEGORY_MASTER";
export const PROJECT_TASK_DEPENDENCY_MASTER = "PROJECT_TASK_DEPENDENCY_MASTER";
export const PROJECT_DEPENDENT_TASK_MASTER = "PROJECT_DEPENDENT_TASK_MASTER";
export const GET_PLANNING_AUTHORITY_MASTER = "GET_PLANNING_AUTHORITY_MASTER";
export const GET_LIAISON_CATEGORY_MASTER = "GET_LIAISON_CATEGORY_MASTER";
export const GET_LIAISON_PROCESS_DEPENDENCY_MASTER = "GET_LIAISON_PROCESS_DEPENDENCY_MASTER";
export const GET_BD_LAND_DISPUTE_TYPE_MASTER_EXPORT = "GET_BD_LAND_DISPUTE_TYPE_MASTER_EXPORT";
export const GET_LIAISON_PROCESS_MASTER = "GET_LIAISON_PROCESS_MASTER";
export const GET_LIAISON_PROCESS_CATEGORY_MASTER_NEW = "GET_LIAISON_PROCESS_CATEGORY_MASTER_NEW";
export const GET_LIAISON_PROCESS_DETAIL = "GET_LIAISON_PROCESS_DETAIL";
export const GET_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE_LIST = "GET_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE_LIST";
export const GET_LIAISON_PROCESS_PLAN_FILTER_SY_NUMBER = "GET_LIAISON_PROCESS_PLAN_FILTER_SY_NUMBER";
export const GET_LIAISON_DOC_TASK_PLAN_FILTER_ACCOUNTABLE = "GET_LIAISON_DOC_TASK_PLAN_FILTER_ACCOUNTABLE";
export const GET_BD_PAYMENT_MILESTONE_MASTER = "GET_BD_PAYMENT_MILESTONE_MASTER";
export const GET_PROJECT_TASK_REJECTION_REASON_MASTER = "GET_PROJECT_TASK_REJECTION_REASON_MASTER";
export const GET_AGREEMENT_TYPE_MASTER = "GET_AGREEMENT_TYPE_MASTER";
export const GET_PROJECT_MOU_REPORT_KPI_FILTER_PROJECT = "GET_PROJECT_MOU_REPORT_KPI_FILTER_PROJECT";
export const GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL_EXPORT = "GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL_EXPORT";
export const GET_COURT_ESTABLISHMENT = "GET_COURT_ESTABLISHMENT";
export const GET_COURT_CASE_STATUS_MASTER = "GET_COURT_CASE_STATUS_MASTER";
export const GET_COURT_CASE_TYPE_MASTER = "GET_COURT_CASE_TYPE_MASTER";
export const GET_REGISTRATION_FEES_RATE_LIST = "GET_REGISTRATION_FEES_RATE_LIST";
export const GET_REGISTRATION_FEES = "GET_REGISTRATION_FEES";
export const GET_LIAISON_PROCESS_DEPENDENCY = "GET_LIAISON_PROCESS_DEPENDENCY";
export const GET_LIASION_PROCESSES_EXCEPTION = "GET_LIASION_PROCESSES_EXCEPTION";
export const GET_APF_TASK_LIST = "GET_APF_TASK_LIST";
export const GET_APF_PANEL_LIST = "GET_APF_PANEL_LIST";
export const GET_MASTER = "GET_MASTER";
export const GET_PROJECT_DOCUMENT_TYPE = "GET_PROJECT_DOCUMENT_TYPE";
export const GET_LIASISON_DELAY_REASON = "GET_LIASISON_DELAY_REASON";
export const GET_BANK = "GET_BANK";
export const GET_BANK_CATEGORY = "GET_BANK_CATEGORY";
export const GET_LOAN_TASK = "GET_LOAN_TASK";
export const GET_LOAN_PROCESS = "GET_LOAN_PROCESS";
export const GET_LOAN_PANEL_MASTER = "GET_LOAN_PANEL_MASTER";
export const GET_LIAISON_PAYMENT_EXPORT = "GET_LIAISON_PAYMENT_EXPORT";
export const GET_PROJECT_TASK_TRANSACTION = "GET_PROJECT_TASK_TRANSACTION";
export const GET_BD_TASK_APPROVAL = "GET_BD_TASK_APPROVAL";
export const GET_PROJECT_EXPORT = "GET_PROJECT_EXPORT";
export const GET_SUB_REGISTRAR_OFFICE = "GET_SUB_REGISTRAR_OFFICE";
export const GET_SUB_REGISTRAR_MAPPING = "GET_SUB_REGISTRAR_MAPPING";
export const GET_REGISTRATION_FEES_MASTER = "GET_REGISTRATION_FEES_MASTER";
export const GET_REGISTRATION_FEES_RATE_MASTER = "GET_REGISTRATION_FEES_RATE_MASTER";
export const GET_LIAISON_PROCESS_APPLICABILITY_REASON = "GET_LIAISON_PROCESS_APPLICABILITY_REASON";
export const GET_LANDLORD_MEETING = "GET_LANDLORD_MEETING";
export const GET_LANDLORD_MEETING_STAGE = "GET_LANDLORD_MEETING_STAGE";
export const GET_LANDLORD_MEETING_PARTICULAR = "GET_LANDLORD_MEETING_PARTICULAR";
export const GET_NEWS_LETTER_CATEGORY = "GET_NEWS_LETTER_CATEGORY";
export const GET_LIASISON_DELAY_REASON_MASTER = "GET_LIASISON_DELAY_REASON_MASTER";
export const GET_PROJECT_DOCUMENT_TYPE_EXPORT = "GET_PROJECT_DOCUMENT_TYPE_EXPORT";
export const GET_BANK_MASTER_EXPORT = "GET_BANK_MASTER_EXPORT";
export const GET_APF_PANEL_EXPORT = "GET_APF_PANEL_EXPORT";
export const GET_LOAN_PROCESS_EXPORT = "GET_LOAN_PROCESS_EXPORT";
export const GET_LOAN_PANEL_EXPORT = "GET_LOAN_PANEL_EXPORT";
export const GET_LIAISON_TASK_EXPORT = "GET_LIAISON_TASK_EXPORT";
export const GET_LIAISON_PROCESS_MASTER_EXPORT = "GET_LIAISON_PROCESS_MASTER_EXPORT";
export const GET_BD_PROJECT_TASK_MASTER_EXPORT = "GET_BD_PROJECT_TASK_MASTER_EXPORT";
export const GET_APF_TASK_PLAN_REPORT = "GET_APF_TASK_PLAN_REPORT";
export const GET_LIASISON_DELAY_REASON_MASTER_EXPORT = "GET_LIASISON_DELAY_REASON_MASTER_EXPORT";
export const GET_REGISTRATION_FEES_RATE_EXPORT_MASTER = "GET_REGISTRATION_FEES_RATE_EXPORT_MASTER";
export const GET_AGREEMENT_TYPE_EXPORT_MASTER = "GET_AGREEMENT_TYPE_EXPORT_MASTER";
export const GET_LIASION_PROCESSES_EXCEPTION_EXPORT = "GET_LIASION_PROCESSES_EXCEPTION_EXPORT";
export const GET_PROJECT_TASK_REJECTION_REASON_EXPORT_MASTER = "GET_PROJECT_TASK_REJECTION_REASON_EXPORT_MASTER";
export const GET_BD_PAYMENT_MILESTONE_EXPORT_MASTER = "GET_BD_PAYMENT_MILESTONE_EXPORT_MASTER";
export const GET_REGISTRATION_FEES_EXPORT_MASTER = "GET_REGISTRATION_FEES_EXPORT_MASTER";
export const GET_PLANNING_AUTHORITY_EXPORT_MASTER = "GET_PLANNING_AUTHORITY_EXPORT_MASTER"
export const PROJECT_TASK_DEPENDENCY_EXPORT_MASTER = "PROJECT_TASK_DEPENDENCY_EXPORT_MASTER";
export const GET_BD_PROJECT_TASK_CATEGORY_EXPORT_MASTER = "GET_BD_PROJECT_TASK_CATEGORY_EXPORT_MASTER";
export const GET_BD_TASK_EXPORT_APPROVAL = "GET_BD_TASK_EXPORT_APPROVAL";
export const GET_PROJECT_DROP_REASON_EXPORT_LIST = "GET_PROJECT_DROP_REASON_EXPORT_LIST";
export const GET_PROJECT_TASK_TRANSACTION_EXPORT = "GET_PROJECT_TASK_TRANSACTION_EXPORT";
export const GET_SUB_REGISTRAR_MAPPING_EXPORT = "GET_SUB_REGISTRAR_MAPPING_EXPORT";
export const GET_LOAN_TASK_EXPORT = "GET_LOAN_TASK_EXPORT";
export const GET_LIAISON_CATEGORY_EXPORT = "GET_LIAISON_CATEGORY_EXPORT";
export const GET_COURT_CASE_TYPE_EXPORT = "GET_COURT_CASE_TYPE_EXPORT";
export const GET_COURT_CASE_STATUS_EXPORT = "GET_COURT_CASE_STATUS_EXPORT";
export const GET_COURT_ESTABLISHMENT_EXPORT = "GET_COURT_ESTABLISHMENT_EXPORT";
export const GET_BD_LAND_DISPUTE_TYPE_EXPORT = "GET_BD_LAND_DISPUTE_TYPE_EXPORT";
export const GET_LIAISON_PROCESS_APPLICABILITY_REASON_EXPORT = "GET_LIAISON_PROCESS_APPLICABILITY_REASON_EXPORT";
export const GET_LIAISON_PROCESS_DEPENDENCY_EXPORT = "GET_LIAISON_PROCESS_DEPENDENCY_EXPORT";
export const GET_LIAISON_PROCESS_CATEGORY_EXPORT = "GET_LIAISON_PROCESS_CATEGORY_EXPORT";
export const GET_APF_TASK_LIST_EXPORT = "GET_APF_TASK_LIST_EXPORT";
export const GET_LAND_LORD_MEETING_PARTICULAR_REMARK = "GET_LAND_LORD_MEETING_PARTICULAR_REMARK";
export const GET_LAND_LORD_MEETING_ATTENDEE = "GET_LAND_LORD_MEETING_ATTENDEE";
export const GET_LAND_LORD_MEETING_PARTICULAR = "GET_LAND_LORD_MEETING_PARTICULAR";
export const GET_LAND_LORD_MEETING_PARTICULAR_EXPORT = "GET_LAND_LORD_MEETING_PARTICULAR_EXPORT";
export const GET_LAND_LORD_MEETING_STAGE_MASTER = "GET_LAND_LORD_MEETING_STAGE_MASTER";
export const GET_LAND_LORD_MEETING_STAGE_EXPORT_MASTER = "GET_LAND_LORD_MEETING_STAGE_EXPORT_MASTER";
export const GET_LANDLORD_MEETING_DETAIL = "GET_LANDLORD_MEETING_DETAIL";
export const GET_APF_TASK_PLAN_REPORT_EXPORT = "GET_APF_TASK_PLAN_REPORT_EXPORT";
export const GET_APF_TASK_PLAN_EXPORT = "GET_APF_TASK_PLAN_EXPORT";
export const GET_APF_TASK_PLAN_QUERY_EXPORT = "GET_APF_TASK_PLAN_QUERY_EXPORT";
export const GET_LAND_LORD_MEETING_MOM="GET_LAND_LORD_MEETING_MOM";
export const GET_LIAISON_PROCESS_DEPENDENT="GET_LIAISON_PROCESS_DEPENDENT";
export const GET_BD_PROJECT_DEED_TYPE="GET_BD_PROJECT_DEED_TYPE";
export const GET_LIAISON_PROCESS_PLAN_MERGER_FILE="GET_LIAISON_PROCESS_PLAN_MERGER_FILE";
export const GET_BD_LAND_DISPUTE_PRIORITY = "GET_BD_LAND_DISPUTE_PRIORITY";
export const GET_APF_TASK_PLAN_QUERY_TEMPLETE="GET_APF_TASK_PLAN_QUERY_TEMPLETE";
export const GET_LIAISON_PROCESS_PLAN_EXCEPTION="GET_LIAISON_PROCESS_PLAN_EXCEPTION";
export const GET_PROJECT_TASK_PLAN_APPROVAL_FILTER_APPROVAL_BY="GET_PROJECT_TASK_PLAN_APPROVAL_FILTER_APPROVAL_BY";
export const GET_LIAISON_PROCESS_PLAN_APPROVAL_FILTER_APPROVER_BY="GET_LIAISON_PROCESS_PLAN_APPROVAL_FILTER_APPROVER_BY";
export const GET_LIAISON_DOCUMENTATION_TASK_PLAN_APPROAVAL_FILTER_APPROVER = "GET_LIAISON_DOCUMENTATION_TASK_PLAN_APPROAVAL_FILTER_APPROVER";
export const GET_APF_TASK_PLAN_FILE="GET_APF_TASK_PLAN_FILE";
export const GET_LANDLORD_MEETING_DETAIL_PRINT="GET_LANDLORD_MEETING_DETAIL_PRINT";
export const GET_APF_TASK_PLAN_QUERY_FILTER_FILE="GET_APF_TASK_PLAN_QUERY_FILTER_FILE";
export const GET_LIAISON_PROCESS_AUTHORITY_MAP = "GET_LIAISON_PROCESS_AUTHORITY_MAP";
export const GET_APF_TASK_PLAN_QUERY_FILTER_RESPONSIBLE="GET_APF_TASK_PLAN_QUERY_FILTER_RESPONSIBLE"
export const GET_APF_TASK_PLAN_QUERY_FILTER_ACCOUNTABLE="GET_APF_TASK_PLAN_QUERY_FILTER_ACCOUNTABLE"
export const GET_APF_TASK_PLAN_OWNER_FILTER="GET_APF_TASK_PLAN_OWNER_FILTER";
export const GET_APF_TASK_PLAN_ACCOUNTABLE_FILTER="GET_APF_TASK_PLAN_ACCOUNTABLE_FILTER";
export const GET_PROJECT_INSERTED_DATA="GET_PROJECT_INSERTED_DATA";
export const GET_PROJECT_ZONE_EXPORT = "GET_PROJECT_ZONE_EXPORT";
export const GET_LAND_LORD_MEETING_EXPORT = "GET_LAND_LORD_MEETING_EXPORT";
