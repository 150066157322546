import {ApiPaths, ApiService, SetAuthToken, ApiStatus} from '../../utils/ApiService';
import {GET_PROFITABLE_REPORTS, GET_LIASONPROCESSOWNER, GET_PROJECT_NAMES, GET_TASK_DELAYS, GET_PROFITABLE_REPORT_COUNT, GET_PENDING_TASK, GET_LIAISON_PROJECT_NAMES, GET_DASHBOARD_DATA, GET_USER_DASHBOARD_DATA, GET_USER_DASHBOARD_TASK,GET_CIVIL_PROCESS_DATA,GET_ENQUIRY_SOURCE,

GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA,GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA,GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA,
    GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA,
    GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA,
    GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA,SET_FUNNEL_ITEM_ID,
    GET_DASHBOARD_STOCK_ZONE_DATA,GET_DASHBOARD_STOCK_PROJECT_DATA,GET_DASHBOARD_STOCK_PROJECT_SITE_DATA,

    GET_DASHBOARD_STOCK_AGING_ZONE_DATA,GET_DASHBOARD_STOCK_AGING_PROJECT_DATA,GET_DASHBOARD_STOCK_AGING_FILE_DATA,
    
    GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA,GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA,GET_DASHBOARD_LAND_AQUIRED_FILE_DATA,

    GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA,GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA,GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA,

    GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA,GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA,GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA,GET_DASHBOARD_DATA_DEPARTMENT,GET_DASHBOARD_DEPARTMENT,

    GET_LIAISON_PROCESS_PLAN_REPORT,GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL,GET_MY_DASHBOARD_DATA_FILTER_USER,GET_DASHBOARD_CRM_PROJECT_OUTSTANDING,GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT,GET_DASHBOARD_CRM_SITE_OUTSTANDING,GET_DASHBOARD_CRM_SITE_OUTSTANDING_EXPORT

} from './types';
import {mainLoadingTrue, mainLoadingFalse, dashBoardLoadingTrue, dashBoardLoadingFalse} from '../User/action'
import { toast } from 'react-toastify';
import {getItem, setItem} from '../../utils/crypto';

const userLocal = getItem('enuseloc');
const LoginuserId = userLocal && Object.keys(userLocal).length > 0 ? userLocal['user_id'] : null;

export const getProfitableReports_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.PROFITABLE_REPORTS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(profitableReportsSuccess(data.process))
                dispatch(profitableReportCountSuccess(data.totalCount))
            }
            else{
                dispatch(profitableReportsSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(profitableReportsSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const profitableReportsSuccess = (data) => {
    return {
        type : GET_PROFITABLE_REPORTS,
        payload : data
    }
}
export const profitableReportCountSuccess = (data) => {
    return {
        type : GET_PROFITABLE_REPORT_COUNT,
        payload : data
    }
}


export const getLiasonProcessOwner_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.LIASONPROCESSOWNER, data).then(response => {
            const {data}  = response;
            if(!data.error){
                dispatch(getLiasonProcessOwnerSuccess(data.user))
            }
            else{
                dispatch(getLiasonProcessOwnerSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiasonProcessOwnerSuccess([]))
            dispatch(mainLoadingFalse());
        }).finally(()=>{
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiasonProcessOwnerSuccess = (data) => {
    return {
        type : GET_LIASONPROCESSOWNER,
        payload : data
    }
}


export const getProjectsNames_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETPROJECTS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getProjectsNamesSuccess(data.project))
            }
            else{
                dispatch(getProjectsNamesSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectsNamesSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectsNamesSuccess = (data) => {
    return {
        type : GET_PROJECT_NAMES,
        payload : data
    }
}

export const getLiaisonProjectsNames_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETLIAISONPROJECTS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonProjectsNamesSuccess(data.project))
            }
            else{
                dispatch(getLiaisonProjectsNamesSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonProjectsNamesSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getLiaisonProjectsNamesSuccess = (data) => {
    return {
        type : GET_LIAISON_PROJECT_NAMES,
        payload : data
    }
}


export const getTaskDelayReason_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETTASKDELAY, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getTaskDelayReasonSuccess(data.reason))
            }
            else{
                dispatch(getTaskDelayReasonSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getTaskDelayReasonSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getTaskDelayReasonSuccess = (data) => {
    return {
        type : GET_TASK_DELAYS,
        payload : data
    }
}


export const getPendingTasks_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETPENDINGTASK, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getPendingTasksSuccess(data.tasks))
            }
            else{
                dispatch(getPendingTasksSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getPendingTasksSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getPendingTasksSuccess = (data) => {
    return {
        type : GET_PENDING_TASK,
        payload : data
    }
}

export const getDashboardData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "leadType":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        dispatch(dashBoardLoadingTrue());
        return ApiService.post(ApiPaths.GETDASHBOARDDATA, initialPayload).then(response => {
            const {data}  = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            dispatch(dashBoardLoadingFalse());
            if(!data.error){
                dispatch(getDashboardDataSuccess(data.dashboardData))
                // dispatch(getBDLandAcquisitionDataSuccess(data.dashboardData.landAcquisitionData))
                // dispatch(getBDPlanApprovalDataSuccess(data.dashboardData.planApprovalData))
                // dispatch(getBDPlanApprovedDataSuccess(data.dashboardData.planApprovedData))
            }
            else{
                dispatch(getDashboardDataSuccess([]))
                // dispatch(getBDLandAcquisitionDataSuccess([]))
                // dispatch(getBDPlanApprovalDataSuccess([]))
                // dispatch(getBDPlanApprovedDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardDataSuccess([]))
            // dispatch(getBDLandAcquisitionDataSuccess([]))
            // dispatch(getBDPlanApprovalDataSuccess([]))
            // dispatch(getBDPlanApprovedDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_DATA,
        payload : data
    }
}




// _________SEPERATE CHART DATA FOR LAND ACQUISITION 

export const getDashboardLandAquiredFinancialYearData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        "financialYear":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA, initialPayload).then(response => {
            const {data}  = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getBDLandAcquisitionDataSuccess(data.dashboardData))
            }
            else{
                dispatch(getBDLandAcquisitionDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getBDLandAcquisitionDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardApplanApprovalFinancialYearData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        "financialYear":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA, initialPayload).then(response => {
            const {data}  = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getBDPlanApprovalDataSuccess(data.dashboardData))
            }
            else{
                dispatch(getBDPlanApprovalDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getBDPlanApprovalDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardApplanApprovedTargetActualFinancialYearData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        "financialYear":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA, initialPayload).then(response => {
            const {data}  = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getBDPlanApprovedDataSuccess(data.dashboardData))
            }
            else{
                dispatch(getBDPlanApprovedDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getBDPlanApprovedDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getBDLandAcquisitionDataSuccess = (dashboardData) => {
    return {
        type : GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA,
        payload : dashboardData
    }
}

export const getBDPlanApprovalDataSuccess = (dashboardData) => { 
    return {
        type : GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA,
        payload : dashboardData
    }
}

export const getBDPlanApprovedDataSuccess = (dashboardData) => {
    return {
        type : GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA,
        payload : dashboardData
    }
}





export const getMyDashBoardData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "reportUserID":LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_MY_DASHBOARD_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getMyDashBoardDataSuccess(data))
            }
            else{
                dispatch(getMyDashBoardDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getMyDashBoardDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getMyDashBoardDataSuccess = (data) => {
    return {
        type : GET_USER_DASHBOARD_DATA,
        payload : data
    }
}

export const getMyDashBoardTask_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "reportUserID":LoginuserId,
        "projectFundID": "",
        "status": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_MY_DASHBOARD_TASK, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getMyDashBoardTaskSuccess(data))
            }
            else{
                dispatch(getMyDashBoardTaskSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getMyDashBoardTaskSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getMyDashBoardTaskSuccess = (data) => {

    return {
        type : GET_USER_DASHBOARD_TASK,
        payload : data
    }
}


export const getCivilProcessData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectId":"",
        "processId": [],
        "fromDate": "",
        "toDate": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": 10,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_CIVIL_PROCESS_DATA, initialPayload).then(response => {
            const {data}  = response;

            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilProcessDataSuccess(data?.dashboardData))
            }
            else{
                dispatch(getCivilProcessDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProcessDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProcessDataSuccess = (data) => {

    return {
        type : GET_CIVIL_PROCESS_DATA,
        payload : data
    }
}
export const getEnquirySource_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "groupID": "",
        "categoryID": "",
        "sourceStatus": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_ENQUIRY_SOURCE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getEnquirySourceSuccess(data.data))
            }
            else{
                dispatch(getEnquirySourceSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getEnquirySourceSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getEnquirySourceSuccess = (data) => {
    return {
        type : GET_ENQUIRY_SOURCE,
        payload : data
    }
}



// For MOU Funnel

export const getDashboardAquiredButPlanApprovalPendingZoneData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardAquiredButPlanApprovalPendingZoneDataSuccess(data.dashboardData))
            }
            else{
                dispatch(getDashboardAquiredButPlanApprovalPendingZoneDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardAquiredButPlanApprovalPendingZoneDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardAquiredButPlanApprovalPendingZoneDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA,
        payload : data
    }
}

export const getDashboardAquiredButPlanApprovalPendingProjectData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardAquiredButPlanApprovalPendingProjectDataSuccess(data.dashboardData))
            }
            else{
                dispatch(getDashboardAquiredButPlanApprovalPendingProjectDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardAquiredButPlanApprovalPendingProjectDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardAquiredButPlanApprovalPendingProjectDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA,
        payload : data
    }
}

export const getDashboardAquiredButPlanApprovalPendingFileData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardAquiredButPlanApprovalPendingFileDataSuccess(data))
            }
            else{
                dispatch(getDashboardAquiredButPlanApprovalPendingFileDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardAquiredButPlanApprovalPendingFileDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardAquiredButPlanApprovalPendingFileDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA,
        payload : data
    }
}






// For Plan Approval Funnel

export const getDashboardPlanApprovalAppliedZoneData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardPlanApprovalAppliedZoneDataSuccess(data.dashboardData))
            }
            else{
                dispatch(getDashboardPlanApprovalAppliedZoneDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardPlanApprovalAppliedZoneDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardPlanApprovalAppliedZoneDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA,
        payload : data
    }
}

export const getDashboardPlanApprovalAppliedProjectData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardPlanApprovalAppliedProjectDataSuccess(data.dashboardData))
            }
            else{
                dispatch(getDashboardPlanApprovalAppliedProjectDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardPlanApprovalAppliedProjectDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardPlanApprovalAppliedProjectDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA,
        payload : data
    }
}

export const getDashboardPlanApprovalAppliedFileData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardPlanApprovalAppliedFileDataDataSuccess(data))
            }
            else{
                dispatch(getDashboardPlanApprovalAppliedFileDataDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardPlanApprovalAppliedFileDataDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardPlanApprovalAppliedFileDataDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA,
        payload : data
    }
}

export const setFunnelItemID_action = (itemID) => {
        return {
            type : SET_FUNNEL_ITEM_ID,
            payload : itemID
        }
}


//  for sale inventory

export const getDashboardStockZoneData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_STOCK_ZONE_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardStockZoneDataSuccess(data.dashboardData))
            }
            else{
                dispatch(getDashboardStockZoneDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardStockZoneDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardStockZoneDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_STOCK_ZONE_DATA,
        payload : data
    }
}

export const getDashboardStockProjectData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_STOCK_PROJECT_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardStockProjectDataSuccess(data.dashboardData))
            }
            else{
                dispatch(getDashboardStockProjectDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardStockProjectDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardStockProjectDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_STOCK_PROJECT_DATA,
        payload : data
    }
}

export const getDashboardStockProjectSiteData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_STOCK_PROJECT_SITE_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardStockProjectSiteDataSuccess(data))
            }
            else{
                dispatch(getDashboardStockProjectSiteDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardStockProjectSiteDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardStockProjectSiteDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_STOCK_PROJECT_SITE_DATA,
        payload : data
    }
}

export const getDashboardStockAgingZoneData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "financialYear":"",
        "projectZoneID":[],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_STOCK_AGING_ZONE_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardStockAgingZoneDataSuccess(data.dashboardData))
            }
            else{
                dispatch(getDashboardStockAgingZoneDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardStockAgingZoneDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardStockAgingZoneDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_STOCK_AGING_ZONE_DATA,
        payload : data
    }
}


export const getDashboardStockAgingProjectData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "financialYear":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_STOCK_AGING_PROJECT_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardStockAgingProjectDataSuccess(data.dashboardData))
            }
            else{
                dispatch(getDashboardStockAgingProjectDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardStockAgingProjectDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardStockAgingProjectDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_STOCK_AGING_PROJECT_DATA,
        payload : data
    }
}

export const getDashboardStockAgingFileData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        "projectID":[],
        "financialYear":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_STOCK_AGING_FILE_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardStockAgingFileDataSuccess(data))
            }
            else{
                dispatch(getDashboardStockAgingFileDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardStockAgingFileDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardStockAgingFileDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_STOCK_AGING_FILE_DATA,
        payload : data
    }
}
export const getDashboardLandAquiredZoneData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        "financialYearMonth":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardLandAquiredZoneDataSuccess(data?.dashboardData))
            }
            else{
                dispatch(getDashboardLandAquiredZoneDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardLandAquiredZoneDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardLandAquiredZoneDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA,
        payload : data
    }
}
export const getDashboardLandAquiredProjectData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        "projectID":[],
        "financialYearMonth":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){

                dispatch(getDashboardLandAquiredProjectDataSuccess(data?.dashboardData))
            }
            else{
                dispatch(getDashboardLandAquiredProjectDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardLandAquiredProjectDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardLandAquiredProjectDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA,
        payload : data
    }
}

export const getDashboardLandAquiredFileData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        "projectID":[],
        "financialYearMonth":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_LAND_AQUIRED_FILE_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardLandAquiredFileDataSuccess(data))
            }
            else{
                dispatch(getDashboardLandAquiredFileDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardLandAquiredFileDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardLandAquiredFileDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_LAND_AQUIRED_FILE_DATA,
        payload : data
    }
}

export const getDashboardApplanApprovalZoneData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        "financialYearMonth":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardApplanApprovalZoneDataSuccess(data?.dashboardData))
            }
            else{
                dispatch(getDashboardApplanApprovalZoneDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardApplanApprovalZoneDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardApplanApprovalZoneDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA,
        payload : data
    }
}
export const getDashboardApplanApprovalProjectData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        "projectID":[],
        "financialYearMonth":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){

                dispatch(getDashboardApplanApprovalProjectDataSuccess(data?.dashboardData))
            }
            else{
                dispatch(getDashboardApplanApprovalProjectDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardApplanApprovalProjectDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardApplanApprovalProjectDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA,
        payload : data
    }
}
export const getDashboardApplanApprovalFileData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID":[],
        "projectID":[],
        "financialYearMonth":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardApplanApprovalFileDataSuccess(data))
            }
            else{
                dispatch(getDashboardApplanApprovalFileDataSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardApplanApprovalFileDataSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardApplanApprovalFileDataSuccess = (data) => {
    return {
        type : GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA,
        payload : data
    }
}

export const getDashboardDataDepartment_action = (data) => {

    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_DATA_DEPARTMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardDataDepartmentSuccess(data))
            }
            else{
                dispatch(getDashboardDataDepartmentSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardDataDepartmentSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardDataDepartmentSuccess = (data) => {
    return {
        type : GET_DASHBOARD_DATA_DEPARTMENT,
        payload : data
    }
}

export const getDashboardDepartment_action = (data) => {

    const initialPayload ={
        "userID": LoginuserId,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_DEPARTMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardDepartmentSuccess(data))
            }
            else{
                dispatch(getDashboardDepartmentSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardDepartmentSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardDepartmentSuccess = (data) => {
    return {
        type : GET_DASHBOARD_DEPARTMENT,
        payload : data
    }
}

export const getLiaisonProcessPlanReport_action = (data) => {

    const initialPayload ={
        "userID": LoginuserId,
        "projectID":"",
        "processID":"",
        "processOwnerID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"",
        "iDisplayLength":"10",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_LIAISON_PROCESS_PLAN_REPORT, initialPayload).then(response => {
            
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonProcessPlanReportSuccess(data))
            }
            else{
                dispatch(getLiaisonProcessPlanReportSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonProcessPlanReportSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getLiaisonProcessPlanReportSuccess = (data) => {
    return {
        type : GET_LIAISON_PROCESS_PLAN_REPORT,
        payload : data
    }
}

export const getLiaisonProcessPlanReportDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "processPlanID":"",
        "projectID":"",
        "projectFileID":"",
        "processID":"",
        "completionStatus":"",
        "applicabilityStatus":"",
        "processOwnerID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
            return ApiService.post(ApiPaths.GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getLiaisonProcessPlanReportDetailSuccess(data))
            }
            else{
                dispatch(getLiaisonProcessPlanReportDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getLiaisonProcessPlanReportDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getLiaisonProcessPlanReportDetailSuccess = (data) => {
    return {
        type : GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL,
        payload : data
    }
}

export const getMyDashBoardDataFilterUser_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_MY_DASHBOARD_DATA_FILTER_USER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getMyDashBoardDataFilterUserSuccess(data.data))
            }
            else{
                dispatch(getMyDashBoardDataFilterUserSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getMyDashBoardDataFilterUserSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getMyDashBoardDataFilterUserSuccess = (data) => {
    return {
        type : GET_MY_DASHBOARD_DATA_FILTER_USER,
        payload : data
    }
}

export const getDashboardCrmProjectOutstanding_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectId":[],
        "reportType":"",
        "sortOrder":"",
        "generalSearch":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_PROJECT_OUTSTANDING, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardCrmProjectOutstandingSuccess(data))
            }
            else{
                dispatch(getDashboardCrmProjectOutstandingSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardCrmProjectOutstandingSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getDashboardCrmProjectOutstandingSuccess = (data) => {
    return {
        type : GET_DASHBOARD_CRM_PROJECT_OUTSTANDING,
        payload : data
    }
}

export const getDashboardCrmProjectOutstandingExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectId":[],
        "reportType":"",
        "sortOrder":"",
        "generalSearch":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_PROJECT_OUTSTANDING, initialPayload).then(response => {
            const {data}  = response;
            // dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardCrmProjectOutstandingExportSuccess(data))
                return data;
            }
            else{
                dispatch(getDashboardCrmProjectOutstandingExportSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardCrmProjectOutstandingExportSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getDashboardCrmProjectOutstandingExportSuccess = (data) => {
    return {
        type : GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT,
        payload : data
    }
}

export const getDashboardCrmSiteOutstanding_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "reportType":"",
        "sortOrder":"",
        "generalSearch":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_SITE_OUTSTANDING, initialPayload).then(response => {
            const {data}  = response;
            // dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getDashboardCrmSiteOutstandingSuccess(data))
                return data;
            }
            else{
                dispatch(getDashboardCrmSiteOutstandingSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getDashboardCrmSiteOutstandingSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getDashboardCrmSiteOutstandingSuccess = (data) => {
    return {
        type : GET_DASHBOARD_CRM_SITE_OUTSTANDING,
        payload : data
    }
}

export const getDashboardCrmSiteOutstandingExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "sortOrder":"",
        "generalSearch":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_SITE_OUTSTANDING, initialPayload).then(response => {
            const {data}  = response;
            // dispatch(mainLoadingFalse());
            if(!data.error){
                return data;
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(mainLoadingFalse());
        })
    }
}
