import axios from 'axios';

export const BaseUrl = 'https://edu.dterp.in/RestAPI_V1/';
export const ErpBaseUrl = 'https://edu.dterp.in/RestAPI_V1/';
export const DocumentUrl = 'https://edu.dterp.in/';

// export const BaseUrl = 'http://localhost/gradecard/RestAPI_V1/';
// export const ErpBaseUrl = 'http://localhost/gradecard/RestAPI_V1/';
// export const DocumentUrl = 'http://localhost/gradecard/';

// export const BaseUrl = 'https://knsonline.in/dev/RestAPI_V1/';
// export const ErpBaseUrl = 'http://192.168.0.200/dev/RestAPI_V1/';
// export const DocumentUrl = 'https://knsonline.in/dev/';

// export const BaseUrl = 'https://knsonline.in/test/RestAPI_V1/';
// export const ErpBaseUrl = 'http://192.168.0.200/test/RestAPI_V1/';
// export const DocumentUrl = 'https://knsonline.in/test/';

// export const BaseUrl = 'https://knsonline.in/uat/RestAPI_V1/';
// export const ErpBaseUrl = 'http://192.168.0.200/uat/RestAPI_V1/';
// export const DocumentUrl = 'https://knsonline.in/uat/';

// export const BaseUrl = 'https://knsonline.in/RestAPI_V1/';
// export const ErpBaseUrl = 'http://192.168.0.200/RestAPI_V1/';
// export const DocumentUrl = 'https://knsonline.in/';

export const ApiPaths = {
    LOGIN: 'userLogin',
    GET_USER_MENU: 'getUserMenu',
    GETDASHBOARDDATA: 'getDashboardData',
    PROFITABLE_REPORTS: 'getProcessPlan',
    LIASONPROCESSOWNER: 'getLiasonProcessOwner1',
    GETPROJECTS: 'getProjects',
    GETTASKDELAY: 'getTaskDelayReason',
    GETPENDINGTASK: 'getPendingTasks',
    GETCOURTCASEPENDINGREM: 'getCourtCasePendingReminder',
    GETCOURTCASEREM: 'getCourtCaseReminder',
    PENDINGLIAISONREPORT: 'getLiaisonReport',
    PENDINGLIAISONREPORT_1: 'getLiaisonReport1',
    CIVILRESOURCEPLANNING: 'getCivilResourcePlanning',
    CIVILRESOURCETYPEPLANNING: 'getCivilTaskOveralResourcePlanning',
    GETCIVILPROJECTS: "getCivilProjectPlan",
    GETCIVILPROCESS: "getCivilProcessPlan",
    GETCIVILTASKS: "getCivilTaskPlan",
    GETCIVILRESOURCE: "getCivilProjectResource",
    GETCIVILBUDGET: "getCivilTaskBudget",
    GETCIVILPROGRESS: "getCivilProjectProgress",
    GET_MY_DASHBOARD_DATA: 'getMyDashBoardData',
    GET_MY_DASHBOARD_TASK: 'getMyDashBoardTask',
    GET_CIVIL_PROCESS_DATA: "getCivilProcessData",
    GET_ENQUIRY_SOURCE: "getEnquirySource",
    GET_MY_DASHBOARD_DATA_FILTER_USER: "getMyDashBoardDataFilterUser",
    // __________________ BD Chart 1 and Chart 2 ______________________
    // for Mou
    GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA: "getDashboardAquiredButPlanApprovalPendingZoneData",
    GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA: "getDashboardAquiredButPlanApprovalPendingProjectData",
    GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA: "getDashboardAquiredButPlanApprovalPendingFileData",
    // for Plan Approval
    GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA: "getDashboardPlanApprovalAppliedZoneData",
    GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA: "getDashboardPlanApprovalAppliedProjectData",
    GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA: "getDashboardPlanApprovalAppliedFileData",
    // for Sale Inventory
    GET_DASHBOARD_STOCK_ZONE_DATA: "getDashboardStockZoneData",
    GET_DASHBOARD_STOCK_PROJECT_DATA: "getDashboardStockProjectData",
    GET_DASHBOARD_STOCK_PROJECT_SITE_DATA: "getDashboardStockProjectSiteData",
    //  _________________ BD Chart 3 _____________
    GET_DASHBOARD_STOCK_AGING_ZONE_DATA: "getDashboardStockAgingZoneData",
    GET_DASHBOARD_STOCK_AGING_PROJECT_DATA: "getDashboardStockAgingProjectData",
    GET_DASHBOARD_STOCK_AGING_FILE_DATA: "getDashboardStockAgingFileData",
    // __________________ BD Chart 4 _____________
    GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA: "getDashboardLandAquiredZoneData",
    GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA: "getDashboardLandAquiredProjectData",
    GET_DASHBOARD_LAND_AQUIRED_FILE_DATA: "getDashboardLandAquiredFileData",
    // __________________ BD Chart 5 _____________
    GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA: "getDashboardApplanApprovalZoneData",
    GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA: "getDashboardApplanApprovalProjectData",
    GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA: "getDashboardApplanApprovalFileData",
    // __________________seperate BD Chart 6 _____________
    GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA: "getDashboardLandAquiredFinancialYearData",
    GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA: "getDashboardApplanApprovalFinancialYearData",
    GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA: "getDashboardApplanApprovedTargetActualFinancialYearData",
    GET_DASHBOARD_DATA_DEPARTMENT: "getDashboardDataDepartment",
    GET_DASHBOARD_DEPARTMENT: "getDashboardDepartment",
    GET_LIAISON_PROCESS_PLAN_REPORT: "getLiaisonProcessPlanReport",
    GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL: "getLiaisonProcessPlanReportDetail",

    // __________________CRM________________

    GET_DASHBOARD_CRM_PROJECT_OUTSTANDING: "getDashboardCrmProjectOutstanding",
    GET_DASHBOARD_CRM_SITE_OUTSTANDING: "getDashboardCrmSiteOutstanding"
};
export const ErpApi = {
    PROJECT_AUTHORITY: "/getProjectAuthority",
    BD_USER: "/getUser",
    PROJECT_PHASE: "/getProjectPhase",
    DISTRICTS: "/getDistrict",
    TALUKS: "/getTaluk",
    HOBLI: "/getHobli",
    PROJECT_BROKERS: "/getLandBroker",
    POST_PROJECT: "/createProject",
    POST_PROJECT_FILE: "/createProjectFile",
    PROJECT_LIST: "/getProject",
    UPCOMING_PROJECT_LIST: "/getProjectUpcoming",
    VILLAGE_LIST: "/getVillage",
    PROJECT_TASK_PLAN: "/getProjectTaskPlan",
    PROJECT_PLAN: "/getProjectPlan",
    PROJECT_OWNERS_NAMES: "getProjectTaskPlanFilterOwner",
    PROJECT_NAMES: "getProjectTaskPlanFilterProject",
    PROJECT_NAMES1: "getProjectTaskPlanFilterProject1",
    PROJECT_TASK_STATUS: "getProjectTaskStatus",
    UPDATE_PROJECT_TASK_REMARK: "updateProjectTaskRemark",
    UPDATE_PROJECT_TASK_STATUS: "uploadProjectTaskStatus",
    //UPDATE_PROJECT_TASK_APROVAL_STATUS: "uploadProjectTaskApprovalStatus",
    UPDATE_PROJECT_TASK_APROVAL_STATUS: "updateProjectTaskPlanApprovalStatus",
    // UPDATE_PROJECT_UPLOAD_DOCUMENT: "/uploadProjectTaskDocument",
    UPDATE_PROJECT_UPLOAD_DOCUMENTS: "/uploadProjectTaskDocuments", // For Multiple Document Upload
    UPDATE_PROJECT_TASK_COMPLETE: "/updateProjectTaskComplete",
    // UPDATE_PROJECT_SUB_TASK_COMPLETE: "/updateProjectTaskPlanComplete",
    UPDATE_PROJECT_SUB_TASK_COMPLETE_STATUS: "/updateProjectTaskPlanCompleteStatus", // with Multiple Docs
    PROJECT_DELAY_REASON: "getProjectDelayReason",
    PROJECT_DOCUMENT_TYPES: "getDocumentType",
    PROJECT_TASK_PLAN_DETAILS: "getProjectTaskPlanDetail",
    PROJECT_PROJECT_FILE: "getProjectFile",
    PROJECT_LAND_OWNER: "getLandOwner",
    //LIASON_PROCESS_PLAN: "getLiaisonProcessPlan",
    LIASON_PROCESS_PLAN: "getLiaisonProcessPlanPending",
    LIASON_TASK_PLAN: "getLiaisonTaskPlan",
    PROJECT_BD_OWNER: "getProjectBdOwner",
    PROJECT_LEGAL_OWNER: "getProjectLegalOwner",
    LIASON_DOCUMENT_TASK_PLAN: "getLiaisonDocumentationTaskPlan",
    PROJECT_SURVEY_NO_TASK_APPROVAL: "getProjectTaskPlanApproval",
    PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS: "getProjectTaskPlanApprovalDetail",
    UPDATE_LIASON_PROCESS_APPLICATION_STATUS: "updateLiaisonProcessApplicabilityStatus",
    UPDATE_LIASON_TASK_PLAN_COMPLETE: "updateLiaisonTaskPlanComplete",
    MY_WEB_NOTIFICATION: "getMyWebNotification",
    AUTHORIZATION: "getFunctionalityPermission",
    MENU_PERMISSION: "getMenu",
    FUNCTIONALITY: "getFunctionality",
    UPDATE_USER_FUNCTIONALITY_PERMISSION: "/updateUserFunctionalityPermission",
    UPDATE_FIREBASE_KEY: "updateFirebaseKeyWeb",
    PROJECT_PLAN_FILE_PLAN: "/getProjectPlanFile",
    LIAISON_PROCESS_CATEGORY: "/getLiaisonProcessCategory",
    FILE_LIAISON_PROCESS_PLAN: "/getFileLiaisonProcessPlan",
    UPDATE_LIAISON_PROCESS_PLAN_APPLICABILITY: "/updateLiaisonProcessPlanApplicabilityStatus",
    GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT: "/getProjectTaskPlanMandatoryDocument",
    UPLOAD_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT: "/uploadProjectTaskPlanMandatoryDocument",
    //New
    GET_SURVEY_NO: 'getProjectPlanFile',
    GET_SURVEY_NO_DETAILS: 'getProjectFileDetail',
    CREATE_FILE_ACQUISITION_INFO: 'createFileAcquisitionInfo',
    GET_FILE_ACQUISITION_INFO: 'getFileAcquisitionInfo',
    GET_FILE_ACQUISITION_MODE: 'getFileAcquisitionMode',
    GET_FILE_ACQUISITION_INFO_DETAIL: 'getFileAcquisitionInfoDetail',
    UPDATE_FILE_ACQUISITION_INFO_STATUS: 'updateFileAcquisitionInfoStatus',
    GET_PROJECT_WITH_EXTENT: 'getProjectWithExtent',
    GET_FEASIBILITY_ITEM: 'getProjectFeasibilityItem',
    UPDATE_FEASIBILITY_REPORT: 'updateFeasibilityReport',
    GET_FEASIBILITY_REPORT: 'getFeasibilityReport',
    GET_FEASIBILITY_REPORT_DETAIL: 'getFeasibilityReportDetail',
    CREATE_LAND_OWNER: 'createLandOwner',
    CREATE_LAND_OWNER_ACCOUNT: 'createLandOwnerAccount',
    GET_LAND_OWNER_DETAIL: 'getLandOwnerDetail',
    GET_LAND_BROKER: 'getProjectBdLandBroker',
    CREATE_LAND_BROKER: 'createLandBroker',
    GET_LAND_BROKER_DETAIL: 'getProjectBdLandBrokerDetail',
    CREATE_LAND_BROKER_ACCOUNT: 'createLandBrokerAccount',
    FEASIBILITY_REPORT_SEND_FOR_APPROVAL: 'feasibilityReportSendForApproval',
    APPROVE_FEASIBILITY_REPORT_TASK_PLAN: 'approveFeasibilityReportTaskPlan',
    GET_LAND_OWNER_VILLAGE: 'getLandOwnerVillage',
    GET_LIAISON_DOCUMENT_CATEGORY: 'getLiaisonDocumentCategory',
    UPDATE_LIAISON_DOCUMENT_CATEGORY: 'updateLiaisonDocumentCategory',
    UPDATE_LIAISON_TASK_PLAN_OWNER: 'updateLiaisonTaskPlanOwner',
    UPDATE_LIAISON_TASK_PLAN_STATUS: 'updateLiaisonTaskPlanStatus',
    UPDATE_BD_PROJECT_TASK_PLAN_OWNER: 'updateBdProjectTaskPlanOwner',
    UPDATE_LIAISON_PROCESS_PLAN_OWNER: 'updateLiaisonProcessPlanOwner',
    UPDATE_LIAISON_PROCESS_PLAN_REMARK: 'updateLiaisonProcessPlanRemark',
    CREATE_LIAISON_DOC_CHECKLIST_PLAN: 'createLiaisonDocumentCheckListPlan',
    IMPORT_LIAISON_DOC_CHECKLIST_PLAN: 'importLiaisonDocumentCheckListPlan',
    GET_PROJECT_FUND_SOURCE: 'getProjectFundSource',
    GET_PROJECT_FUND_REQUIREMENT: 'getProjectFundRequirement',
    UPDATE_PROJECT_FUND_REQUIREMENT: 'updateProjectFundRequirement',
    GET_PROJECT_FUND: 'getProjectFund',
    GET_PROJECT_FUND_DETAIL: 'getProjectFundDetail',
    GET_PROJECT_FILE_PAYMENT_DETAIL: 'getProjectFilePaymentDetail',
    ADD_LAND_OWNER_PAYMENT: 'addLandOwnerPayment',
    GET_BD_PAYMENT: 'getBdPayment',
    _GET_BD_PAYMENT_DETAIL: 'getBdPaymentDetail',
    UPDATE_BD_PAYMENT_STATUS: 'updateBdPaymentStatus',
    GET_BD_PAYMENT_MILESTONE: 'getBdPaymentMilestone',
    GET_PROJECT_BROKER: 'getProjectBroker',
    GET_PROJECT_BROKER_DETAIL: 'getProjectBrokerDetail',
    UPDATE_PROJECT_BROKER_STATUS: 'updateProjectBrokerStatus',
    ADD_PROJECT_BROKER_CHARGE: 'addProjectBrokerCharge',
    UPDATE_PROJECT_BROKER_CHARGE_STATUS: 'updateProjectBrokerChargeStatus',
    ADD_PROJECT_BROKER_PAYMENT: 'addProjectBrokerPayment',
    ADD_PROJECT_BROKER_PAYMENT_INFO: 'getProjectBrokerPaymentInfo',
    GET_PROJECT_LAND_OWNER: 'getProjectLandOwner',
    GET_PROJECT_OWNER_SURVEY_NUMBER: 'getProjectOwnerSurveyNo',
    GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL: 'getProjectFileAdvancePaymentDetail',
    GET_LIAISON_PROCESS_PLAN_FILE: 'getLiaisonProcessPlanFile',
    ADD_LIAISON_PROCESS_PLAN_START: 'liaisonProcessPlanStart',
    GET_LIAISON_PROCESS_PLAN_APPLICATION: 'getLiaisonProcessPlanApplication',
    UPDATE_LIAISON_PROCESS_PLAN_APPLICATION_OWNER: 'updateLiaisonProcessPlanApplicationOwner',
    GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL: 'getLiaisonProcessPlanApplicationFileDetail',
    ADD_LIAISON_PROCESS_PLAN_SPLIT: 'liaisonProcessPlanSplit',
    UPDATE_LIAISON_PROCESS_PLAN_APPLICATION_STATUS: 'updateLiaisonProcessPlanApplicationStatus',
    GET_PROJECTC_MILESTONE: 'getProjectMilestone',
    GET_BD_REGISTRATION_ITEM: 'getBdRegistrationItem',
    GET_SUBREGISTER_OFFICE: 'getSubRegisterOffice',
    ADD_LAND_GOVT_PAYMENT: 'addLandRegistrationPayment',
    UPDATE_SURVEY_NUMBER_CREATION: 'updateSurveyNumberCreation',
    UPLOAD_PROJECT_FILE_DOCUMENT: 'uploadProjectFileDocument',
    GET_PROJECT_FILE_DOCUMENT: 'getProjectFileDocument',
    UPDATE_PROJECT_FILE: 'updateProjectFile',
    UPDATE_PROJECT_FILE_STATUS: 'updateProjectFileStatus',
    GET_DEPENDENT_RELATIONSHIP: 'getDependentRelationship',
    UPDATE_LAND_OWNER: 'updateLandOwner',
    UPDATE_LAND_OWNER_STATUS: 'updateLandOwnerStatus',
    UPDATE_LAND_BROKER: 'updateLandBroker',
    UPDATE_LAND_BROKER_STATUS: 'updateLandBrokerStatus',
    _GET_BD_PAYMENT_ADVANCE_DETAIL: 'getBdPaymentAdvanceDetail',
    BD_LAND_PAYMENT_SETTLE: 'bdLandPaymentSettle',
    GET_ACCOUNT_PAYMENT: 'getAccountPayment',
    GET_COMPANY: 'getCompany',
    GET_PAYMENT_CATEGORY: 'getPaymentCategory',
    UPDATE_PAYMENT_COMPANY: 'updatePaymentCompany',
    GET_ACCOUNT_PAYMENT_DETAIL: 'getAccountPaymentDetail',
    GET_COMPANY_BANK_ACCOUNT: 'getCompanyBankAccount',
    GET_PAYMENT_MODE_CATEGORY: 'getPaymentModeCategory',
    GET_PAYMENT_MODE: 'getPaymentMode',
    PAY_ACCOUNT_PAYMENT: 'payAccountPayment',
    GET_ACCOUNT_PAYMENT_PAID: 'getAccountPaymentPaid',
    GET_ACCOUNT_PAYMENT_PAID_DETAIL: 'getAccountPaymentPaidDetail',
    GET_PROJECT_DETAIL: 'getProjectDetail',
    GET_PROJECT_DROP_REASON: 'getProjectDropReason',
    UPDATE_PROJECT_STATUS: 'updateProjectStatus',
    UPDATE_PROJECT_BD_OWNER: 'updateProjectBdOwner',
    UPDATE_PROJECT_LEGAL_OWNER: 'updateProjectLegalOwner',
    UPDATE_PROJECT_DETAIL: 'updateProjectDetail',
    COMPANY_PROJECT_MAP: 'getCompanyProjectMap',
    ADD_PROJECT_COMPANY_MAP: "addProjectCompanyMap",
    UPDATE_PROJECT_COMPANY_MAP: "updateProjectCompanyMap",
    GET_DROPPED_PROJECT_LIST: "/getDroppedProject",
    GET_LAND_OWNER_ACCOUNT_DETAIL: "getLandOwnerAccountDetail",
    UPDATE_LAND_OWNER_ACCOUNT_DETAIL: "updateLandOwnerAccount",
    GET_LAND_BROKER_ACCOUNT_DETAIL: "getLandBrokerAccountDetail",
    UPDATE_LAND_BROKER_ACCOUNT_DETAIL: "updateLandBrokerAccount",
    GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER: "getLiaisonProcessPlanApplicationFilterOwner",
    GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER: "getLiaisonDocumentationTaskPlanFilterOwner",
    USER_PROFILE: "getUserProfile",
    UPDATE_EMPLOYEE_PROFILE: "updateEmployeeProfile",
    UPDATE_USER_PROFILE_IMAGE: 'updateUserProfileImage',
    GET_BIRTHDAY: 'getEmployeeBirthday',
    UPDATE_USER_GREETING_IMAGE: 'updateUserGreetingImage',
    GET_WORK_ANNIVERSARY: 'getEmployeeWorkAnniversary',
    UPDATE_USER_ANNIVERSARY_GREETING_IMAGE: 'updateUserAnniversaryGreetingImage',
    GET_PROJECT_FILTER: 'getProjectFilter',
    GET_PROJECT_UPCOMING_FILTER: 'getProjectUpcomingFilter',
    GET_CIVIL_PROJECT_FILTER: 'getCivilProjectFilter',
    GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL: "getLiaisonDocumentationTaskPlanApproval",
    GET_PROJECT_FILE_FILTER: "getProjectFileFilter",
    GET_LIAISON_PROCESS_FILTER: "getLiaisonProcessFilter",
    GET_APF_PANEL: "getApfPanel",
    GET_APF_PENDING_PROJECT_FILE: "getApfPendingProjectFile",
    CREATE_APF_TASK_PLAN: "createApfTaskPlan",
    GET_APF_TASK_PLAN: "getApfTaskPlan",
    GET_APF_TASK_PLAN_DETAIL: "getApfTaskPlanDetail",
    UPDATE_APF_TASK_REMARK: "updateApfTaskRemark",
    UPDATE_APF_TASK_PLAN_OWNER: "updateApfTaskPlanOwner",
    UPLOAD_APF_TASK_DOCUMENT: "uploadApfTaskDocument",
    UPDATE_APF_TASK_PLAN_START: "updateApfTaskPlanStart",
    UPDATE_APF_TASK_PLAN_COMPLETE: "updateApfTaskPlanComplete",
    GET_APF_TASK_PLAN_PROJECT_FILTER: "getApfTaskPlanProjectFilter",
    // GET_APF_TASK_PLAN_OWNER_FILTER:"getApfTaskPlanOwnerFilter",
    GET_APF_TASK_PLAN_PANEL_FILTER: "getApfTaskPlanPanelFilter",
    GET_APF_TASK_PLAN_PROJECT_FILE_FILTER: "getApfTaskPlanProjectFileFilter",
    GET_APF_TASK_PLAN_TASK_FILTER: "getApfTaskPlanTaskFilter",
    GET_APF_TASK_FILTER: "getApfTask",
    GET_APF_TASK_PLAN_QUERY: "getApfTaskPlanQuery",
    CREATE_APF_TASK_PLAN_QUERY: "createApfTaskPlanQuery",
    UPLOAD_APF_TASK_QUERY_DOCUMENT: "uploadApfTaskQueryDocument",
    GET_APF_TASK_PLAN_QUERY_DETAIL: "getApfTaskPlanQueryDetail",
    UPDATE_APF_TASK_PLAN_QUERY_OWNER: "updateApfTaskPlanQueryOwner",
    UPDATE_APF_TASK_PLAN_QUERY_STATUS: "updateApfTaskPlanQueryStatus",
    GET_LIAISON_DOCUMENTATION_REPORT: "getLiaisonDocumentationReport",
    GET_BD_LAND_DISPUTE_TYPE: "getBdLandDisputeType",
    CREATE_BD_LAND_DISPUTE: "createBdLandDispute",
    GET_BD_LAND_DISPUTE: "getBdLandDispute",
    UPLOAD_BD_LAND_DISPUTE_DOCUMENT: "uploadBdLandDisputDocument",
    GET_BD_LAND_DISPUTE_DETAIL: "getBdLandDisputeDetail",
    UPDATE_LAND_DISPUTE_OWNER: "updateBdLandDisputOwner",
    UPDATE_LAND_DISPUTE_REMARK: "updateBdLandDisputRemark",
    UPDATE_BD_LAND_DISPUTE_STATUS: "updateBdLandDisputStatus",
    GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER: "getLiaisonDocumentationReportProjectFilter",
    GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER: "getLiaisonDocumentationReportProjectFileFilter",
    GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER: "getLiaisonDocumentationReportCategoryFilter",
    GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER: "getLiaisonDocumentationReportOwnerFilter",
    GET_COURT_CASE_TYPE: "getCourtCaseType",
    GET_COURT_CASE_STATUS: "getCourtCaseStatus",
    GET_COURT_CASE_JURISDICTION: "getCourtCaseJurisdiction",
    GET_DIRECTOR: "getDirector",
    CREATE_COURT_CASE: "createCourtCase",
    GET_COURT_CASE: "getCourtCase",
    UPLOAD_COURT_CASE_DOCUMENT: "uploadCourtCaseDocument",
    UPDATE_COURT_CASE_OWNER: "updateCourtCaseOwner",
    UPDATE_COURT_CASE_REMARK: "updateCourtCaseRemark",
    UPDATE_COURT_CASE_STATUS: "updateCourtCaseStatus",
    GET_COURT_CASE_DETAIL: "getCourtCaseDetail",
    UPDATE_COURT_CASE: "updateCourtCase",
    UPDATE_BD_PROJECT_TASK_PLAN_START: "updateBdProjectTaskPlanStart",
    CREATE_TRAINING_CONTENT: "createTrainingContent",
    GET_TRAINING_CONTENT: "getTrainingContent",
    UPDATE_TRAINING_CONTENT: "updateTrainingContent",
    DELETE_PROJECT_FILE_DOCUMENT: "deleteProjectFileDocument",
    GET_BD_PROJECT_TASK_REJECTION_REASON: "getBdProjectTaskRejectionReason",
    GET_BD_AGREEMENT_TYPE: "getBdAgreementType",
    GET_BD_PROJECT_REPORT: "getBdProjectReport",
    GET_BD_PROJECT_TASK_REPORT: "getBdProjectTaskReport",
    CREATE_PROJECT_PROPOSED_NAME: "createProjectProposedName",
    GET_PROJECT_PROPOSED_NAME: "getProjectProposedName",
    DELETE_PROJECT_PROPOSED_NAME: "deleteProjectProposedName",
    UPDATE_PROJECT_PROPOSED_NAME_STATUS: "updateProjectProposedNameStatus",
    DELETE_TRANING_CONTENT: "deleteTrainingContent",
    GET_TRAINING: "getMyTrainingContent",
    GET_LIAISON_PROCESS_PLAN_PROJECT: "getLiaisonProcessPlanProject",
    GET_LIAISON_PROCESS_PLAN_PROCESS: "getLiaisonProcessPlanProcess",
    CREATE_LIAISON_PAYMENT: "createLiaisonPayment",
    GET_LIAISON_PAYMENT_LIST: "getLiaisonPayment",
    GET_LIAISON_PAYMENT_DETAIL: "getLiaisonPaymentDetail",
    DELETE_LIAISON_PAYMENT: "deleteLiaisonPayment",
    GET_LIAISON_PAYMENT_SURVEY_NUMBER: "getLiaisonPaymentSurveyNumber",
    GET_LIAISON_PAYMENT_TASK_PLAN: "getLiaisonPaymentTaskPlan",
    ADD_LIAISON_PAYMENT_ITEM: "addLiaisonPaymentItem",
    DELETE_LIAISON_PAYMENT_ITEM: "deleteLiaisonPaymentItem",
    UPDATE_LIAISON_PAYMENT_STATUS: "updateLiaisonPaymentStatus",
    UPDATE_LIAISON_PAYMENT_ISSUE: "updateLiaisonPaymentIssue",
    UPDATE_LIAISON_PAYMENT_ITEM_ACTUAL_AMOUNT: "updateLiaisonPaymentItemActualAmount",
    UPDATE_LIAISON_PAYMENT_SETTLEMENT_APPROVAL: "updateLiaisonPaymentSettlementApproval",
    UPDATE_LIAISON_PAYMENT_SETTLEMENT_SETTLE: "updateLiaisonPaymentSettlementSettle",
    GET_FUNCTIONALITY_PERMISSION_DETAIL: "getFunctionalityPermissionDetail",
    GET_LIAISON_PAYMENT_FILTER_PROJECT: "getLiaisonPaymentFilterProject",
    GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY: "getLiaisonPaymentFilterRequestedBy",
    GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER: "getProjectTaskPlanFilterTaskOwner",
    GET_PROJECT_TASK_PLAN_FILTER_TASK: "getProjectTaskPlanFilterTask",
    UPDATE_LIAISON_TASK_PLAN_START: "updateLiaisonTaskPlanStart",
    UPDATE_LIAISON_TASK_PLAN_OWNERS: "updateLiaisonTaskPlanOwners",
    GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING: "getLiaisonDocumentationTaskPlanPending",
    GET_LIAISON_APPLICABLE_PROCESS_PLAN: "getLiaisonApplicableProcessPlan",
    GET_DEPARTMENT: "getDepartment",
    DELETE_PROJECT_DOCUMENT: "deleteProjectDocument",
    UPDATE_LIAISON_DOCUMENT_TASK_PLAN: "updateLiaisonDocumentTaskPlan",
    GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL: "getLiaisonDocumentationTaskPlanApprovalDetail",
    GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL: "getLiaisonDocumentationTaskPlanDetail",
    UPDATE_NOTIFICATION_VIEW_STATUS: "updateNotificationViewStatus",
    UPDATE_APF_TASK_PLAN_QUERY: "updateApfTaskPlanQuery",
    GET_PROJECT_TASK_PLAN_FILTER_TASK_CATEGORY: "getProjectTaskPlanFilterTaskCategory",
    GET_LIAISON_PROCESS_PLAN_APPLICATION_DETAIL_1: "getLiaisonProcessPlanApplicationDetail1",
    GET_LIAISON_TASK_PLAN_DETAIL: "getLiaisonTaskPlanDetail",
    UPDATE_LIAISON_TASK_PLAN_APPLICABILITY_STATUS: "updateLiaisonTaskPlanApplicabilityStatus",
    GET_LIAISON_PROCESS_PLAN_PENDING_DETAIL: "getLiaisonProcessPlanPendingDetail",
    GET_LIAISON_TASK: "getLiaisonTask",
    GET_LIAISON_PROCESS_PLAN_PENDING_TASK: "getLiaisonProcessPlanPendingTask",
    ADD_LIAISON_PROCESS_PLAN_TASK: "addLiaisonProcessPlanTask",
    GET_LIAISON_PROCESS_OPTIONAL: "getLiaisonProcessOptional",
    GET_LIAISON_PROCESS_SURVEY_NUMBER_OPTIONAL: "getLiaisonProcessSurveyNumberOptional",
    ADD_LIAISON_PROCESS_PLAN_EXTRA: "addLiaisonProcessPlanExtra",
    GET_LIAISON_PROCESS_PLAN: "getLiaisonProcessPlan",
    GET_LIAISON_PROCESS_PLAN_APPROVAL: "getLiaisonProcessPlanApproval",
    GET_LIAISON_PROCESS_PLAN_DETAIL: "getLiaisonProcessPlanDetail",
    GET_LIAISON_PROCESS_PLAN_FILTER_PROJECT: "getLiaisonProcessPlanFilterProject",
    GET_LIAISON_PROCESS_PLAN_FILTER_PROCESS: "getLiaisonProcessPlanFilterProcess",
    GET_LIAISON_PROCESS_PLAN_FILTER_OWNER: "getLiaisonProcessPlanFilterOwner",
    UPDATE_LIAISON_PROCESS_PLAN_STATUS: "updateLiaisonProcessPlanStatus",
    UPLOAD_NEWS_LETTER: "uploadNewsLetter",
    GET_NEWS_LETTER: "getNewsLetter",
    SEND_NEWS_LETTER_WHATSAPP: "sendNewsLetterWhatsApp",
    SEND_NEWS_LETTER_EMAIL: "sendNewsLetterEmail",
    GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_FILE: "getLiaisonProcessIdentificationPendingFile",
    GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_CATEGORY: "getLiaisonProcessIdentificationPendingCategory",
    UPDATE_LIAISON_PROCESS_APPLICABILITY_STATUS_REMOVE: "updateLiaisonProcessApplicabilityStatusRemove",
    GET_PROJECT_ZONE: "getProjectZone",
    UPDATE_LIAISON_TASK_PLAN_REJECT: "updateLiaisonTaskPlanReject",
    //GET_COURT_CASE_HANDLED_BY: "getCourtCaseHandledByFilter",
    GET_COURT_CASE_HANDLED_BY: "getCourtCaseHandledByFilter",
    GET_BD_LAND_DISPUTE_HANDLED_BY: "getBdLandDisputeHandledByFilter",
    DELETE_BD_LAND_DISPUT: "deleteBdLandDisput",
    UPDATE_COURT_CASE_ACTIVE: "updateCourtCaseActive",
    DELETE_COURT_CASE: "deleteCourtCase",
    GET_COURT_CASE_PROJECT_FILTER: "getCourtCaseProjectFilter",
    CREATE_PROJECT_DEED: "createProjectDeed",
    GET_PROJECT_DEED: "getProjectDeed",
    CREATE_EXTRA_LIAISON_PROCESS_PLAN: "createExtraLiaisonProcessPlan",
    CREATE_PROJECT_TASK_SUPPLEMENTARY_PLAN: "createProjectTaskSupplementaryPlan",
    GET_BD_PROJECT_CONSOLIDATED_REPORT: "getBdProjectConsolidatedReport",
    GET_PROJECT_FILE_ACQUISITION_STATUS_FILTER: "getProjectFileAcquisitionStatusFilter",
    GET_BD_PROJECT_CONSOLIDATED_REPORT_PRINT: "getBdProjectConsolidatedReportPrint",
    GET_BD_FILE_ACQUISITION_REASON: "getbdFileAcquisitionReason",
    GET_PROJECT_LEGAL_OWNER_FILTER: "getProjectLegalOwnerFilter",
    GET_PROJECT_BD_OWNER_FILTER: "getProjectBdOwnerFilter",
    UPDATE_LIAISON_PROCESS_PLAN_OWNERS: "updateLiaisonProcessPlanOwners",
    GET_LIAISON_PROCESS_PLAN_APPLICABILITY_REASON: "getLiaisonProcessPlanApplicabilityReason",
    GET_LOAN_PANEL: "getLoanPanel",
    GET_LOAN_APPLICATION_ELIGIBLE_PROJECT: "getLoanApplicationEligibleProject",
    GET_LOAN_APPLICATION_ELIGIBLE_PROJECT_FILE: "getLoanApplicationEligibleProjectFile",
    CREATE_LOAN_PROCESS_PLAN: "createLoanProcessPlan",
    GET_LOAN_PROCESS_PLAN: "getLoanProcessPlan",
    GET_LOAN_TASK_PLAN: "getLoanTaskPlan",
    UPDATE_LOAN_TASK_PLAN_START: "updateLoanTaskPlanStart",
    UPDATE_LOAN_PLAN_COMPLETE: "updateLoanTaskPlanComplete",
    GET_LOAN_TASK_PLAN_DETAIL: "getLoanTaskPlanDetail",
    UPDATE_PROJECT_LIAISON_OWNER: "updateProjectLaisonOwner",
    GET_PROJECT_LIAISON_OWNER_FILTER: "getProjectLiaisonOwnerFilter",
    UPDATE_LOAN_TASK_PLAN_OWNER: "updateLoanTaskPlanOwner",
    UPDATE_LOAN_PROCESS_APPLICABILITY_STATUS: "updateLoanProcessApplicabilityStatus",
    UPDATE_LOAN_TASK_PLAN_APPLICABILITY_STATUS: "updateLoanTaskPlanApplicabilityStatus",
    UPDATE_LOAN_PROCESS_PLAN_OWNER: "updateLoanProcessPlanOwner",
    GET_LOAN_PROCESS_PLAN_DETAIL: "getLoanProcessPlanDetail",
    GET_LOAN_PROCESS_PLAN_PROJECT_FILTER: "getLoanProcessPlanProjectFilter",
    GET_LOAN_PROCESS_PLAN_PROCESS_FILTER: "getLoanProcessPlanProcessFilter",
    GET_LOAN_PROCESS_PLAN_BANK_FILTER: "getLoanProcessPlanBankFilter",
    GET_LOAN_PROCESS_PLAN_OWNER_FILTER: "getLoanProcessPlanOwnerFilter",
    GET_LIAISON_PROCESS_PLAN_DELAYED: "getLiaisonProcessPlanDelayed",
    SPLIT_PROJECT_FILE: "splitProjectFile",
    PROJECT_MERGER: "projectMerger",
    GET_MASTER: "getMaster",
    // ____________Sadanand_____________________
    GET_PROJECT_FILE_ACQUISITION_PENDING: "getProjectFileAcquisitionPending",
    UPDATE_PROJECT_FILE_ACQUISTION_PENDING_REMARK: "updateProjectFileAcquisitionPendingRemark",
    UPDATE_PROJECT_FILE_ACQUISTION_OWNER: "updateProjectFileAcquisitionOwner",
    GET_PROJECT_FILE_ACQUISTION_PENDING_DETAIL: "getProjectFileAcquisitionPendingDetail",
    GET_PROJECT_FILE_ACQUISTION_HANDLED_BY_FILTER: "getProjectFileAcquisitionHandledByFilter",
    GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT: "getLiaisonPlanApprovalReport",
    GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_FILTER_PROJECT: "getLiaisonPlanApprovalReportFilterProject",
    GET_PROJECT_MOU_REPORT_KPI: "getProjectMoUReportKPI",
    GET_PROJECT_MOU_REPORT_KPI_FILTER_PROJECT: "getProjectMoUReportKPIFilterProject",
    // ---------BD Master
    GET_LIAISON_PROCESS: "getLiaisonProcess",
    GET_LIAISON_PROCESS_CATEGORY1: "getLiaisonProcessCategory1",
    CREATE_LIAISON_PROCESS: "createLiaisonProcess",
    UPDATE_LIAISON_PROCESS_OWNER: "updateLiaisonProcessOwner",
    GET_BD_PROJECT_TASK: "getBdProjectTask",
    // ADDED BY NIHTISH 
    CREATE_LAND_DISPUTE: "createLandIssueType",
    UPDATE_LAND_DISPUTE: "updateLandIssueType",
    GET_LIAISON_PROCESS_CATEGORY: "getLiaisonProcessCategoryMaster",
    CREATE_LIAISON_PROCESS_CATEGORY: "createLiaisonProcessCategory",
    UPDATE_LIAISON_PROCESS_CATEGORY: "updateLiaisonProcessCategory",
    GET_BD_PROJECT_TASK_CATEGORY: "getBdProjectTaskCategory",
    UPDATE_BD_PROJECT_TASK: "updateBdProjectTaskOwner",
    CREATE_BD_PROJECT_TASK: "createBdProjectTask",
    UPDATE_BD_PROJECT_TASK_PLANE: "updateBdProjectTask",
    CREATE_PROJECT_TASK_CATEGORY: "createBdProjectTaskCategory",
    UPDATE_PROJECT_TASK_CATEGORY: "updateBdProjectTaskCategory",
    PROJECT_TASK_DEPENDENCY: "getBdProjectTaskDependency",
    CREATE_TASK_DEPENDENCY: "createBdProjectTaskDependency",
    PROJECT_DEPENDENT_TASK: "getBdProjectDependentTask",
    UPDATE_TASK_DEPENDENCY: "updateBdProjectTaskDependency",
    GET_PLANNING_AUTHORITY: "getPlanningAuthority",
    CREATE_PLANNING_AUTHORITY: "createPlanningAuthority",
    UPDATE_PLANNING_AUTHORITY: "updatePlanningAuthority",
    GET_LIAISON_CATEGORY: "getLiaisonTaskCategory",
    CREATE_LIAISON_TASK_CATEGORY: "createLiaisonTaskCategory",
    UPDATE_LIAISON_CATEGORY: "updateLiaisonTaskCategory",
    UPDATE_LIAISON_TASK: "updateLiaisonTask",
    CREATE_LIAISON_TASK: "createLiaisonTask",
    GET_LIAISON_PROCESS_DEPENDENCY: "getLiaisonProcessDependency",
    CREATE_LIAISON_PROCESS_DEPENDENCY: "createLiaisonProcessDependency",
    UPDATE_LIAISON_PROCESS_DEPENDENCY: "updateLiaisonProcessDependency",
    GET_LIAISON_PROCESS_MASTER: "getLiaisonProcess",
    GET_LIAISON_PROCESS_CATEGORY_MASTER_NEW: "getLiaisonProcessCategory1",
    GET_LIAISON_PROCESS_DETAIL: "getLiaisonProcessDetail",
    CREATE_LIAISON_PROCESS_MASTER: "createLiaisonProcess",
    UPDATE_LIAISON_PROCESS_MASTER: "updateLiaisonProcess",
    GET_LIAISON_PROCESS_PLAN_FILTER_SY_NUMBER: "getLiaisonProcessPlanFilterSyNumber",
    GET_LIAISON_DOC_TASK_PLAN_FILTER_ACCOUNTABLE: "getLiaisonDocumentationTaskPlanFilterAccountable",
    UPDATE_LIAISON_DOCUMENTATION_TASK_PLAN_REJECT: "updateLiaisonDocumentationTaskPlanReject",
    // HR Modules
    ADD_TASK: "createTask",
    GET_TASK: "getTask",
    GET_TASK_CATEGORY: "getTaskCategory",
    UPDATE_TASK_OWNER: "updateTaskOwner",
    UPDATE_TASK_START: "updateTaskStart",
    UPDATE_TASK_COMPLETED: "updateTaskComplete",
    GET_TASK_DETAIL: "getTaskDetail",
    GET_TASK_PROJECT_FILTER: "getTaskProjectFilter",
    GET_TASK_CATEGORY_FILTER: "getTaskCategoryFilter",
    GET_TASK_DEPARTMENT_FILTER: "getTaskDepartmentFilter",
    GET_TASK_OWNER_FILTER: "getTaskOwnerFilter",
    // CIVIL MODULES
    GET_CIVIL_PROJECT: "getCivilProject",
    GET_CIVIL_PROJECT_PLANNIG_STAGE: "getCivilProjectPlanningStage",
    CREATE_CIVIL_PROJECT_PLAN: "createCivilProjectPlan",
    GET_CIVIL_PROJECT_TEAM_ROLE: "getCivilProjectTeamRole",
    GET_CIVIL_PROJECT_PLAN_LIST: "getCivilProjectPlanList",
    CREATE_CIVIL_PROJECT_TEAM: "createCivilProjectTeam",
    CREATE_CIVIL_PROJECT_LOCATION: "createCivilProjectLocation",
    GET_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE: "getLiaisonProcessPlanFilterAccountable",
    UPDATE_LIASON_PROCESS_DOCUMENT_TYPE: "updateLiaisonDocumentType",
    UPDATE_PROJECT_TASK_APPLICABLE_STATUS: "updateProjectTaskPlanApplicableStatus",
    GET_CIVIL_PROJECT_PLAN_TEAM: "getCivilProjectPlanTeam",
    GET_CIVIL_PROJECT_SITE_LOCATION: "getCivilProjectSiteLocation",
    GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT: "getCivilProjectPlanFilterProject",
    GET_CIVIL_WBS: "getCivilWbs",
    CREATE_CIVIL_PROJECT_PROCESS_PLAN: "createCivilProjectProcessPlan",
    GET_CIVIL_PROJECT_PROCESS_PLAN: "getCivilProjectProcessPlan",
    GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS: "getCivilProjectProcessPlanFilterProcess",
    GET_CIVIL_PROJECT_PROCESS_TASK: "getCivilActivity",
    GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK: "getCivilProjectProcessTaskPlanFilterTask",
    GET_TASK_DEPENDENCY_TYPE: "getTaskDependencyType",
    CREATE_CIVIL_PROJECT_PROCESS_TASK_PLAN: "createCivilProjectTaskPlan",
    GET_CIVIL_PROJECT_PLAN_FILTER_STAGE: "getCivilProjectPlanFilterStage",
    GET_CIVIL_PROJECT_PROCESS_TASK_PLAN: "getCivilProjectProcessTaskPlan",
    GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY: "getCivilProjectTaskPlanLocationQuantity",
    CREATE_CIVIL_PROJECT_TASK_PLAN_QUANTITY: "createCivilProjectTaskPlanQuantity",
    GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY: "getCivilMaterialCategory",
    GET_CIVIL_MATERIAL: "getCivilMaterial",
    CREATE_CIVIL_PROJECT_PLAN_MATERIAL: "createCivilProjectTaskPlanMaterial",
    GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL: "getCivilProjectTaskPlanMaterial",
    GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_DELETE: "getCivilProjectTaskPlanMaterialDelete",
    GET_CIVIL_PROJECT_ACTIVITY_MASTER: "getCivilProjectActivityMaster",
    GET_CIVIL_PROJECT_ACTIVITY: "getCivilProjectActivity",
    UPDATE_CIVIL_PROJECT_PLAN: "updateCivilProjectPlan",
    GET_CIVIL_PROJECT_WBS: "getCivilProjectWbs",
    GET_CIVIL_PROJECT_RESOURCE_1: "getCivilProjectResource",
    CREATE_CIVIL_PROJECT_TASK_PLAN_RESOURCE: "createCivilProjectTaskPlanResource",
    GET_CIVIL_RESOURCE_ALL: "getCivilResourceAll",
    GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE: "getCivilProjectTaskPlanResource",
    UPDATE_CIVIL_PROJECT_TASK_PLAN_DURATION: "updateCivilProjectTaskPlanDuration",
    GET_CIVIL_PROJECT_ACTIVITY_DETAIL: "getCivilProjectActivityDetail",
    CREATE_CIVIL_PROJECT_TASK_PLAN_DEPENDENCY: "createCivilProjectTaskPlanDependancy",
    GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY: "getCivilProjectDependableActivity",
    // sadanand
    GET_CIVIL_PROJECT_ROLE: "getCivilProjectRole",
    CREATE_CIVIL_PROJECT_ROLE: "createCivilProjectRole",
    UPDATE_CIVIL_PROJECT_ROLE: "updateCivilProjectRole",
    // Nikhil
    GET_BD_PAYMENT_MILESTONE_MASTER: "getBdPaymentMilestoneList",
    CREATE_BD_PAYMENT_MILESTONE: "createBdPaymentMilestoneList",
    UPDATE_BD_PAYMENT_MILESTONE: "updateBdPaymentMilestoneList",
    GET_PROJECT_TASK_REJECTION_REASON_MASTER: "getBdProjectTaskRejectionReason",
    CREATE_PROJECT_TASK_REJECTION_REASON_MASTER: "createBdProjectTaskRejectionReason",
    UPDATE_PROJECT_TASK_REJECTION_REASON_MASTER: "updateBdProjectTaskRejectionReason",
    GET_AGREEMENT_TYPE_MASTER: "getBdAgreementType",
    CREATE_AGREEMENT_TYPE_MASTER: "createBdAgreementType",
    UPDATE_AGREEMENT_TYPE_MASTER: "updateBdAgreementType",
    GET_COURT_ESTABLISHMENT: "getCourtEstablishment",
    CREATE_COURT_ESTABLISHMENT: "createCourtEstablishment",
    UPDATE_COURT_ESTABLISHMENT: "updateCourtEstablishment",
    GET_COURT_CASE_STATUS_MASTER: "getCourtCaseStatusMaster",
    CREATE_COURT_CASE_STATUS_MASTER: "createCourtCaseStatus",
    UPDATE_COURT_CASE_STATUS_MASTER: "updateCourtCaseStatusMaster",
    GET_COURT_CASE_TYPE_MASTER: "getCourtCaseTypeMaster",
    CREATE_COURT_CASE_TYPE: "createCourtCaseType",
    UPDATE_COURT_CASE_TYPE: "updateCourtCaseType",
    GET_REGISTRATION_FEES_RATE: "getBdRegistrationFeesRate",
    CREATE_REGISTRATION_FEES_RATE: "createBdRegistrationFeesRate",
    UPDATE_REGISTRATION_FEES_RATE: "updateBdRegistrationFeesRate",
    GET_REGISTRATION_FEES: "getBdRegistrationFees",
    CREATE_REGISTRATION_FEES: "createBdRegistrationFees",
    UPDATE_REGISTRATION_FEES: "updateBdRegistrationFees",
    GET_LIASION_PROCESSES_EXCEPTION: "getLiaisionProcessException",
    CREATE_LIASION_PROCESSES_EXCEPTION: "createLiasionProcessesException",
    UPDATE_LIASION_PROCESSES_EXCEPTION: "updateLiasionProcessesException",
    GET_APF_TASK_LIST: "getApfTaskList",
    CREATE_APF_TASK: "createApfTaskList",
    UPDATE_APF_TASK: "updateApfTaskList",
    GET_APF_PANEL_LIST: "getApfPanelList",
    CREATE_APF_PANEL_LIST: "createApfPanelList",
    UPDATE_APF_PANEL_LIST: "updateApfPanelList",
    GET_PROJECT_DOCUMENT_TYPE: "getProjectDocumentType",
    CREATE_PROJECT_DOCUMENT_TYPE: "createBdProjectDocumentType",
    UPDATE_PROJECT_DOCUMENT_TYPE: "updateBdProjectDocumentType",
    // GET_LIASISON_DELAY_REASON:"getliasionDelayReason",
    // CREATE_LIASISON_DELAY_REASON:"createliasionDelayReason",
    // UPDATE_LIASISON_DELAY_REASON: "updateliasionDelayReason",
    GET_LIASISON_DELAY_REASON: "getLiaisonDelayReason",
    CREATE_LIASISON_DELAY_REASON: "createLiaisonDelayReason",
    UPDATE_LIASISON_DELAY_REASON: "updateLiaisonDelayReason",
    GET_BANK: "getBank",
    CREATE_BANK: "createBank",
    UPDATE_BANK: "updateBank",
    GET_BANK_CATEGORY: "getBankCategory",
    GET_LOAN_TASK: "getLoanTask",
    GET_LOAN_PROCESS: "getLoanProcess",
    CREATE_LOAN_TASK: "createLoanTask",
    UPDATE_LOAN_TASK: "updateLoanTask",
    GET_LOAN_PANEL_MASTER: "getLoanPanelMaster",
    CREATE_LOAN_PANEL: "createLoanPanel",
    UPDATE_LOAN_PANEL: "updateLoanPanel",
    CREATE_LOAN_PROCESS: "createLoanProcess",
    UPDATE_LOAN_PROCESS: "updateLoanProcess",
    GET_CIVIL_PROJECT_TASK_UOM: "getCivilProjectTaskUOM",
    CREATE_CIVIL_PROJECT_TASK_UOM: "createCivilProjectTaskUOM",
    UPDATE_CIVIL_PROJECT_TASK_UOM: "updateCivilProjectTaskUOM",
    GET_PROJECT_TASK_TRANSACTION: "getBdProjectTaskTransaction",
    CREATE_PROJECT_TASK_TRANSACTION: "createBdProjectTaskTransaction",
    UPDATE_PROJECT_TASK_TRANSACTION: "updateBdProjectTaskTransaction",
    CREATE_PROJECT_DROP_REASON: "createProjectDropReason",
    UPDATE_PROJECT_DROP_REASON: "updateProjectDropReason",
    GET_BD_TASK_APPROVAL: "getBdTaskApproval",
    CREATE_BD_TASK_APPROVAL: "createBDTaskApproval",
    UPDATE_BD_TASK_APPROVAL: "updateBDTaskApproval",
    GET_SUB_REGISTRAR_OFFICE: "getSubRegisterOfficeMaster",
    CREATE_SUB_REGISTRAR_OFFICE: "createSubRegisterOffice",
    UPDATE_SUB_REGISTRAR_OFFICE: "updateSubRegisterOffice",
    GET_SUB_REGISTRAR_MAPPING: "getSubRegistrarMapping",
    CREATE_SUB_REGISTRAR_MAPPING: "createSubRegistrarMapping",
    UPDATE_SUB_REGISTRAR_MAPPING: "updateSubRegistrarMapping",
    GET_CIVIL_PROJECT_TASK: "getCivilTask",
    CREATE_CIVIL_PROJECT_TASK: "createCivilTask",
    UPDATE_CIVIL_PROJECT_TASK: "updateCivilTask",
    GET_CIVIL_PROCESS_M: "getCivilProcess",
    CREATE_CIVIL_PROJECT_PROCESS: "createCivilProcess",
    UPDATE_CIVIL_PROJECT_PROCESS: "updateCivilProcess",
    GET_CIVIL_RESOURCE: "getCivilResource",
    GET_CIVIL_RESOURCE_CATEGORY: "getCivilResourceCategory",
    CREATE_CIVIL_RESOURCE: "createCivilResource",
    UPDATE_CIVIL_RESOURCE: "updateCivilResource",
    GET_LIAISON_PROCESS_APPLICABILITY_REASON: "getLiaisonProcessApplicabilityReason",
    CREATE_LIAISON_PROCESS_APPLICABILITY_REASON: "createLiaisonProcessApplicabilityReason",
    UPDATE_LIAISON_PROCESS_APPLICABILITY_REASON: "updateLiaisonProcessApplicabilityReason",
    CREATE_CIVIL_PROJECT_RESOURCE: "createCivilProjectResource",
    UPDATE_CIVIL_PROJECT_RESOURCE: "updateCivilProjectResource",
    GET_CIVIL_OBJECT_PRODUCTIVITY: "getCivilProjectResourceProductivity",
    CREATE_LANLORD_MEETING: "createLandLordMeeting",
    GET_LANDLORD_MEETING: "getLandLordMeeting",
    GET_LANDLORD_MEETING_STAGE: "getLandLordMeetingStage",
    GET_LANDLORD_MEETING_PARTICULAR: "getLandLordMeetingParticular",
    // CREATE_CIVIL_OBJECT_PRODUCTIVITY: "createCivilProjectResourceProductivity",
    CREATE_CIVIL_RESOURCE_PRODUCTIVITY: "createCivilProjectResourceProductivity",
    COPY_CIVIL_PROJECT_RESOURCE: "copyCivilProjectResource",
    COPY_CIVIL_PROJECT_PRODUCTIVITY: "copyCivilResourceProductivity",
    UPDATE_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY: "updateCivilProjectResourceProductivity",
    GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY: "getCivilProjectResourceFilterResourceCategory",
    GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE: "getCivilProjectResourceFilterResource",
    GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY: "getCivilProjectResourceProductivityFilterResourceCategory",
    GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE: "getCivilProjectResourceProductivityFilterResource",
    IMPORT_CIVIL_PROJECT_RESOURCE: "importCivilProjectResource",
    IMPORT_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY: "importCivilProjectResourceProductivity",
    GET_USER_DESIGNATION: "getUserDesignation",
    GET_USER_ROLE_FUNCTIONALITY: "getUserRoleFunctionality",
    UPDATE_USER_ROLE_FUNCTIONALITY: "updateUserRoleFunctionality",
    CREATE_USER_ROLE_FUNCTIONALITY: "createUserRoleFunctionality",
    GET_PUBLIC_HOLIDAY_LIST: "getPublicHoliday",
    CREATE_PUBLIC_HOLIDAY_LIST: "createPublicHoliday",
    UPDATE_PUBLIC_HOLIDAY_LIST: "updatePublicHoliday",
    GET_NEWS_LETTER_CATEGORY: "getNewsLetterCategory",
    GET_APF_TASK_PLAN_REPORT: "getApfTaskPlanReport",
    CREATE_PROJECT_ZONE: "createProjectZone",
    GET_YEAR: "getYear",
    //dec13 
    ADD_LAND_LORD_MEETING_PARTICULAR_REMARK: "addLandLordMeetingParticularRemark",
    GET_LAND_LORD_MEETING_PARTICULAR_REMARK: "getLandLordMeetingParticularRemark",
    GET_LAND_LORD_MEETING_ATTENDEE: "getLandLordMeetingAttendee",
    GET_LAND_LORD_MEETING_PARTICULAR: "getLandLordMeetingParticular",
    CREATE_LAND_LORD_MEETING_PARTICULAR: "createLandLordMeetingParticular",
    UPDATE_LAND_LORD_MEETING_PARTICULAR: "updateLandLordMeetingParticular",
    GET_LANDLORD_MEETING_DETAIL: "getLandLordMeetingDetail",
    UPDATE_LANDLORD_MEETING: "updateLandLordMeeting",
    GET_LAND_LORD_MEETING_STAGE_MASTER: "getLandLordMeetingStage",
    CREATE_LAND_LORD_MEETING_STAGE: "createLandLordMeetingStage",
    UPDATE_LAND_LORD_MEETING_STAGE: "updateLandLordMeetingStage",
    ADD_LAND_LORD_MEETING_ATTENDEE: "addLandLordMeetingAttendee",
    ADD_LAND_LORD_MEETING_MOM: "addLandLordMeetingMom",
    GET_LAND_LORD_MEETING_MOM: "getLandLordMeetingMom",
    GET_LIAISON_PROCESS_DEPENDENT: "getLiaisonProcessDependent",
    UPDATE_LAND_LORD_MEETING_STATUS: "updateLandLordMeetingStatus",
    CREATE_SALARY_ADVANCE: "createSalaryAdvance",
    GET_SALARY_ADVANCE_DEDUCTION_HISTORY: "getSalaryAdvanceDeductionHistory",
    GET_SALARY_ADVANCE: "getSalaryAdvance",
    GET_OUT_PASS: "getOutPass",
    CREATE_OUT_PASS: "createOutPass",
    UPDATE_OUT_PASS_APPROVAL: "updateOutPassStatus",
    GET_EMPLOYEE: "getEmployee",
    GET_EMPLOYEE_ATTENDANCE: "getEmployeeAttendance",
    GET_LEAVE_TYPE: "getLeaveType",
    GET_EMPLOYEE_CHECKIN: "getEmployeeCheckIn",
    UPDATE_SALARY_ADVANCE_STATUS: "updateSalaryAdvanceStatus",
    GET_PETTY_CASH: "getPettyCash",
    GET_LEAVE_SUMMARY: "getLeaveSummary",
    GET_COMP_OFF_LEAVE_SUMMARY: "getCompOffLeaveSummary",
    // GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE:"getCivilProjectTaskPlanResource",
    GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT: "getCivilProjectTaskPlanQuantityExport",
    GET_BD_PROJECT_DEED_TYPE: "getBdProjectDeedType",
    UPDATE_PROJECT_FILE_MOU_DATE: "updateProjectFileMouDate",
    ADD_LIAISON_PROCESS_PLAN_MERGER: "liaisonProcessPlanMerger",
    GET_LIAISON_PROCESS_PLAN_MERGER_FILE: "getLiaisonProcessPlanMergerFile",
    GET_VEHICLE_SERVICE_REQUEST: "getVehicleServiceRequest",
    IMPORT_PROJECT_FILE_MOU_DATE: "importProjectFileMouDate",
    GET_VECHICLE_TYPE: "getVehicleType",
    GET_SERVICE_TYPE: "getServiceType",
    IMPORT_CIVIL_PROJECT_TASK_PLAN_QUANTITY: "importCivilProjectTaskPlanQuantity",
    GET_CIVIL_PROJECT_TASK_MATERIAL_EXPORT: "getCivilProjectTaskPlanMaterialExport",
    GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT: "getCivilProjectTaskPlanResourceExport",
    // IMPORT_CIVIL_PROJECT_TASK_PLAN_MATERIAL:"importCivilProjectTaskPlanQuantity",
    IMPORT_CIVIL_PROJECT_TASK_PLAN_RESOURCE: "importCivilProjectTaskPlanResource",
    UPDATE_CIVIL_PROJECT_TASK_PLAN_SCHEDULE: "updateCivilProjectTaskPlanSchedule",
    GET_BD_LAND_DISPUTE_PRIORITY: "getBdLandDisputePriority",
    GET_APF_TASK_PLAN_QUERY_TEMPLETE: "getApfTaskPlanQueryTemplete",
    IMPORT_APF_TASK_PLAN_QUERY: "importApfTaskPlanQuery",
    UPDATE_BD_LAND_DISPUTE: "updateBdLandDispute",
    GET_CIVIL_PROJECT_BUDGET: "getCivilProjectBudget",
    CIVIL_PROJECT_ACTIVITY_DELETE: "civilProjectActivityDelete",
    CIVIL_PROJECT_WBS_DELETE: "civilProjectWbsDelete",
    GET_CIVIL_PROJECT_WBS_DETAIL: "getCivilProjectWbsDetail",
    UPDATE_CIVIL_PROJECT_PROCESS_PLAN: "updateCivilProjectProcessPlan",
    GET_CIVIL_PROJECT_PLAN_PENDING: "getCivilProjectPlanPending",

    CREATE_LIAISON_PROCESS_PLAN_EXCEPTION: "createLiaisonProcessPlanException",
    GET_LIAISON_PROCESS_PLAN_EXCEPTION: "getLiaisonProcessPlanException",
    UPDATE_LIAISON_PROCESS_PLAN_EXCEPTION: "updateLiaisonProcessPlanException",

    GET_PROJECT_TASK_PLAN_APPROVAL_FILTER_APPROVAL_BY: "getProjectTaskPlanApprovalFilterApprovalBy",
    GET_LIAISON_PROCESS_PLAN_APPROVAL_FILTER_APPROVER_BY: "getLiaisonProcessPlanApprovalFilterApprover",
    GET_LIAISON_DOCUMENTATION_TASK_PLAN_APPROAVAL_FILTER_APPROVER: "getLiaisonDocumentationTaskPlanApprovalFilterApprover",
    UPDATE_PROJECT_PHASE: "updateProjectPhase",
    GET_LANDLORD_MEETING_DETAIL_PRINT: "getLandLordMeetingDetail",
    RESCHEDULE_LANDLORD_MEETING: "rescheduleLandLordMeeting",
    GET_APF_TASK_PLAN_FILE: "getApfTaskPlanFile",
    GET_APF_TASK_PLAN_QUERY_FILTER_FILE: "getApfTaskPlanQueryFilterFile",
    GET_LIAISON_PROCESS_AUTHORITY_MAP: "getLiaisonProcessAuthorityMap",
    UPDATE_LIAISON_PROCESS_PLANNING_AUTHORITY: "updateLiaisonProcessPlanningAuthority",
    GET_APF_TASK_PLAN_QUERY_FILTER_RESPONSIBLE: "getApfTaskPlanQueryFilterResponsible",
    GET_APF_TASK_PLAN_QUERY_FILTER_ACCOUNTABLE: "getApfTaskPlanQueryFilterAccountable",
    GET_APF_TASK_PLAN_ACCOUNTABLE_FILTER: "getApfTaskPlanAccountableFilter",
    GET_APF_TASK_PLAN_OWNER_FILTER: "getApfTaskPlanOwnerFilter",
    UPDATE_PROJECT_ZONE: "updateProjectZone",
    UPDATE_LIAISON_PROCESS_PLAN_DEPENDENCY_EXCEPTION: "updateLiaisonProcessPlanException",
    UPDATE_CIVIL_PROJECT_TASK_PLAN_DEPENDENCY: "updateCivilProjectTaskPlanDependancy",
    DELETE_CIVIL_PROJECT_TASK_PLAN_DEPENDENCY: "deleteCivilProjectTaskPlanDependancy",
    GET_CIVIL_PROJECT_SCHEDULE: "getCivilProjectSchedule",

    GET_DESIGNATION: "getDesignation",
    GET_DEPARTMENT_MASTER: "getDepartmentMaster",
    CREATE_DESIGNATION: "createDesignation",
    UPDATE_DESIGNATION: "updateDesignation",
    CIVIL_PROJECT_WBS_DELETE_BULK: "civilProjectWbsDeleteBulk",
    CIVIL_PROJECT_ACTIVITY_DELETE_BULK: "civilProjectActivityDeleteBulk",
    GET_CIVIL_RESOURCE_FOR_ACTIVITY: "getCivilResourceForActivity",
    GET_CIVIL_MATERIAL_FOR_ACTIVITY: "getCivilMaterialForActivity",

    //Education
    GET_COURSE_SUBJECT: "getCourseSubject",
    CREATE_COURSE_SUBJECT: "createCourseSubject",
    UPDATE_COURSE_SUBJECT: "updateCourseSubject",
    IMPORT_COURSE_SUBJECT: "importCourseSubject",

    CREATE_DEPARTMENT: "createDepartment",
    GET_COURSE_DEPARTMENT: "getDepartment",
    UPDATE_DEPARTMENT: "updateDepartment",

    CREATE_COURSE_CATEGORY: "createCourseCategory",
    GET_COURSE_CATEGORY: "getCourseCategory",
    UPDATE_COURSE_CATEGORY: "updateCourseCategory",

    CREATE_COURSE_LEVEL: "createCourseLevel",
    GET_COURSE_LEVEL: "getCourseLevel",
    UPDATE_COURSE_LEVEL: "updateCourseLevel",

    CREATE_COURSE_EXAM_TYPE: "createCourseExamType",
    GET_COURSE_EXAM_TYPE: "getCourseExamType",
    UPDATE_COURSE_EXAM_TYPE: "updateCourseExamType",


    // GET_COURSE_DEPARTMENT:"getDepartment",
    // GET_COURSE_CATEGORY:"getCourseCategory",
    // GET_COURSE_LEVEL:"getCourseLevel",
    // GET_COURSE_EXAM_TYPE:"getCourseExamType",
    CREATE_COURSE: "createCourse",
    GET_COURSE: "getCourse",
    GET_STUDENT: "getStudent",
    CREATE_STUDENT: "createStudent",
    GET_STUDENT_DETAIL: "getStudentDetail",
    UPDATE_STUDENT: "updateStudent",
    UPDATE_STUDENT_PROFILE_IMAGE: "updateStudentProfileImage",

    IMPORT_EXAM_RESULT: "importExamResult",
    GET_EXAM_RESULT:"getExamResult",
    GET_RESULT_GRADE:"getResultGrade",
    CREATE_RESULT_GRADE:"createResultGrade",
    UPDATE_RESULT_GRADE:"updateResultGrade",

    IMPORT_STUDENT:"importStudent",
    UPLOAD_STUDENT_PROFILE_IMAGE:"uploadStudentProfileImage",
    UPDATE_COURSE:"updateCourse",

    GET_EXAM_RESULT_FILTER_SESSION:"getExamResultFilterSession",
    GET_EXAM_RESULT_FILTER_DEPARTMENT:"getExamResultFilterDepartment",
    GET_EXAM_RESULT_FILTER_COURSE:"getExamResultFilterCourse",
    GET_EXAM_RESULT_FILTER_SEMISTER:"getExamResultFilterSemister",

    GET_DYNAMIC_FORM_DATA:"getDynamicFormData",
    GET_SENSOR_DATA:"getSensorData",

}
export const ApiService = axios.create({
    baseURL: BaseUrl + 'v2/',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
    }
});
export const ErpApiService = axios.create({
    baseURL: BaseUrl + 'v2/',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
    }
});
export const FileUploadService = axios.create({
    baseURL: BaseUrl + 'v2/',
    headers: {
        // 'Accept' : 'application/json',
        // 'Content-Type' : 'application/x-www-form-urlencoded',
    }
});
export const SetAuthToken = (token) => {
    if (token) {
        ApiService.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
    else {
        delete ApiService.defaults.headers.common['Authorization'];
    }
}
export const ApiStatus = {
    OTP_SENT: 'OTP sent',
}
