import {ApiPaths, ApiService, SetAuthToken, ApiStatus} from '../../utils/ApiService';
import {MAIN_LOAD_TRUE, MAIN_LOAD_FALSE, SET_AUTH_USER,
     OTP_SENT_SUCCESS, OTP_VALID_FAILS, LOGIN_SUCCESS, DISPLAY_MESSAGE, 
     DISPLAY_ERROR,OTP_VALID_SUCCESS,GET_USER_MENU,
     DASHBOARD_LOAD_TRUE,DASHBOARD_LOAD_FALSE
    
    } from './types';
import { toast } from 'react-toastify';
import { fetchToken } from '../../Pages/firebase';
import {getItem, setItem} from '../../utils/crypto';


const userLocal = getItem('enuseloc');
const LoginuserId = userLocal && Object.keys(userLocal).length > 0 ? userLocal['user_id'] : null;


export const userLogin_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.LOGIN, data).then(response => {
                localStorage.clear()
                const {data}  = response;
                console.log("data1111111",data);
                if(data.user?.user_id){
                    fetchToken(data.user?.user_id)
                }
                dispatch(mainLoadingFalse());
                if(!data.error){
                    console.log("data.user?.user_id",data.menu)
                    localStorage.setItem("user", JSON.stringify(data.user))
                    // localStorage.setItem("menu", JSON.stringify(data.menu?.data))
                    setItem(data.user, "enuseloc")
                    window.location.reload();
                    dispatch(displayMessage({
                        text : data.message,
                        type : "success"
                    }));
                }
                else{
                    console.log("error", data.error)
                    dispatch(displayMessage({
                        text : data.message,
                        type : "error"
                    }));
                    localStorage.clear()
                    }
            })
            .catch(err => {
                console.log("err", err)
                dispatch(mainLoadingFalse());
                dispatch(displayMessage({
                    text : "Failed, try again later",
                    type : "error"
                }));
                localStorage.clear()
            })
    }
}

export const otpVerify_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        localStorage.clear()
        dispatch(otpSentfails());
        return ApiService.post(ApiPaths.OTPVERIFY, data).then(response => {
                const {data}  = response;
                dispatch(mainLoadingFalse());
                if(!data.error)
                {
                    dispatch(displayMessage());
                    toast.success(data.message);
                    localStorage.setItem('user', JSON.stringify(data.user));
                    dispatch(otpValidSuccess());
                    // setTimeout(function(){ dispatch(displayMessage(""))}, 3000);
                }
                else{
                    toast.error(data.message);
                    dispatch(otpSentfails());
                    // dispatch(displayError(data.message));
                    // setTimeout(function(){ dispatch(displayError("")); }, 3000);
                }
            })
            .catch(err => {
                dispatch(otpSentfails());
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const mainLoadingTrue = () => {
    return {
        type : MAIN_LOAD_TRUE
    }
}
export const mainLoadingFalse = () => {
    return {
        type : MAIN_LOAD_FALSE
    }
}

export const dashBoardLoadingTrue = () => {
    return {
        type : DASHBOARD_LOAD_TRUE,
        payload : {
            value: true
        }
    }
}
export const dashBoardLoadingFalse = () => {
    return {
        type : DASHBOARD_LOAD_FALSE,
        payload : {
            value: false
        }
    }
}

export const dashBoardLoadingReset = () => {
    return {
        type : DASHBOARD_LOAD_FALSE,
        payload : {
            value: null
        }
    }
}

export const displayMessage = (data) => {
    return {
        type : DISPLAY_MESSAGE,
        payload : {
            text : data.text,
            type : data.type
        }
    }
}

export const otpSentSuccess = () => {
    return {
        type : OTP_SENT_SUCCESS
    }
}

export const otpSentfails = () => {
    return {
        type : OTP_VALID_FAILS
    }
}



export const otpValidSuccess = () => {
    return {
        type : OTP_VALID_SUCCESS
    }
}


export const setAuthUser = (auth_user) => {
    return {
        type : SET_AUTH_USER,
        payload : {
            auth_user : auth_user
        }
    }
}

export const displayError = (error) => {
    return {
        type : DISPLAY_ERROR,
        payload : { 
            error : error
        }
    }
}

export const getUserMenuItem_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        ...data,
    };


    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_USER_MENU, initialPayload)
            .then((response) => {
                const { data } = response;
                // console.log(data, "data");
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getUserMenuItemSuccess(data?.data));
                } else {
                    dispatch(getUserMenuItemSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getUserMenuItemSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};
export const getUserMenuItemSuccess = (data) => {
    return {
        type: GET_USER_MENU,
        payload: data,
    };
};