import { ApiPaths, ApiService, SetAuthToken, ApiStatus, ErpApiService, ErpApi, FileUploadService } from '../../utils/ApiService';
import {
    GET_COURSE_SUBJECT,
    GET_COURSE_DEPARTMENT,
    GET_COURSE_CATEGORY,
    GET_COURSE_LEVEL,
    GET_COURSE_EXAM_TYPE,
    GET_COURSE,
    GET_STUDENT,
    GET_STUDENT_DETAIL,
    GET_APF_TASK_PLAN_QUERY_TEMPLETE,
    GET_EXAM_RESULT,GET_RESULT_GRADE,
    GET_EXAM_RESULT_FILTER_SESSION,
    GET_EXAM_RESULT_FILTER_DEPARTMENT,
    GET_EXAM_RESULT_FILTER_COURSE,
    GET_EXAM_RESULT_FILTER_SEMISTER,
    GET_DYNAMIC_FORM_DATA,
    GET_SENSOR_DATA
} from './types';
import { mainLoadingTrue, mainLoadingFalse, displayMessage } from '../User/action'

import { getItem, setItem } from '../../utils/crypto';


const userLocal = getItem('enuseloc');
const LoginuserId = userLocal && Object.keys(userLocal).length > 0 ? userLocal['id'] : null;

export const getCourseSubject_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "departmentID": "",
        "statusID": "",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COURSE_SUBJECT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getCourseSubjectSuccess(data))
            }
            else {
                dispatch(getCourseSubjectSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCourseSubjectSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCourseSubjectSuccess = (data) => {
    return {
        type: GET_COURSE_SUBJECT,
        payload: data
    }
}


export const createCourseSubject_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID": "",
        "remark": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_COURSE_SUBJECT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const updateCourseSubject_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_COURSE_SUBJECT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}


export const importCourseSubject_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('documentFile', data.documentFile);
    // console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.IMPORT_COURSE_SUBJECT, initialPayload, config).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}


// export const getDepartment_action = (data) => {

//     const initialPayload = {
//         "userID": LoginuserId,
//         "statusID": "",
//         "generalSearch": "",
//         "sortOrder": "",
//         "iDisplayStart": 0,
//         "iDisplayLength": -1,
//         ...data
//     }

//     return dispatch => {
//         dispatch(mainLoadingTrue());
//         return ErpApiService.post(ErpApi.GET_COURSE_DEPARTMENT, initialPayload).then(response => {
//             const { data } = response;
//             dispatch(mainLoadingFalse());

//             if (!data.error) {
//                 dispatch(getDepartmentSuccess(data))
//             }
//             else {
//                 dispatch(getDepartmentSuccess([]))
//             }
//         })
//             .catch(err => {
//                 console.log("err", err)
//                 dispatch(getDepartmentSuccess([]))
//                 dispatch(mainLoadingFalse());
//             })
//     }
// }

// export const getDepartmentSuccess = (data) => {
//     return {
//         type: GET_COURSE_DEPARTMENT,
//         payload: data
//     }
// }


export const createDepartment_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID": "",
        "remark": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_DEPARTMENT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const updateDepartment_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_DEPARTMENT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCourseLevel_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "statusID": "",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COURSE_LEVEL, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getCourseLevelSuccess(data))
            }
            else {
                dispatch(getCourseLevelSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCourseLevelSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCourseLevelSuccess = (data) => {
    return {
        type: GET_COURSE_LEVEL,
        payload: data
    }
}


export const createCourseCategory_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_COURSE_CATEGORY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const updateCourseCategory_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_COURSE_CATEGORY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}




export const getCourseCategory_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "statusID": "",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COURSE_CATEGORY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getCourseCategorySuccess(data))
            }
            else {
                dispatch(getCourseCategorySuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCourseCategorySuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCourseCategorySuccess = (data) => {
    return {
        type: GET_COURSE_CATEGORY,
        payload: data
    }
}


export const createCourseLevel_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_COURSE_LEVEL, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const updateCourseLevel_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_COURSE_LEVEL, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}


export const getCourseExamType_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "statusID": "",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COURSE_EXAM_TYPE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getCourseExamTypeSuccess(data))
            }
            else {
                dispatch(getCourseExamTypeSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCourseExamTypeSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCourseExamTypeSuccess = (data) => {
    return {
        type: GET_COURSE_EXAM_TYPE,
        payload: data
    }
}


export const createCourseExamType_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_COURSE_EXAM_TYPE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const updateCourseExamType_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_COURSE_EXAM_TYPE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

//abhi
export const getCourseDepartment_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "statusID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COURSE_DEPARTMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCourseDepartmentSuccess(data))
            }
            else{
                dispatch(getCourseDepartmentSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCourseDepartmentSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getCourseDepartmentSuccess = (data) => {
    return {
        type : GET_COURSE_DEPARTMENT,
        payload : data
    }
}

// export const getCourseCategory_action = (data) => {
//     const initialPayload = {
//         "userID": LoginuserId,
//         "statusID":"",
//         "generalSearch":"",
//         "sortOrder":"",
//         "iDisplayStart":"0",
//         "iDisplayLength":"-1",
//         ...data
//     }
//     return dispatch => {
//         dispatch(mainLoadingTrue());
//         return ErpApiService.post(ErpApi.GET_COURSE_CATEGORY, initialPayload).then(response => {
//             const {data}  = response;
//             dispatch(mainLoadingFalse());
//             if(!data.error){
//                 dispatch(getCourseCategorySuccess(data))
//             }
//             else{
//                 dispatch(getCourseCategorySuccess({}))
//             }
//         })
//         .catch(err => {
//             console.log("err", err)
//             dispatch(getCourseCategorySuccess([]))
//             dispatch(mainLoadingFalse());
//         })
//     }
// }
// export const getCourseCategorySuccess = (data) => {
//     return {
//         type : GET_COURSE_CATEGORY,
//         payload : data
//     }
// }

// export const getCourseLevel_action = (data) => {
//     const initialPayload = {
//         "userID": LoginuserId,
//         "statusID":"",
//         "generalSearch":"",
//         "sortOrder":"",
//         "iDisplayStart":"0",
//         "iDisplayLength":"-1",
//         ...data
//     }
//     return dispatch => {
//         dispatch(mainLoadingTrue());
//         return ErpApiService.post(ErpApi.GET_COURSE_LEVEL, initialPayload).then(response => {
//             const {data}  = response;
//             dispatch(mainLoadingFalse());
//             if(!data.error){
//                 dispatch(getCourseLevelSuccess(data))
//             }
//             else{
//                 dispatch(getCourseLevelSuccess({}))
//             }
//         })
//         .catch(err => {
//             console.log("err", err)
//             dispatch(getCourseLevelSuccess([]))
//             dispatch(mainLoadingFalse());
//         })
//     }
// }
// export const getCourseLevelSuccess = (data) => {
//     return {
//         type : GET_COURSE_LEVEL,
//         payload : data
//     }
// }

// export const getCourseExamType_action = (data) => {
//     const initialPayload = {
//         "userID": LoginuserId,
//         "statusID":"",
//         "generalSearch":"",
//         "sortOrder":"",
//         "iDisplayStart":"0",
//         "iDisplayLength":"-1",
//         ...data
//     }
//     return dispatch => {
//         dispatch(mainLoadingTrue());
//         return ErpApiService.post(ErpApi.GET_COURSE_EXAM_TYPE, initialPayload).then(response => {
//             const {data}  = response;
//             dispatch(mainLoadingFalse());
//             if(!data.error){
//                 dispatch(getCourseExamTypeSuccess(data))
//             }
//             else{
//                 dispatch(getCourseExamTypeSuccess({}))
//             }
//         })
//         .catch(err => {
//             console.log("err", err)
//             dispatch(getCourseExamTypeSuccess([]))
//             dispatch(mainLoadingFalse());
//         })
//     }
// }
// export const getCourseExamTypeSuccess = (data) => {
//     return {
//         type : GET_COURSE_EXAM_TYPE,
//         payload : data
//     }
// }

export const createCourse_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "courseName":"",
        "courseDescription":"",
        "departmentID":"",
        "categoryID":"",
        "specializationID":"",
        "courseLevelID":"",
        "durationTypeID":"",
        "courseDuration":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_COURSE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCourse_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID":"",
        "categoryID":"",
        "specializationID":"",
        "courseLevelID":"",
        "durationTypeID":"",
        "statusID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COURSE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCourseSuccess(data))
            }
            else{
                dispatch(getCourseSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCourseSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getCourseSuccess = (data) => {
    return {
        type : GET_COURSE,
        payload : data
    }
}

export const getStudent_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "statusID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_STUDENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getStudentSuccess(data))
            }
            else{
                dispatch(getStudentSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getStudentSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getStudentSuccess = (data) => {
    return {
        type : GET_STUDENT,
        payload : data
    }
}

export const createStudent_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('universityNumber',  data.universityNumber);
    initialPayload.append('fullName',  data.studentName);
    initialPayload.append('fatherName', data?.fatherName);
    initialPayload.append('motherName', data.motherName);
    initialPayload.append('dateOfBirth', data.dob);
    initialPayload.append('gender', data.studentGender);
    initialPayload.append('courseID', data.courseID);
    initialPayload.append('documentFile', data.documentFile);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.CREATE_STUDENT, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getStudentDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "studentID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_STUDENT_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getStudentDetailSuccess(data))
            }
            else{
                dispatch(getStudentDetailSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getStudentDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getStudentDetailSuccess = (data) => {
    return {
        type : GET_STUDENT_DETAIL,
        payload : data
    }
}

export const updateStudent_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "studentID":"",
        "universityNumber":"",
        "fullName":"",
        "dateOfBirth":"",
        "motherName":"",
        "fatherName":"",
        "gender":"",
        "statusID":"",
        "courseID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_STUDENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateStudentProfileImage_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('studentID',  data.studentID);
    initialPayload.append('documentFile', data.documentFile);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPDATE_STUDENT_PROFILE_IMAGE, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getExamResult_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID":"",
        "categoryID":"",
        "courseCategoryID":"",
        "courseID":"",
        "semisterID":"",
        "sessionID":"",
        "statusID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_EXAM_RESULT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.status){
                dispatch(getExamResultSuccess(data))
            }
            else{
                dispatch(getExamResultSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getExamResultSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getExamResultSuccess = (data) => {
    return {
        type : GET_EXAM_RESULT,
        payload : data
    }
}




export const createExamResult_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID": "",
        "remark": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_COURSE_SUBJECT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const updateExamResult_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_COURSE_SUBJECT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}


export const importExamResult_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('documentFile', data.documentFile);
    // console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.IMPORT_EXAM_RESULT, initialPayload, config).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}


export const getApfTaskPlanQueryTemplete_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,

  
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(
        ErpApi.GET_APF_TASK_PLAN_QUERY_TEMPLETE,
        initialPayload
      )
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch({
              type: GET_APF_TASK_PLAN_QUERY_TEMPLETE,
              payload: data,
            });
          } else {
            dispatch({
              type: GET_APF_TASK_PLAN_QUERY_TEMPLETE,
              payload: [],
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch({
            type: GET_APF_TASK_PLAN_QUERY_TEMPLETE,
            payload: [],
          });
          dispatch(mainLoadingFalse());
        });
    };
  };

  export const getResultGrade_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "statusID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_RESULT_GRADE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getResultGradeSuccess(data))
            }
            else{
                dispatch(getResultGradeSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getResultGradeSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getResultGradeSuccess = (data) => {
    return {
        type : GET_RESULT_GRADE,
        payload : data
    }
}




export const createResultGrade_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_RESULT_GRADE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const updateResultGrade_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_RESULT_GRADE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}


export const importStudent_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('documentFile', data.documentFile);
    // console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.IMPORT_STUDENT, initialPayload, config).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

// export const uploadStudentProfileImage_action = (data) => {
//     let initialPayload = new FormData();
//     initialPayload.append('userID', LoginuserId);
//     // initialPayload.append('projectFileID', data.projectFileID);
//     for(var i = 0 ; i < data.taskDocuments.length; i++) {
//         initialPayload.append('documentFile'+data.taskDocuments[i].documentType+i, data.taskDocuments[i].documentFile);
//     }
//     initialPayload.append('taskDocuments', JSON.stringify(data.taskDocuments));
//     const config = {
//         headers: { 'content-type': 'multipart/form-data' }
//     }
//     return dispatch => {
//         dispatch(mainLoadingTrue());
//         return FileUploadService.post(ErpApi.UPLOAD_STUDENT_PROFILE_IMAGE, initialPayload, config).then(response => {
//             const {data}  = response;
//             dispatch(mainLoadingFalse());
//             if(!data.error)
//             {
//                 dispatch(displayMessage({
//                     text : data.message,
//                     type : "success"
//                 }));
//             }
//             else{
//                 dispatch(displayMessage({
//                     text : data.message,
//                     type : "success"
//                 }));
//             }
//         })
//         .catch(err => {
//             dispatch(mainLoadingFalse());
//         })
//     }
// }
export const uploadStudentProfileImage_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    // for (let index = 0; index < data.documentFile.length; index++) {
    //     initialPayload.append('documentFile[]', data.documentFile[index]);
    // }
    // for(var i = 0 ; i < data.taskDocuments.length; i++) {
    //     initialPayload.append('documentFile'+data.taskDocuments[i], data.taskDocuments[i].documentFile);
    // }
    for(var i = 0 ; i < data.taskDocuments.length; i++) {
        initialPayload.append('documentFile'+i, data.taskDocuments[i].documentFile);
    }
    initialPayload.append('taskDocuments', JSON.stringify(data.taskDocuments));
    // initialPayload.append('documentRemark', data.documentRemark);
    // initialPayload.append('documentTitle', data.documentTitle);
    // initialPayload.append('documentType',  data.documentType);
    // console.log("initialPayload", Array.from(initialPayload));
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.UPLOAD_STUDENT_PROFILE_IMAGE, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateCourse_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_COURSE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}


export const getExamResultFilterSession_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID":"",
        "courseID":"",
        "semisterID": "",
        "sessionID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_EXAM_RESULT_FILTER_SESSION, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_EXAM_RESULT_FILTER_SESSION,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_EXAM_RESULT_FILTER_SESSION,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_EXAM_RESULT_FILTER_SESSION,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getExamResultFilterDepartment_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID":"",
        "courseID":"",
        "semisterID": "",
        "sessionID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_EXAM_RESULT_FILTER_DEPARTMENT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_EXAM_RESULT_FILTER_DEPARTMENT,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_EXAM_RESULT_FILTER_DEPARTMENT,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_EXAM_RESULT_FILTER_DEPARTMENT,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getExamResultFilterCourse_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID":"",
        "courseID":"",
        "semisterID": "",
        "sessionID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_EXAM_RESULT_FILTER_COURSE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_EXAM_RESULT_FILTER_COURSE,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_EXAM_RESULT_FILTER_COURSE,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_EXAM_RESULT_FILTER_COURSE,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}

export const getExamResultFilterSemister_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID":"",
        "courseID":"",
        "semisterID": "",
        "sessionID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_EXAM_RESULT_FILTER_SEMISTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_EXAM_RESULT_FILTER_SEMISTER,
                    payload : data
                })
            }
            else{
                dispatch({
                    type : GET_EXAM_RESULT_FILTER_SEMISTER,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_EXAM_RESULT_FILTER_SEMISTER,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}


export const getDynamicFormData_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "formID": "",

        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_DYNAMIC_FORM_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDynamicFormDataSuccess(data))
            }
            else {
                dispatch(getDynamicFormDataSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDynamicFormDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getDynamicFormDataSuccess = (data) => {
    return {
        type: GET_DYNAMIC_FORM_DATA,
        payload: data
    }
}

export const getExamResultPrint_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID":"",
        "categoryID":"",
        "courseCategoryID":"",
        "courseID":"",
        "semisterID":"",
        "sessionID":"",
        "statusID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"0",
        "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        return ErpApiService.post(ErpApi.GET_EXAM_RESULT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.status){
                return data;
            }
            else{
                return [];
            }
        })
        .catch(err => {
            console.log("err", err)
            return [];
        })
    }
}

export const getSensorData_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "dataType": "",
        "toDate":"",
        "fromDate":"",

        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_SENSOR_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getSensorDataSuccess(data))
            }
            else {
                dispatch(getSensorDataSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getSensorDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getSensorDataSuccess = (data) => {
    return {
        type: GET_SENSOR_DATA,
        payload: data
    }
}