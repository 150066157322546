import {
    GET_COURSE_SUBJECT,
    GET_COURSE_DEPARTMENT,
    GET_COURSE_CATEGORY,
    GET_COURSE_LEVEL,
    GET_COURSE_EXAM_TYPE,
    GET_COURSE,
    GET_STUDENT,
    GET_STUDENT_DETAIL,
    GET_APF_TASK_PLAN_QUERY_TEMPLETE,
    GET_EXAM_RESULT,
    GET_RESULT_GRADE,
    GET_EXAM_RESULT_FILTER_SESSION,
    GET_EXAM_RESULT_FILTER_DEPARTMENT,
    GET_EXAM_RESULT_FILTER_COURSE,
    GET_EXAM_RESULT_FILTER_SEMISTER,
    GET_DYNAMIC_FORM_DATA,
    GET_SENSOR_DATA
} from './types';
const initialStateChart = {
    labels: [],
    datasets: [],
};
const initialState = {
    // civilResourcePlanning: initialStateChart,
    // civilResourceTypePlanning: initialStateChart,
    // civilBudgetPlanning: initialStateChart,
    // getProjectNames: [],
    // getProcessNames: [],
    // getTaskNames: [],
    // getResourceNames: [],
    // courtCasePendingReminderCount : 0,
    // pendingLiaisonReports : [], 
    // pendingLiaisonReportsCount : 0,
    getCourseSubject: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCourseDepartment: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCourseCategory: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCourseLevel: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCourseExamType: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCourse: {
        error: false,
        message: "",
        data: [],
        totalCount: 0,
    },
    getStudent: {
        error: false,
        message: "",
        data: [],
        totalCount: 0,
    },
    getStudentDetail: {
        error: false,
        message: "",
        data: [],
        totalCount: 0,
    },
    getApfTaskPlanQueryTemplete: {
        error: false,
        message: "",
        data: [],
        totalCount: 0,
    },
    getExamResult: {
        error: false,
        message: "",
        data: [],
        totalCount: 0,
    },
    getResultGrade: {
        error: false,
        message: "",
        data: [],
        totalCount: 0,
    },
    getExamResultFilterSession: [],
    getExamResultFilterDepartment: [],
    getExamResultFilterCourse: [],
    getExamResultFilterSemister: [],
    getDynamicFormData: {
        error: false,
        message: "",
        data: [],
    },
    getSensorData: {
        error: false,
        message: "",
        data: [],
    },
}
const Report_reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_COURSE_SUBJECT:
            return Object.assign({}, state, { getCourseSubject: payload });
        case GET_COURSE_DEPARTMENT:
            return Object.assign({}, state, { getCourseDepartment: payload });
        case GET_COURSE_CATEGORY:
            return Object.assign({}, state, { getCourseCategory: payload });
        case GET_COURSE_LEVEL:
            return Object.assign({}, state, { getCourseLevel: payload });
        case GET_COURSE_EXAM_TYPE:
            return Object.assign({}, state, { getCourseExamType: payload });
        case GET_COURSE_DEPARTMENT:
            return Object.assign({}, state, { getCourseDepartment: payload });
        //abhi
        // case GET_COURSE_CATEGORY:
        //     return Object.assign({}, state, { getCourseCategory: payload });
        // case GET_COURSE_LEVEL:
        //     return Object.assign({}, state, { getCourseLevel: payload });
        // case GET_COURSE_EXAM_TYPE:
        //     return Object.assign({}, state, { getCourseExamType: payload });
        case GET_COURSE:
            return Object.assign({}, state, { getCourse: payload });
        case GET_STUDENT:
            return Object.assign({}, state, { getStudent: payload });
        case GET_STUDENT_DETAIL:
            return Object.assign({}, state, { getStudentDetail: payload });
        case GET_APF_TASK_PLAN_QUERY_TEMPLETE:
            return Object.assign({}, state, { getApfTaskPlanQueryTemplete: payload, });
        case GET_EXAM_RESULT:
            return Object.assign({}, state, { getExamResult: payload });
        case GET_RESULT_GRADE:
            return Object.assign({}, state, { getResultGrade: payload });
        case GET_EXAM_RESULT_FILTER_SESSION:
            return Object.assign({}, state, { getExamResultFilterSession: payload });
        case GET_EXAM_RESULT_FILTER_DEPARTMENT:
            return Object.assign({}, state, { getExamResultFilterDepartment: payload });
        case GET_EXAM_RESULT_FILTER_COURSE:
            return Object.assign({}, state, { getExamResultFilterCourse: payload });
        case GET_EXAM_RESULT_FILTER_SEMISTER:
            return Object.assign({}, state, { getExamResultFilterSemister: payload });
        case GET_DYNAMIC_FORM_DATA:
            return Object.assign({}, state, { getDynamicFormData: payload });
        case GET_SENSOR_DATA:
            return Object.assign({}, state, { getSensorData: payload });
        default:
            return state;
    }
}
export default Report_reducer;