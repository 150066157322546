import {ApiPaths, ApiService, SetAuthToken, ApiStatus,ErpApiService,ErpApi,FileUploadService} from '../../utils/ApiService';
import {GET_CIVIL_RESOURCE_PLANNING, GET_CIVIL_PROJECT_NAMES, GET_CIVIL_PROCESS_NAMES, 
    GET_CIVIL_TASK_NAMES, GET_CIVIL_RESOURCE_NAMES, 
    GET_CIVIL_RESOURCE_TYPE_PLANNING, 
    GET_CIVIL_BUDGET_NAMES,

    GET_CIVIL_PROJECT,GET_CIVIL_PROJECT_PLANNIG_STAGE,
    GET_CIVIL_PROJECT_TEAM_ROLE,GET_CIVIL_PROJECT_PLAN_LIST,
    GET_CIVIL_PROJECT_PLAN_TEAM,GET_CIVIL_PROJECT_SITE_LOCATION,
    GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT,GET_CIVIL_WBS,
    GET_CIVIL_PROJECT_PROCESS_PLAN,GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS,GET_CIVIL_PROJECT_PROCESS_TASK,
    GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK,GET_TASK_DEPENDENCY_TYPE,
    GET_CIVIL_PROJECT_ROLE,GET_CIVIL_PROJECT_PLAN_FILTER_STAGE,GET_CIVIL_PROJECT_PROCESS_TASK_PLAN,
    GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY,
    GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY,GET_CIVIL_MATERIAL,
    GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL,GET_CIVIL_PROJECT_ACTIVITY_MASTER,GET_CIVIL_PROJECT_TASK_UOM,GET_CIVIL_PROJECT_WBS,
    GET_CIVIL_PROJECT_TASK,GET_CIVIL_PROCESS_M,GET_CIVIL_RESOURCE,
    GET_CIVIL_RESOURCE_CATEGORY,GET_CIVIL_PROJECT_RESOURCE_1,GET_CIVIL_OBJECT_PRODUCTIVITY,
    GET_CIVIL_RESOURCE_ALL,GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE,
    GET_CIVIL_PROJECT_ACTIVITY_DETAIL,GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY,

    GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY, GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE,
    GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY, 
    GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE,GET_CIVIL_PROJECT_RESOURCE_EXPORT,
    COPY_CIVIL_PROJECT_RESOURCE,GET_CIVIL_OBJECT_PRODUCTIVITY_EXPORT,COPY_CIVIL_PROJECT_PRODUCTIVITY,
    GET_CIVIL_RESOURCE_EXPORT,
    GET_CIVIL_PROJECT_PLAN_LIST_EXPORT, GET_CIVIL_PROJECT_TASK_UOM_EXPORT, GET_CIVIL_PROCESS_M_EXPORT, GET_CIVIL_PROJECT_TASK_EXPORT,
    GET_CIVIL_PROJECT_ROLE_EXPORT,GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE_EXPORT,GET_CIVIL_PROJECT_ACTIVITY_EXPORT,
    GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT,GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT,
    GET_CIVIL_PROJECT_BUDGET,GET_CIVIL_PROJECT_WBS_DETAIL,GET_CIVIL_PROJECT_PLAN_PENDING,GET_CIVIL_PROJECT_SCHEDULE,GET_CIVIL_RESOURCE_IDENTIFICATION,GET_CIVIL_MATERIAL_FOR_ACTIVITY

} from './types';
import {mainLoadingTrue, mainLoadingFalse, displayMessage} from '../User/action'

import {getItem, setItem} from '../../utils/crypto';


const userLocal = getItem('enuseloc');
const LoginuserId = userLocal && Object.keys(userLocal).length > 0 ? userLocal['user_id'] : null;


export const getResourcePlanning_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.CIVILRESOURCEPLANNING, data).then(response => {
            const {data}  = response;
            console.log("scheduleData", data.scheduleData)
            dispatch(getCivilResourcePlanningSuccess(data.scheduleData))
            dispatch(mainLoadingFalse());
            // if(!data.error){
            //     dispatch(getCivilResourcePlanningSuccess(data.scheduleData))
            // }
            // else{
            //     dispatch(getCivilResourcePlanningSuccess({}))
            // }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilResourcePlanningSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilResourcePlanningSuccess = (data) => {
    return {
        type : GET_CIVIL_RESOURCE_PLANNING,
        payload : data
    }
}

export const getResourceTypePlanning_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.CIVILRESOURCETYPEPLANNING, data).then(response => {
            const {data}  = response;
            console.log("scheduleData", data.scheduleData)
            dispatch(getCivilResourceTypePlanningSuccess(data.scheduleData))
            dispatch(mainLoadingFalse());
            // if(!data.error){
            //     dispatch(getCivilResourcePlanningSuccess(data.scheduleData))
            // }
            // else{
            //     dispatch(getCivilResourcePlanningSuccess({}))
            // }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilResourceTypePlanningSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilResourceTypePlanningSuccess = (data) => {
    return {
        type : GET_CIVIL_RESOURCE_TYPE_PLANNING,
        payload : data
    }
}

export const getProjectsNames_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCIVILPROJECTS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getProjectsNamesSuccess(data.projectData))
            }
            else{
                dispatch(getProjectsNamesSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getProjectsNamesSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getProjectsNamesSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_NAMES,
        payload : data
    }
}

export const getResourceNames_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCIVILRESOURCE, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getResourceNamesSuccess(data.resourceData))
            }
            else{
                dispatch(getResourceNamesSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getResourceNamesSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getResourceNamesSuccess = (data) => {
    return {
        type : GET_CIVIL_RESOURCE_NAMES,
        payload : data
    }
}

export const getCivilProcess_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCIVILPROCESS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilProcessSuccess(data.processData))
            }
            else{
                dispatch(getCivilProcessSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProcessSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProcessSuccess = (data) => {
    return {
        type : GET_CIVIL_PROCESS_NAMES,
        payload : data
    }
}

export const getCivilTasks_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCIVILTASKS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilTasksSuccess(data.taskData))
            }
            else{
                dispatch(getCivilTasksSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilTasksSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilTasksSuccess = (data) => {
    return {
        type : GET_CIVIL_TASK_NAMES,
        payload : data
    }
}

export const getCivilBudget_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCIVILBUDGET, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilBudgetSuccess(data.scheduleData))
            }
            else{
                dispatch(getCivilBudgetSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilBudgetSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilBudgetSuccess = (data) => {
    return {
        type : GET_CIVIL_BUDGET_NAMES,
        payload : data
    }
}



export const getCivilProgress_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCIVILPROGRESS, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilProgressSuccess(data.scheduleData))
            }
            else{
                dispatch(getCivilProgressSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProgressSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProgressSuccess = (data) => {
    return {
        type : GET_CIVIL_BUDGET_NAMES,
        payload : data
    }
}

export const getCivilProject_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "status": "",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectSuccess(data))
            }
            else{
                dispatch(getCivilProjectSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProjectSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT,
        payload : data
    }
}

export const getCivilProjectPlanningStage_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "projectID":"",
        "status": "",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_PLANNIG_STAGE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectPlanningStageSuccess(data))
            }
            else{
                dispatch(getCivilProjectPlanningStageSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectPlanningStageSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProjectPlanningStageSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_PLANNIG_STAGE,
        payload : data
    }
}


export const createCivilProjectPlan_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "planningStageID":"",
        "planningExtent":"",
        "developmentArea":"",
        "developmentBudget":"",
        "plannedStartDate":"",
        "plannedEndDate":"",
        "plannedDuration":"",
        "planningRemark":"",

        "project_problems":"",
        "project_purpose":"",
        "project_goals":"",
        "project_deliverable":"",
        "project_specifications":"",
        "specification_in_scrope":"",
        "specification_out_scrope":"",
        "process_owner":"",
        "key_stakeholder":"",
        "final_customer":"",
        "expected_benefits":"",
        "risks":"",
        "constraints":"",
        "assumptions":"",

        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectTeamRole_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "projectID":"",
        "status": "",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TEAM_ROLE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectTeamRoleSuccess(data))
            }
            else{
                dispatch(getCivilProjectTeamRoleSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectTeamRoleSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectTeamRoleSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_TEAM_ROLE,
        payload : data
    }
}

export const getCivilProjectPlanList_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "projectPlanID":"",
        "stageID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_PLAN_LIST, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectPlanListSuccess(data))
            }
            else{
                dispatch(getCivilProjectPlanListSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectPlanListSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectPlanListSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_PLAN_LIST,
        payload : data
    }
}

export const createCivilProjectTeam_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "roleID":"",
        "memberID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_TEAM, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const createCivilProjectLocation_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "projectLocationID":[],
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_LOCATION, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectPlanTeam_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "projectID":"",
        "status":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_PLAN_TEAM, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectPlanTeamSuccess(data))
            }
            else{
                dispatch(getCivilProjectPlanTeamSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectPlanTeamSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectPlanTeamSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_PLAN_TEAM,
        payload : data
    }
}

export const getCivilProjectSiteLocation_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "projectID":"",
        "status":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_SITE_LOCATION, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectSiteLocationSuccess(data))
            }
            else{
                dispatch(getCivilProjectSiteLocationSuccess([]))
            }
            return response?.data;
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectSiteLocationSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectSiteLocationSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_SITE_LOCATION,
        payload : data
    }
}

export const getCivilProjectPlanFilterProject_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        // "projectPlanID":"",
        // "status":"",
        // "generalSearch":"",
        // "sortOrder":"",
        // "iDisplayStart":0,
        // "iDisplayLength":-1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectPlanFilterProjectSuccess(data))
            }
            else{
                dispatch(getCivilProjectPlanFilterProjectSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectPlanFilterProjectSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectPlanFilterProjectSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT,
        payload : data
    }
}

export const getCivilWbs_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "projectPlanID":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_WBS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilWbsSuccess(data))
            }
            else{
                dispatch(getCivilWbsSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilWbsSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilWbsSuccess = (data) => {
    return {
        type : GET_CIVIL_WBS,
        payload : data
    }
}

export const createCivilProjectWbs_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID":"",
        "processID":[],
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_PROCESS_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectProcessPlan_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "projectID":"",
        "projectPlanID":"",
        "processID":"",
        "status":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":10,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_PROCESS_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectProcessPlanSuccess(data))
            }
            else{
                dispatch(getCivilProjectProcessPlanSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectProcessPlanSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectProcessPlanSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_PROCESS_PLAN,
        payload : data
    }
}

export const getCivilProjectProcessPlanFilterProcess_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "projectID":"",
        "projectPlanID":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectProcessPlanFilterProcessSuccess(data))
            }
            else{
                dispatch(getCivilProjectProcessPlanFilterProcessSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectProcessPlanFilterProcessSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectProcessPlanFilterProcessSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS,
        payload : data
    }
}

export const getCivilProjectProcessTask_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      processPlanID: "",
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_PROCESS_TASK,initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getCivilProjectProcessTaskSuccess(data));
          } else {
            dispatch(getCivilProjectProcessTaskSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getCivilProjectProcessTaskSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };

  export const getCivilProjectProcessTaskSuccess = (data) => {
    return {
      type: GET_CIVIL_PROJECT_PROCESS_TASK,
      payload: data,
    };
  };

  


  export const getCivilProjectProcessTaskPlanFilterTask_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      processPlanID: "",
      taskID:"",
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(
        ErpApi.GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK,
        initialPayload
      )
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getCivilProjectProcessTaskPlanFilterTaskSuccess(data));
          } else {
            dispatch(getCivilProjectProcessTaskPlanFilterTaskSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getCivilProjectProcessTaskPlanFilterTaskSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };

  export const getCivilProjectProcessTaskPlanFilterTaskSuccess = (data) => {
    return {
      type: GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK,
      payload: data,
    };
  };

  export const getTaskDependencyType_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_TASK_DEPENDENCY_TYPE, initialPayload).then(response => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getTaskDependencyTypeSuccess(data));
          } else {
            dispatch(getTaskDependencyTypeSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getTaskDependencyTypeSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };

  export const getTaskDependencyTypeSuccess = (data) => {
    return {
      type: GET_TASK_DEPENDENCY_TYPE,
      payload: data,
    };
  };

  export const getCivilProjectRole_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "roleStatus": "",
    "generalSearch": "",
    "sortOrder": "",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_ROLE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectRoleSuccess(data))
            }
            else{
                dispatch(getCivilProjectRoleSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectRoleSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProjectRoleSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_ROLE,
        payload : data
    }
}

export const updateCivilProjectRoleStatus_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
    "roleID": "",
    "roleName": "",
    "roleStatus": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_ROLE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const createCivilProjectRole_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "roleName":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_ROLE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const createCivilProjectProcessTaskPlan_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "processPlanID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_PROCESS_TASK_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectPlanFilterStage_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "projectID": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_PLAN_FILTER_STAGE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectPlanFilterStageSuccess(data))
            }
            else{
                dispatch(getCivilProjectPlanFilterStageSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectPlanFilterStageSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProjectPlanFilterStageSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_PLAN_FILTER_STAGE,
        payload : data
    }
}

export const getCivilProjectProcessTaskPlan_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "projectID": "",
        "projectPlanID": "",
        "processPlanID": "",
        "taskID": "",
        "status":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": "0",
        "iDisplayLength": "10",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_ACTIVITY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectProcessTaskPlanSuccess(data))
            }
            else{
                dispatch(getCivilProjectProcessTaskPlanSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectProcessTaskPlanSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProjectProcessTaskPlanSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_PROCESS_TASK_PLAN,
        payload : data
    }
}

export const getCivilProjectTaskPlanLocationQuantity_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "taskPlanID": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectTaskPlanLocationQuantitySuccess(data))
            }
            else{
                dispatch(getCivilProjectTaskPlanLocationQuantitySuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectTaskPlanLocationQuantitySuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProjectTaskPlanLocationQuantitySuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY,
        payload : data
    }
}

export const createCivilProjectTaskPlanQuantity_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "taskPlanID":"",
        "taskPlanQuantity":"",
        "locationQuantityID":[],
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_TASK_PLAN_QUANTITY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectTaskMaterialCategory_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectTaskMaterialCategorySuccess(data))
            }
            else{
                dispatch(getCivilProjectTaskMaterialCategorySuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectTaskMaterialCategorySuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectTaskMaterialCategorySuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY,
        payload : data
    }
}

export const getCivilProjectTaskMaterial_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "categoryID":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_MATERIAL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectTaskMaterialSuccess(data))
            }
            else{
                dispatch(getCivilProjectTaskMaterialSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectTaskMaterialSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectTaskMaterialSuccess = (data) => {
    return {
        type : GET_CIVIL_MATERIAL,
        payload : data
    }
}

export const createCivilProjectTaskPlanMaterial_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "taskPlanID":"",
        "materialID":"",
        "materialQuantity":"",
        "budgetedRate":"",
        "materialDescription":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_PLAN_MATERIAL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const getCivilProjectTaskPlanMaterial_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "taskPlanID":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectTaskPlanMaterialSuccess(data))
            }
            else{
                dispatch(getCivilProjectTaskPlanMaterialSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectTaskPlanMaterialSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectTaskPlanMaterialSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL,
        payload : data
    }
}

export const getCivilProjectTaskPlanMaterialDelete_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "taskPlanMaterialID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_DELETE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectActivityMaster_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "processID":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_ACTIVITY_MASTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectActivityMasterSuccess(data))
            }
            else{
                dispatch(getCivilProjectActivityMasterSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectActivityMasterSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectActivityMasterSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_ACTIVITY_MASTER,
        payload : data
    }
}
export const getCivilProjectTaskUOM_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "taskUomStatus": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK_UOM, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilProjectTaskUOMSuccess(data))
            }
            else {
                dispatch(getCivilProjectTaskUOMSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProjectTaskUOMSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getCivilProjectTaskUOMSuccess = (data) => {
    return {
        type: GET_CIVIL_PROJECT_TASK_UOM,
        payload: data
    }
}

export const createCivilProjectTaskUOM_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
       "taskUomName":"",
"uomRemark":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_TASK_UOM, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const updateCivilProjectTaskUOM_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "uomID": "",
        "taskUomName": "",
        "uomRemark":"",
        "uomStatus": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_TASK_UOM, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const updateCivilProjectPlan_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID":"",
        "planningStageID":"",
        "planningExtent":"",
        "developmentArea":"",
        "developmentBudget":"",
        "plannedStartDate":"",
        "plannedEndDate":"",
        "plannedDuration":"",
        "planningRemark":"",

        "project_problems":"",
        "project_purpose":"",
        "project_goals":"",
        "project_deliverable":"",
        "project_specifications":"",
        "specification_in_scrope":"",
        "specification_out_scrope":"",
        "process_owner":"",
        "key_stakeholder":"",
        "final_customer":"",
        "expected_benefits":"",
        "risks":"",
        "constraints":"",
        "assumptions":"",

        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectWbs_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "projectPlanID": "",
        "processPlanID": "",
        "statusID":"",
        "generalSearch": "",
        "projectID": "",
        "taskID": "",
        "sortOrder": "",
        "iDisplayStart": "0",
        "iDisplayLength": "10",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_WBS, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectWbsSuccess(data))
            }
            else{
                dispatch(getCivilProjectWbsSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectWbsSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectWbsSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_WBS,
        payload : data
    }
}
export const getCivilProjectTask_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "taskStatus": "",
        "processID":"",
        "taskCategory": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilProjectTaskSuccess(data))
            }
            else {
                dispatch(getCivilProjectTaskSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProjectTaskSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getCivilProjectTaskSuccess = (data) => {
    return {
        type: GET_CIVIL_PROJECT_TASK,
        payload: data
    }
}

export const createCivilProjectTask_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "taskUomID":"",
        "taskOrder":"",
       "taskName":"",
       "processID":"",
       "taskRemark":"",
       "taskCategory":"Schedule",
       "taskDescription":"",
       "taskCode":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_TASK, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const updatetCivilProjectTask_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "taskID": "",
        "taskName": "",
        "taskUomID":"",
        "taskOrder":"",
        "processID":"",
        "taskRemark":"",
        "taskStatus": "",
        "taskCode":"",
        "taskCategory":"Schedule",
        "taskDescription":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_TASK, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilProcesslist_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "parentID":"",
        "processIsRoad": "",
        "processStatus": "",
        "generalSearch": "",
        "category":"",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROCESS_M, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilProcesslistSuccess(data))
            }
            else {
                dispatch(getCivilProcesslistSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProcesslistSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilProcesslistSuccess = (data) => {
    return {
        type: GET_CIVIL_PROCESS_M,
        payload: data
    }
}
export const createCivilProjectProcess_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "parentID":"",
        "processName":"",
        "processOrder":"",
       "processIsRoad":"",
       "projectProgressStatus":"",
       "processRemark":"",
       "category":"Schedule",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_PROCESS, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const updateCivilProjectProcess_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "parentID":"",
        "processID": "",
        "processName": "",
        "processOrder":"",
        "processIsRoad":"",
        "projectProgressStatus":"",
        "processRemark":"",
        "processStatus": "",
        "category":"Schedule",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_PROCESS, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilResource_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "categoryID": "",
        "statusID":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_RESOURCE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilResourceSuccess(data))
            }
            else {
                dispatch(getCivilResourceSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilResourceSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilResourceSuccess = (data) => {
    return {
        type: GET_CIVIL_RESOURCE,
        payload: data
    }
}

export const createCivilResource_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "categoryID":"",
        "name":"",
       "remark":"",
       "typeColor":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_RESOURCE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const updateCivilResource_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "categoryID": "",
        "resourceID": "",
   
        "name":"",
        "status":"",
        "remark":"",
        "typeColor":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_CIVIL_RESOURCE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilResourceCategory_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "statusID":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_RESOURCE_CATEGORY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilResourceCategorySuccess(data))
            }
            else {
                dispatch(getCivilResourceCategorySuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilResourceCategorySuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilResourceCategorySuccess = (data) => {
    return {
        type: GET_CIVIL_RESOURCE_CATEGORY,
        payload: data
    }
}

export const getCivilProjectResource_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID":"",
        "resourceCategoryID":"",
        "resourceID":"",
        "uomID":"",
        "status":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_RESOURCE_1, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectResourceSuccess(data))
            }
            else{
                dispatch(getCivilProjectResourceSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectResourceSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProjectResourceSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_RESOURCE_1,
        payload : data
    }
}

export const updateCivilProjectResource_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectResourceID": "",
        "resourceRate": "",
        "uomID": "",
        "remark": "",
        "status":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_RESOURCE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const createCivilObjectProductivity_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        // "projectPlanID": "",
        // "taskID":"",
        // "resourceID": "",
        // "outputPerHr": "",
        // "remark": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_RESOURCE_PRODUCTIVITY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilProjectResourceProductivity_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID":"",
        "processID":"",
        "taskID":"",
        "resourceCategoryID":"",
        "resourceID":"",
        "status": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_OBJECT_PRODUCTIVITY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilObjectProductivitySuccess(data))
            }
            else {
                dispatch(getCivilObjectProductivitySuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilObjectProductivitySuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilObjectProductivitySuccess = (data) => {
    return {
        type: GET_CIVIL_OBJECT_PRODUCTIVITY,
        payload: data
    }
}

export const createCivilProjectResource_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID": "",
        "resourceID": "",
        "resourceRate": "",
        "uom": "",
        "remark": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_RESOURCE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}
export const createCivilProjectTaskPlanResource_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "taskPlanID": "",
        "projectResourceID": "",
        "plannedQuantity": "",
        "resourceDescription": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_TASK_PLAN_RESOURCE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}
export const getCivilResourceAll_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "categoryID": "",
        "statusID":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_RESOURCE_ALL, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilResourceAllSuccess(data))
            }
            else {
                dispatch(getCivilResourceAllSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilResourceAllSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilResourceAllSuccess = (data) => {
    return {
        type: GET_CIVIL_RESOURCE_ALL,
        payload: data
    }
}
export const getCivilProjectTaskPlanResource_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID":"",
        "processPlanID":"",
        "taskPlanID": "",
        "resourceCategoryID":"",
        "resourceID":"",
        "statusID":"Active",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        // $userID = $input->userID;
        // $projectPlanID = $input->projectPlanID;
        // $processPlanID = $input->processPlanID;
        // $taskPlanID = $input->taskPlanID;
        // $resourceCategoryID = $input->resourceCategoryID;
        // $resourceID = $input->resourceID;
        // $statusID = $input->statusID;
        // $generalSearch = $input->generalSearch;
        // $sortOrder = $input->sortOrder;
        // $iDisplayStart = $input->iDisplayStart;
        // $iDisplayLength = $input->iDisplayLength;
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilProjectTaskPlanResourceSuccess(data))
            }
            else {
                dispatch(getCivilProjectTaskPlanResourceSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProjectTaskPlanResourceSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilProjectTaskPlanResourceSuccess = (data) => {
    return {
        type: GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE,
        payload: data
    }
}
export const updateCivilProjectTaskPlanDuration_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "taskPlanID":"",
        "plannedDuration":"",
        "taskPlanOrder":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_TASK_PLAN_DURATION, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectActivityDetail_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "taskPlanID": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_ACTIVITY_DETAIL, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilProjectActivityDetailSuccess(data))
            }
            else {
                dispatch(getCivilProjectActivityDetailSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProjectActivityDetailSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilProjectActivityDetailSuccess = (data) => {
    return {
        type: GET_CIVIL_PROJECT_ACTIVITY_DETAIL,
        payload: data
    }
}

export const createCivilProjectTaskPlanDependancy_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "taskPlanID":"",
        "relationshipID":"",
        "dependantTaskPlanID":"",
        "lagLead":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_TASK_PLAN_DEPENDENCY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectDependableActivity_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "taskPlanID": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilProjectDependableActivitySuccess(data))
            }
            else {
                dispatch(getCivilProjectDependableActivitySuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProjectDependableActivitySuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilProjectDependableActivitySuccess = (data) => {
    return {
        type: GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY,
        payload: data
    }
}

export const getCivilProjectResourceFilterResourceCategory_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilProjectResourceFilterResourceCategorySuccess(data))
            }
            else {
                dispatch(getCivilProjectResourceFilterResourceCategorySuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProjectResourceFilterResourceCategorySuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilProjectResourceFilterResourceCategorySuccess = (data) => {
    return {
        type: GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY,
        payload: data
    }
}


export const getCivilProjectResourceFilterResource_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID": "",
        "resourceCategoryID":"",
        "statusID":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilProjectResourceFilterResourceSuccess(data))
            }
            else {
                dispatch(getCivilProjectResourceFilterResourceSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProjectResourceFilterResourceSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilProjectResourceFilterResourceSuccess = (data) => {
    return {
        type: GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE,
        payload: data
    }
}

export const getCivilProjectResourceProductivityFilterResourceCategory_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID": "",
        "processID":"",
        "taskID":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilProjectResourceProductivityFilterResourceCategorySuccess(data))
            }
            else {
                dispatch(getCivilProjectResourceProductivityFilterResourceCategorySuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProjectResourceProductivityFilterResourceCategorySuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilProjectResourceProductivityFilterResourceCategorySuccess = (data) => {
    return {
        type: GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY,
        payload: data
    }
}

export const getCivilProjectResourceProductivityFilterResource_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID": "",
        "processID":"",
        "taskID":"",
        "resourceCategoryID":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilProjectResourceProductivityFilterResourceSuccess(data))
            }
            else {
                dispatch(getCivilProjectResourceProductivityFilterResourceSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProjectResourceProductivityFilterResourceSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilProjectResourceProductivityFilterResourceSuccess = (data) => {
    return {
        type: GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE,
        payload: data
    }
}


// already available changes 
export const updateCivilObjectProductivity_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "resourceProductivityID": "",
        "outputPerHr": "",
        "remark": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}


export const getCivilProjectResourceExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID":"",
        "resourceCategoryID":"",
        "resourceID":"",
        "uomID":"",
        "status":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_RESOURCE_1, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilProjectResourceExportSuccess(data))
            }
            else{
                dispatch(getCivilProjectResourceExportSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectResourceExportSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getCivilProjectResourceExportSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_RESOURCE_EXPORT,
        payload : data
    }
}


export const copyCivilProjectResource_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "fromProjectPlanID":"",
        "toProjectPlanID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.COPY_CIVIL_PROJECT_RESOURCE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(copyCivilProjectResourceSuccess(data))
            }
            else{
                dispatch(copyCivilProjectResourceSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(copyCivilProjectResourceSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const copyCivilProjectResourceSuccess = (data) => {
    return {
        type : COPY_CIVIL_PROJECT_RESOURCE,
        payload : data
    }
}


export const getCivilProjectResourceProductivityExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID":"",
        "processID":"",
        "taskID":"",
        "resourceCategoryID":"",
        "resourceID":"",
        "status": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_OBJECT_PRODUCTIVITY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilProjectResourceProductivityExportSuccess(data))
            }
            else{
                dispatch(getCivilProjectResourceProductivityExportSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectResourceProductivityExportSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getCivilProjectResourceProductivityExportSuccess = (data) => {
    return {
        type : GET_CIVIL_OBJECT_PRODUCTIVITY_EXPORT,
        payload : data
    }
}


export const copyCivilResourceProductivity_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "fromProjectPlanID":"",
        "toProjectPlanID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.COPY_CIVIL_PROJECT_PRODUCTIVITY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(copyCivilResourceProductivitySuccess(data))
            }
            else{
                dispatch(copyCivilResourceProductivitySuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(copyCivilResourceProductivitySuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const copyCivilResourceProductivitySuccess = (data) => {
    return {
        type : COPY_CIVIL_PROJECT_PRODUCTIVITY,
        payload : data
    }
}


export const importCivilResourceList_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('documentFile',  data.documentFile);

    // console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.IMPORT_LIAISON_DOC_CHECKLIST_PLAN, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const importCivilProjectResource_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('documentFile',  data.documentFile);

    // console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.IMPORT_CIVIL_PROJECT_RESOURCE, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}


export const importCivilProjectResourceProductivity_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('documentFile',  data.documentFile);

    // console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.IMPORT_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilResourceExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "categoryID": "",
        "statusID": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_RESOURCE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getCivilResourceExportSuccess(data))
            }
            else {
                dispatch(getCivilResourceExportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilResourceExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getCivilResourceExportSuccess = (data) => {
    return {
        type: GET_CIVIL_RESOURCE_EXPORT,
        payload: data
    }
}


export const getCivilProjectPlanListExport_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID": "",
        "stageID": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_PLAN_LIST, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getCivilProjectPlanListExportSuccess(data))
            }
            else {
                dispatch(getCivilProjectPlanListExportSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProjectPlanListExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilProjectPlanListExportSuccess = (data) => {
    return {
        type: GET_CIVIL_PROJECT_PLAN_LIST_EXPORT,
        payload: data
    }
}

export const getCivilProjectTaskUOMExport_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "taskUomStatus": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK_UOM, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getCivilProjectTaskUOMExportSuccess(data))
            }
            else {
                dispatch(getCivilProjectTaskUOMExportSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProjectTaskUOMExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getCivilProjectTaskUOMExportSuccess = (data) => {
    return {
        type: GET_CIVIL_PROJECT_TASK_UOM_EXPORT,
        payload: data
    }
}

export const getCivilProcesslistExport_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "processIsRoad": "",
        "processStatus": "",
        "category":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROCESS_M, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getCivilProcesslistExportSuccess(data))
            }
            else {
                dispatch(getCivilProcesslistExportSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProcesslistExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilProcesslistExportSuccess = (data) => {
    return {
        type: GET_CIVIL_PROCESS_M_EXPORT,
        payload: data
    }
}

export const getCivilProjectTaskExport_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "processID": "",
        "taskStatus": "",
        "taskCategory":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getCivilProjectTaskExportSuccess(data))
            }
            else {
                dispatch(getCivilProjectTaskExportSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProjectTaskExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getCivilProjectTaskExportSuccess = (data) => {
    return {
        type: GET_CIVIL_PROJECT_TASK_EXPORT,
        payload: data
    }
}


export const getCivilProjectRoleExport_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "roleStatus": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_ROLE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getCivilProjectRoleExportSuccess(data))
            }
            else {
                dispatch(getCivilProjectRoleExportSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProjectRoleExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getCivilProjectRoleExportSuccess = (data) => {
    return {
        type: GET_CIVIL_PROJECT_ROLE_EXPORT,
        payload: data
    }
}

export const getCivilProjectTaskPlanResourceExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectPlanID":"",
        "processPlanID":"",
        "taskPlanID":"",
        "resourceCategoryID":"",
        "resourceID":"",
        "statusID": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCivilProjectTaskPlanResourceExportSuccess(data))
            }
            else{
                dispatch(getCivilProjectTaskPlanResourceExportSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectTaskPlanResourceExportSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getCivilProjectTaskPlanResourceExportSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE_EXPORT,
        payload : data
    }
}

export const getCivilProjectProcessTaskPlanExport_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "projectID": "",
        "projectPlanID": "",
        "processPlanID": "",
        "taskID": "",
        "status":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": "0",
        "iDisplayLength": "10",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_ACTIVITY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectProcessTaskPlanExportSuccess(data))
            }
            else{
                dispatch(getCivilProjectProcessTaskPlanExportSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectProcessTaskPlanExportSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProjectProcessTaskPlanExportSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_ACTIVITY_EXPORT,
        payload : data
    }
}

export const getCivilProjectTaskPlanQuantityExport_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,

        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectTaskPlanQuantityExportSuccess(data))
            }
            else{
                dispatch(getCivilProjectTaskPlanQuantityExportSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectTaskPlanQuantityExportSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProjectTaskPlanQuantityExportSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT,
        payload : data
    }
}

export const importCivilProjectTaskPlanQuantityExport_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('documentFile',  data.documentFile);

    // console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.IMPORT_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const importCivilProjectTaskPlanQuantity_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('documentFile',  data.documentFile);

    // console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.IMPORT_CIVIL_PROJECT_TASK_PLAN_QUANTITY, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const importCivilProjectTaskPlanResource_action = (data) => {
    let initialPayload = new FormData();
    initialPayload.append('userID', LoginuserId);
    initialPayload.append('documentFile',  data.documentFile);

    // console.log("data", initialPayload);
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return FileUploadService.post(ErpApi.IMPORT_CIVIL_PROJECT_TASK_PLAN_RESOURCE, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const getCivilProjectTaskPlanCivilResourceExport_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,

        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectTaskPlanCivilResourceExportSuccess(data))
            }
            else{
                dispatch(getCivilProjectTaskPlanCivilResourceExportSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectTaskPlanCivilResourceExportSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProjectTaskPlanCivilResourceExportSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT,
        payload : data
    }
}

export const updateCivilProjectTaskPlanSchedule_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "projectPlanID": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_TASK_PLAN_SCHEDULE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}



export const getCivilProjectBudget_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "projectPlanID":"",
        "processPlanID":"",
        "statusID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":"",
        "iDisplayLength":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_BUDGET, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectBudgetSuccess(data))
            }
            else{
                dispatch(getCivilProjectBudgetSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectBudgetSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProjectBudgetSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_BUDGET,
        payload : data
    }
}

export const getCivilProjectActivityDelete_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "taskPlanID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CIVIL_PROJECT_ACTIVITY_DELETE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));

                return "success";
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
                return "failed";
            }

            
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })

        
    }
}

export const getCivilProjectWbsDelete_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "processPlanID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CIVIL_PROJECT_WBS_DELETE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
                return "success";
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
                return "failed";
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const getCivilProjectWbsDetail_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "processPlanID":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_WBS_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectWbsDetailSuccess(data))
            }
            else{
                dispatch(getCivilProjectWbsDetailSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectWbsDetailSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCivilProjectWbsDetailSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_WBS_DETAIL,
        payload : data
    }
}

export const updateCivilProjectProcessPlan_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "processPlanID":"",
        "processPlanOrder":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_PROCESS_PLAN, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));

                return "success";
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
                return "failed";
            }

            
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })

        
    }
}

export const getCivilProjectPlanPending_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "status": "",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_PLAN_PENDING, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectPlanPendingSuccess(data))
            }
            else{
                dispatch(getCivilProjectPlanPendingSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectPlanPendingSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectPlanPendingSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_PLAN_PENDING,
        payload : data
    }
}

export const deleteCivilProjectTaskPlanDependancy_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "dependancyID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.DELETE_CIVIL_PROJECT_TASK_PLAN_DEPENDENCY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateCivilProjectTaskPlanDependancy_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "taskPlanID":"",
        "dependancyID":[],
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_TASK_PLAN_DEPENDENCY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectSchedule_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "status": "",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength":-1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_SCHEDULE, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilProjectScheduleSuccess(data))
            }
            else{
                dispatch(getCivilProjectScheduleSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilProjectScheduleSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilProjectScheduleSuccess = (data) => {
    return {
        type : GET_CIVIL_PROJECT_SCHEDULE,
        payload : data
    }
}

export const civilProjectWbsDeleteBulk_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "processPlanID":[],
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CIVIL_PROJECT_WBS_DELETE_BULK, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const civilProjectActivityDeleteBulk_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "taskPlanID":[],
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CIVIL_PROJECT_ACTIVITY_DELETE_BULK, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                // toast.error(data.message);
                dispatch(displayMessage({
                    text : data.message,
                    type : "error"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const getCivilResourceIdentification_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_RESOURCE_FOR_ACTIVITY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            
            if (!data.error) {
                dispatch(getCivilResourceIdentificationSuccess(data))
            }
            else {
                dispatch(getCivilResourceIdentificationSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilResourceIdentificationSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilResourceIdentificationSuccess = (data) => {
    return {
        type: GET_CIVIL_RESOURCE_IDENTIFICATION,
        payload: data
    }
}


export const getCivilMaterialForActivity_action = (data) => {

    const initialPayload = {
        "userID":LoginuserId,
        "categoryID":"",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_CIVIL_MATERIAL_FOR_ACTIVITY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            
            if(!data.error){
                dispatch(getCivilMaterialForActivitySuccess(data))
            }
            else{
                dispatch(getCivilMaterialForActivitySuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCivilMaterialForActivitySuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getCivilMaterialForActivitySuccess = (data) => {
    return {
        type : GET_CIVIL_MATERIAL_FOR_ACTIVITY,
        payload : data
    }
}