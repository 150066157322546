export const GET_PROFITABLE_REPORTS = 'GET_PROFITABLE_REPORTS';
export const GET_LIASONPROCESSOWNER = 'GET_LIASONPROCESSOWNER';
export const GET_PROJECT_NAMES = 'GET_PROJECT_NAMES';
export const GET_TASK_DELAYS = 'GET_TASK_DELAYS';
export const GET_PROFITABLE_REPORT_COUNT = 'GET_PROFITABLE_REPORT_COUNT';
export const GET_PENDING_TASK = 'GET_PENDING_TASK';
export const GET_LIAISON_PROJECT_NAMES = 'GET_LIAISON_PROJECT_NAMES';

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
// export const OTP_SENT_SUCCESS = 'OTP_SENT_SUCCESS';
// export const OTP_VALID_SUCCESS = 'OTP_VALID_SUCCESS';
// export const OTP_VALID_FAILS = 'OTP_VALID_FAILS';

export const GET_USER_DASHBOARD_DATA = 'GET_USER_DASHBOARD_DATA';
export const GET_USER_DASHBOARD_TASK = 'GET_USER_DASHBOARD_TASK';
export const GET_CIVIL_PROCESS_DATA = 'GET_CIVIL_PROCESS_DATA';
export const GET_ENQUIRY_SOURCE = "GET_ENQUIRY_SOURCE";


export const GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA = "GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA";
export const GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA = "GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA";
export const GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA = "GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA";

export const GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA = "GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA";
export const GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA = "GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA";
export const GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA = "GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA";
export const SET_FUNNEL_ITEM_ID = "SET_FUNNEL_ITEM_ID";

export const GET_DASHBOARD_STOCK_ZONE_DATA = "GET_DASHBOARD_STOCK_ZONE_DATA"
export const GET_DASHBOARD_STOCK_PROJECT_DATA = "GET_DASHBOARD_STOCK_PROJECT_DATA"
export const GET_DASHBOARD_STOCK_PROJECT_SITE_DATA = "GET_DASHBOARD_STOCK_PROJECT_SITE_DATA"

export const GET_DASHBOARD_STOCK_AGING_ZONE_DATA = "GET_DASHBOARD_STOCK_AGING_ZONE_DATA"
export const GET_DASHBOARD_STOCK_AGING_PROJECT_DATA = "GET_DASHBOARD_STOCK_AGING_PROJECT_DATA"
export const GET_DASHBOARD_STOCK_AGING_FILE_DATA = "GET_DASHBOARD_STOCK_AGING_FILE_DATA"

export const GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA = "GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA"
export const GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA = "GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA"
export const GET_DASHBOARD_LAND_AQUIRED_FILE_DATA = "GET_DASHBOARD_LAND_AQUIRED_FILE_DATA"

export const GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA = "GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA"
export const GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA = "GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA"
export const GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA = "GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA"
// ________BD chart data seperate___________
export const GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA = "GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA"
export const GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA = "GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA"
export const GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA = "GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA"
export const GET_DASHBOARD_DATA_DEPARTMENT = "GET_DASHBOARD_DATA_DEPARTMENT";
export const GET_DASHBOARD_DEPARTMENT = "GET_DASHBOARD_DEPARTMENT";
export const GET_LIAISON_PROCESS_PLAN_REPORT = "GET_LIAISON_PROCESS_PLAN_REPORT";
export const GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL = "GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL";


export const GET_MY_DASHBOARD_DATA_FILTER_USER = "GET_MY_DASHBOARD_DATA_FILTER_USER";
export const GET_DASHBOARD_CRM_PROJECT_OUTSTANDING = "GET_DASHBOARD_CRM_PROJECT_OUTSTANDING";
export const GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT = "GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT";
export const GET_DASHBOARD_CRM_SITE_OUTSTANDING = "GET_DASHBOARD_CRM_SITE_OUTSTANDING";
export const GET_DASHBOARD_CRM_SITE_OUTSTANDING_EXPORT = "GET_DASHBOARD_CRM_SITE_OUTSTANDING_EXPORT";