export const GET_CIVIL_RESOURCE_PLANNING = 'GET_CIVIL_RESOURCE_PLANNING';
export const GET_CIVIL_PROJECT_NAMES = 'GET_CIVIL_PROJECT_NAMES';
export const GET_CIVIL_PROCESS_NAMES = 'GET_CIVIL_PROCESS_NAMES';


export const GET_CIVIL_TASK_NAMES = 'GET_CIVIL_TASK_NAMES';
export const GET_CIVIL_RESOURCE_NAMES = 'GET_CIVIL_RESOURCE_NAMES';
export const GET_CIVIL_RESOURCE_TYPE_PLANNING = 'GET_CIVIL_RESOURCE_TYPE_PLANNING';
export const GET_CIVIL_BUDGET_NAMES = 'GET_CIVIL_BUDGET_NAMES';
// export const SUCCESS_STATUS = 'SUCCESS_STATUS';
// export const OTP_SENT_SUCCESS = 'OTP_SENT_SUCCESS';
// export const OTP_VALID_SUCCESS = 'OTP_VALID_SUCCESS';
// export const OTP_VALID_FAILS = 'OTP_VALID_FAILS';

export const GET_CIVIL_PROJECT = "GET_CIVIL_PROJECT";
export const GET_CIVIL_PROJECT_PLANNIG_STAGE = "GET_CIVIL_PROJECT_PLANNIG_STAGE";
export const GET_CIVIL_PROJECT_TEAM_ROLE = "GET_CIVIL_PROJECT_TEAM_ROLE";
export const GET_CIVIL_PROJECT_PLAN_LIST = "GET_CIVIL_PROJECT_PLAN_LIST";

export const GET_CIVIL_PROJECT_PLAN_TEAM = "GET_CIVIL_PROJECT_PLAN_TEAM";
export const GET_CIVIL_PROJECT_SITE_LOCATION = "GET_CIVIL_PROJECT_SITE_LOCATION";
export const GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT = "GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT";
export const GET_CIVIL_WBS = "GET_CIVIL_WBS";
export const GET_CIVIL_PROJECT_PROCESS_PLAN = "GET_CIVIL_PROJECT_PROCESS_PLAN";
export const GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS = "GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS";
export const GET_CIVIL_PROJECT_PROCESS_TASK = "GET_CIVIL_PROJECT_PROCESS_TASK";
export const GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK = "GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK";
export const GET_TASK_DEPENDENCY_TYPE = "GET_TASK_DEPENDENCY_TYPE";

export const GET_CIVIL_PROJECT_ROLE="GET_CIVIL_PROJECT_ROLE";
export const CREATE_CIVIL_PROJECT_ROLE="CREATE_CIVIL_PROJECT_ROLE";
export const UPDATE_CIVIL_PROJECT_ROLE="UPDATE_CIVIL_PROJECT_ROLE";

export const GET_CIVIL_PROJECT_PLAN_FILTER_STAGE = "GET_CIVIL_PROJECT_PLAN_FILTER_STAGE";
export const GET_CIVIL_PROJECT_PROCESS_TASK_PLAN = "GET_CIVIL_PROJECT_PROCESS_TASK_PLAN";
export const GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY = "GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY";
export const GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY = "GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY";
export const GET_CIVIL_MATERIAL = "GET_CIVIL_MATERIAL";
export const GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL = "GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL";
export const GET_CIVIL_PROJECT_ACTIVITY_MASTER = "GET_CIVIL_PROJECT_ACTIVITY_MASTER";
export const GET_CIVIL_PROJECT_TASK_UOM="GET_CIVIL_PROJECT_TASK_UOM";
export const GET_CIVIL_PROJECT_WBS = "GET_CIVIL_PROJECT_WBS";
export const GET_CIVIL_PROJECT_TASK="GET_CIVIL_PROJECT_TASK";
export const GET_CIVIL_PROCESS_M="GET_CIVIL_PROCESS_M";
export const GET_CIVIL_RESOURCE="GET_CIVIL_RESOURCE";
export const GET_CIVIL_RESOURCE_CATEGORY="GET_CIVIL_RESOURCE_CATEGORY";
export const GET_CIVIL_PROJECT_RESOURCE_1 = "GET_CIVIL_PROJECT_RESOURCE_1";
export const GET_CIVIL_OBJECT_PRODUCTIVITY="GET_CIVIL_OBJECT_PRODUCTIVITY";
export const GET_CIVIL_RESOURCE_ALL="GET_CIVIL_RESOURCE_ALL";
export const GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE = "GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE";
export const GET_CIVIL_PROJECT_ACTIVITY_DETAIL = "GET_CIVIL_PROJECT_ACTIVITY_DETAIL";
export const GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY = "GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY";
export const GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY="GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY";
export const GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE="GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE";
export const GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY="GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY";
export const GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE="GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE";
export const GET_CIVIL_PROJECT_RESOURCE_EXPORT="GET_CIVIL_PROJECT_RESOURCE_EXPORT";
export const COPY_CIVIL_PROJECT_RESOURCE="COPY_CIVIL_PROJECT_RESOURCE";
export const GET_CIVIL_OBJECT_PRODUCTIVITY_EXPORT="GET_CIVIL_OBJECT_PRODUCTIVITY_EXPORT";
export const COPY_CIVIL_PROJECT_PRODUCTIVITY="COPY_CIVIL_PROJECT_PRODUCTIVITY";
export const GET_CIVIL_RESOURCE_EXPORT="GET_CIVIL_RESOURCE_EXPORT";
export const GET_CIVIL_PROJECT_PLAN_LIST_EXPORT="GET_CIVIL_PROJECT_PLAN_LIST_EXPORT";
export const GET_CIVIL_PROJECT_TASK_UOM_EXPORT="GET_CIVIL_PROJECT_TASK_UOM_EXPORT";
export const GET_CIVIL_PROCESS_M_EXPORT="GET_CIVIL_PROCESS_M_EXPORT";
export const GET_CIVIL_PROJECT_TASK_EXPORT="GET_CIVIL_PROJECT_TASK_EXPORT";
export const GET_CIVIL_PROJECT_ROLE_EXPORT="GET_CIVIL_PROJECT_ROLE_EXPORT";
export const GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE_EXPORT="GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE_EXPORT";
export const GET_CIVIL_PROJECT_ACTIVITY_EXPORT="GET_CIVIL_PROJECT_ACTIVITY_EXPORT";
export const GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT="GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT";
export const GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT="GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT";
export const GET_CIVIL_PROJECT_BUDGET = "GET_CIVIL_PROJECT_BUDGET";
export const GET_CIVIL_PROJECT_WBS_DETAIL = "GET_CIVIL_PROJECT_WBS_DETAIL";
export const GET_CIVIL_PROJECT_PLAN_PENDING = "GET_CIVIL_PROJECT_PLAN_PENDING";
export const GET_CIVIL_PROJECT_SCHEDULE = "GET_CIVIL_PROJECT_SCHEDULE";
export const GET_CIVIL_RESOURCE_IDENTIFICATION = "GET_CIVIL_RESOURCE_IDENTIFICATION";
export const GET_CIVIL_MATERIAL_FOR_ACTIVITY="GET_CIVIL_MATERIAL_FOR_ACTIVITY";