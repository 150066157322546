import {
    GET_BD_USER, GET_DEPARTMENT, GET_PROJECT_LIST, ADD_TASK, GET_TASK, GET_TASK_CATEGORY, GET_TASK_EXPORT,
    GET_FEASIBILITY_REPORT_DETAIL, UPDATE_TASK_OWNER, UPDATE_TASK_START, UPDATE_TASK_COMPLETED,
    GET_TASK_DETAIL, GET_TASK_PROJECT_FILTER, GET_TASK_CATEGORY_FILTER, GET_TASK_DEPARTMENT_FILTER,
    GET_TASK_OWNER_FILTER, GET_USER_DESIGNATION, GET_USER_ROLE_FUNCTIONALITY, CREATE_USER_ROLE_FUNCTIONALITY,
    GET_PUBLIC_HOLIDAY_LIST, GET_YEAR
    , GET_SALARY_ADVANCE_DEDUCTION_HISTORY, GET_SALARY_ADVANCE_EXPORT,
    GET_SALARY_ADVANCE, GET_OUT_PASS, GET_EMPLOYEE, GET_OUT_PASS_EXPORT, GET_EMPLOYEE_ATTENDANCE, GET_LEAVE_TYPE, GET_EMPLOYEE_CHECKIN,
    GET_PETTY_CASH, GET_PETTY_CASH_MASTER_EXPORT, GET_LEAVE_SUMMARY, GET_COMP_OFF_LEAVE_SUMMARY, GET_VEHICLE_SERVICE_REQUEST, GET_VECHICLE_TYPE, GET_SERVICE_TYPE,
    GET_EMPLOYEE_ATTENDANCE_EXPORT, GET_VEHICLE_SERVICE_REQUEST_EXPORT, GET_VEHICLE_NUMBER, GET_GST, GET_ASSEST_TYPE,GET_DESIGNATION,GET_DESIGNATION_EXPORT,GET_DEPARTMENT_MASTER,GET_DEPARTMENT_EXPORT
} from './types';
const initialStateChart = {
    labels: [],
    datasets: [],
};
const initialState = {
    projectAuthority: [],
    // civilResourceTypePlanning : initialStateChart,
    // civilBudgetPlanning : initialStateChart,
    user: [],
    projectList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    departmentList: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    feasibilityReportDetail: [],
    createTask: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getTask: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getTaskCategory: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getTaskExport: {
        error: false,
        message: "",
        data: [],
        totalCount: 0,
    },
    updateTaskOwner: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    updateTaskStart: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    updateTaskComplete: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getTaskDetail: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getTaskProjectFilter: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getTaskCategoryFilter: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getTaskDepartmentFilter: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getTaskOwnerFilter: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getUserDesignation: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getUserRoleFunctionality: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    createUserRoleFunctionality: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getPublicHolidayList: {
        error: false,
        message: "",
        data: [],
    },
    getYear: {
        error: false,
        message: "",
        data: [],
    },
    getSalaryAdvanceMaster: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getSalaryAdvanceMasterExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getSalaryAdvanceDeductionHistory: {
        error: false,
        message: "",
        data: [],
    },
    getOutPass: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getEmployee: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getOutPassExport: {
        error: false,
        message: "",
        data: [],
    },
    getEmployeeAttendance: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getLeaveType: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getEmployeeCheckin: {
        error: false,
        message: "",
        data: [],
    },
    getPettyCashMaster: {
        error: false,
        message: "",
        data: [],
    },
    getPettyCashMasterExport: {
        error: false,
        message: "",
        data: [],
    },
    getLeaveSummary: {
        error: false,
        message: "",
        data: [],
    },
    getCompOffLeaveSummary: {
        error: false,
        message: "",
        data: [],
    },
    getVehicleServiceRequest: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getVehicleType: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getServiceType: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getEmployeeAttendanceExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getVehicleServiceRequestExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getVehicleNumber: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getGst: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getAssetType: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getDesignation: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getDesignationExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getDepartmentMaster: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getDepartmentExport: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
}
const HR_reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_BD_USER:
            return Object.assign({}, state, { user: payload });
        case GET_DEPARTMENT:
            return Object.assign({}, state, { departmentList: payload });
        case GET_PROJECT_LIST:
            return Object.assign({}, state, { projectList: payload });
        case ADD_TASK:
            return Object.assign({}, state, { createTask: payload });
        case GET_TASK:
            return Object.assign({}, state, { getTask: payload });
        case GET_TASK_CATEGORY:
            return Object.assign({}, state, { getTaskCategory: payload });
        case GET_TASK_EXPORT:
            return Object.assign({}, state, { getTaskExport: payload });
        case GET_FEASIBILITY_REPORT_DETAIL:
            return Object.assign({}, state, { feasibilityReportDetail: payload });
        case UPDATE_TASK_OWNER:
            return Object.assign({}, state, { updateTaskOwner: payload });
        case UPDATE_TASK_START:
            return Object.assign({}, state, { updateTaskStart: payload });
        case UPDATE_TASK_COMPLETED:
            return Object.assign({}, state, { updateTaskComplete: payload });
        case GET_TASK_DETAIL:
            return Object.assign({}, state, { getTaskDetail: payload });
        case GET_TASK_PROJECT_FILTER:
            return Object.assign({}, state, { getTaskProjectFilter: payload });
        case GET_TASK_CATEGORY_FILTER:
            return Object.assign({}, state, { getTaskCategoryFilter: payload });
        case GET_TASK_DEPARTMENT_FILTER:
            return Object.assign({}, state, { getTaskDepartmentFilter: payload });
        case GET_TASK_OWNER_FILTER:
            return Object.assign({}, state, { getTaskOwnerFilter: payload });
        case GET_USER_DESIGNATION:
            return Object.assign({}, state, { getUserDesignation: payload });
        case GET_USER_ROLE_FUNCTIONALITY:
            return Object.assign({}, state, { getUserRoleFunctionality: payload });
        case CREATE_USER_ROLE_FUNCTIONALITY:
            return Object.assign({}, state, { createUserRoleFunctionality: payload });
        case GET_PUBLIC_HOLIDAY_LIST:
            return Object.assign({}, state, { getPublicHolidayList: payload });
        case GET_YEAR:
            return Object.assign({}, state, { getYear: payload });
        case GET_SALARY_ADVANCE:
            return Object.assign({}, state, { getSalaryAdvanceMaster: payload });
        case GET_SALARY_ADVANCE_DEDUCTION_HISTORY:
            return Object.assign({}, state, { getSalaryAdvanceDeductionHistory: payload });
        case GET_SALARY_ADVANCE_EXPORT:
            return Object.assign({}, state, { getSalaryAdvanceMasterExport: payload });
        case GET_OUT_PASS:
            return Object.assign({}, state, { getOutPass: payload });
        case GET_EMPLOYEE:
            return Object.assign({}, state, { getEmployee: payload });
        case GET_OUT_PASS_EXPORT:
            return Object.assign({}, state, { getOutPassExport: payload });
        case GET_EMPLOYEE_ATTENDANCE:
            return Object.assign({}, state, { getEmployeeAttendance: payload });
        case GET_LEAVE_TYPE:
            return Object.assign({}, state, { getLeaveType: payload });
        case GET_EMPLOYEE_CHECKIN:
            return Object.assign({}, state, { getEmployeeCheckin: payload });
        case GET_PETTY_CASH:
            return Object.assign({}, state, { getPettyCashMaster: payload });
        case GET_PETTY_CASH_MASTER_EXPORT:
            return Object.assign({}, state, { getPettyCashMasterExport: payload });
        case GET_LEAVE_SUMMARY:
            return Object.assign({}, state, { getLeaveSummary: payload });
        case GET_COMP_OFF_LEAVE_SUMMARY:
            return Object.assign({}, state, { getCompOffLeaveSummary: payload });
        case GET_VEHICLE_SERVICE_REQUEST:
            return Object.assign({}, state, { getVehicleServiceRequest: payload });
        case GET_VECHICLE_TYPE:
            return Object.assign({}, state, { getVehicleType: payload });
        case GET_SERVICE_TYPE:
            return Object.assign({}, state, { getServiceType: payload });
        case GET_EMPLOYEE_ATTENDANCE_EXPORT:
            return Object.assign({}, state, { getEmployeeAttendanceExport: payload });
        case GET_VEHICLE_SERVICE_REQUEST_EXPORT:
            return Object.assign({}, state, { getVehicleServiceRequestExport: payload });
        case GET_VEHICLE_NUMBER:
            return Object.assign({}, state, { getVehicleNumber: payload });
        case GET_GST:
            return Object.assign({}, state, { getGst: payload });
        case GET_ASSEST_TYPE:
            return Object.assign({}, state, { getAssetType: payload });
        case GET_DESIGNATION:
            return Object.assign({}, state, { getDesignation: payload });
        case GET_DESIGNATION_EXPORT:
            return Object.assign({}, state, { getDesignationExport: payload });
        case GET_DEPARTMENT_MASTER:
            return Object.assign({}, state, { getDepartmentMaster: payload });
        case GET_DEPARTMENT_EXPORT:
            return Object.assign({}, state, { getDepartmentExport: payload });
        default:
            return state;
    }
}
export default HR_reducer;