import { GET_PROFITABLE_REPORTS, GET_LIASONPROCESSOWNER, GET_PROJECT_NAMES, GET_PENDING_TASK, 
    GET_TASK_DELAYS, GET_PROFITABLE_REPORT_COUNT,
    GET_LIAISON_PROJECT_NAMES,
    GET_DASHBOARD_DATA,
    GET_USER_DASHBOARD_DATA, GET_USER_DASHBOARD_TASK,GET_CIVIL_PROCESS_DATA,GET_ENQUIRY_SOURCE,
    GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA,GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA,GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA,
    GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA,
    GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA,
    GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA,SET_FUNNEL_ITEM_ID,
    GET_DASHBOARD_STOCK_ZONE_DATA,GET_DASHBOARD_STOCK_PROJECT_DATA,GET_DASHBOARD_STOCK_PROJECT_SITE_DATA,
    GET_DASHBOARD_STOCK_AGING_ZONE_DATA,GET_DASHBOARD_STOCK_AGING_PROJECT_DATA,GET_DASHBOARD_STOCK_AGING_FILE_DATA,
    GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA,GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA,GET_DASHBOARD_LAND_AQUIRED_FILE_DATA,GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA,GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA,GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA,GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA,GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA,GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA,GET_DASHBOARD_DATA_DEPARTMENT,GET_DASHBOARD_DEPARTMENT,

    GET_LIAISON_PROCESS_PLAN_REPORT,GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL,GET_MY_DASHBOARD_DATA_FILTER_USER,GET_DASHBOARD_CRM_PROJECT_OUTSTANDING,GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT,GET_DASHBOARD_CRM_SITE_OUTSTANDING,
    
 } from './types';

const initialState = {
    profitableReports : [],
    profitableReportCount : 0,
    LiasonProcessOwners : [],
    getProjectNames : [],
    getTaskDelays : [],
    getPendingTasks : [],
    getLisionProjectNames : [],
    DashboardData: [],
    myDashboardData : {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    myDashboardTask : {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    civilProcessProgressData : {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    enquirySourceList:{
        error: false,
        message: "",
        data: [],
    },

    getDashboardAcquiredButPlanApprovalPendingZoneData:{
        error: false,
        message: "",
        data: [],
    },
    getDashboardAcquiredButPlanApprovalPendingProjectData:{
        error: false,   
        message: "",
        data: [],
    },
    getDashboardAcquiredButPlanApprovalPendingFileData:{
        error: false,
        message: "",
        data: [],
    },
    getDashboardPlanApprovalAppliedZoneData:{
        error: false,
        message: "",
        data: [],
    },
    getDashboardPlanApprovalAppliedProjectData:{
        error: false,
        message: "",
        data: [],
    },
    getDashboardPlanApprovalAppliedFileData:{
        error: false,
        message: "",
        data: [],
    },
    // getFunnelID:{
    //     data:""
    // }
    getBDDashboardDataDepartment:{
        error: false,
        message: "",
        data: [],
    },
    getBDDashboardDepartment:{
        error: false,
        message: "",
        data: [],
    },
    getLiaisonProcessPlanReport:{
        error: false,
        message: "",
        data: {
            data:[{}],
            dataTotal:[{}]
        },
        totalCount:0
 
    
    },
    liaisonProcessPlanReportDetail:{
        error: false,
        message: "",
        data: [],
    },
    getMyDashboardDataFilterUser:{
        error: false,
        message: "",
        data: [],
    },
    getDashboardCrmProjectOutstanding:{
        error: false,
        message: "",
        data: [],
    },
    getDashboardCrmProjectOutstandingExport:{
        error: false,
        message: "",
        data: [],
    },
    getDashboardCrmSiteOutstanding:{
        error: false,
        message: "",
        data: [],
        totalCount: 0,
    }
}

const Report_reducer = (state = initialState, { type, payload }) => {
    // console.log(type, "payload", payload)
    switch(type)
    {
        case GET_PROFITABLE_REPORTS:
            return Object.assign({}, state, { profitableReports: payload });        
        case GET_PROFITABLE_REPORT_COUNT:
            return Object.assign({}, state, { profitableReportCount: payload });
        case GET_LIASONPROCESSOWNER:
            return Object.assign({}, state, { LiasonProcessOwners: payload });
        case GET_PROJECT_NAMES:
            return Object.assign({}, state, { getProjectNames: payload });
        case GET_LIAISON_PROJECT_NAMES:
            return Object.assign({}, state, { getLisionProjectNames: payload });
        case GET_TASK_DELAYS:
            return Object.assign({}, state, { getTaskDelays: payload });
        case GET_PENDING_TASK:
            return Object.assign({}, state, { getPendingTasks: payload });

        case GET_DASHBOARD_DATA:
            return Object.assign({}, state, { DashboardData: payload });


        case GET_USER_DASHBOARD_DATA:
            return Object.assign({}, state, { myDashboardData: payload });
        case GET_USER_DASHBOARD_TASK:
                return Object.assign({}, state, { myDashboardTask: payload });

        case GET_CIVIL_PROCESS_DATA:
            return Object.assign({}, state, { civilProcessProgressData: payload });

        case GET_ENQUIRY_SOURCE:
            return Object.assign({}, state, { enquirySourceList: payload });
           


       case GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA:
            return Object.assign({}, state, { getDashboardAcquiredButPlanApprovalPendingZoneData: payload });
         
        case GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA:
            return Object.assign({}, state, { getDashboardAcquiredButPlanApprovalPendingProjectData: payload });

        case GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA:
            return Object.assign({}, state, { getDashboardAcquiredButPlanApprovalPendingFileData: payload });

        case GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA:
            return Object.assign({}, state, { getDashboardPlanApprovalAppliedZoneData: payload });
        
        case GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA:
            return Object.assign({}, state, { getDashboardPlanApprovalAppliedProjectData: payload });
        
        case GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA:
            return Object.assign({}, state, { getDashboardPlanApprovalAppliedFileData: payload });    

                    
        case SET_FUNNEL_ITEM_ID:
            return Object.assign({}, state, { getFunnelID: payload });    

        case GET_DASHBOARD_STOCK_ZONE_DATA:
            return Object.assign({}, state, { getDashboardStockZoneData: payload });
        
        case GET_DASHBOARD_STOCK_PROJECT_DATA:
            return Object.assign({}, state, { getDashboardStockProjectData: payload });    
    
        case GET_DASHBOARD_STOCK_PROJECT_SITE_DATA:
            return Object.assign({}, state, { getDashboardStockProjectSiteData: payload });

        case GET_DASHBOARD_STOCK_AGING_ZONE_DATA:
            return Object.assign({}, state, { getDashboardStockAgingZoneData: payload });
        
        case GET_DASHBOARD_STOCK_AGING_PROJECT_DATA:
            return Object.assign({}, state, { getDashboardStockAgingProjectData: payload });
        case GET_DASHBOARD_STOCK_AGING_FILE_DATA:
            return Object.assign({}, state, { getDashboardStockAgingFileData: payload });

        case GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA:
            return Object.assign({}, state, { getDashboardLandAquiredZoneData: payload });
        
        case GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA:
            return Object.assign({}, state, { getDashboardLandAquiredProjectData: payload });

        case GET_DASHBOARD_LAND_AQUIRED_FILE_DATA:
            return Object.assign({}, state, { getDashboardLandAquiredFileData: payload });

        case GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA:
            return Object.assign({}, state, { getDashboardApplanApprovalZoneData: payload });

        case GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA:
            return Object.assign({}, state, { getDashboardApplanApprovalProjectData: payload });

        case GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA:
            return Object.assign({}, state, { getDashboardApplanApprovalFileData: payload });

        case GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA:
            return Object.assign({}, state, { getBDDashboardLandAcquisitionData: payload });

        case GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA:
            return Object.assign({}, state, { getBDDashboardPlanApprovalData: payload });

        case GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA:
            return Object.assign({}, state, { getBDDashboardPlanApprovedTargetActualData: payload });
        case GET_DASHBOARD_DATA_DEPARTMENT:
            return Object.assign({}, state, { getBDDashboardDataDepartment: payload });

        case GET_DASHBOARD_DEPARTMENT:
            return Object.assign({}, state, { getBDDashboardDepartment: payload });

        case GET_LIAISON_PROCESS_PLAN_REPORT:
            return Object.assign({}, state, { getLiaisonProcessPlanReport: payload });

        case GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL:
            return Object.assign({}, state, { liaisonProcessPlanReportDetail: payload });

        case GET_MY_DASHBOARD_DATA_FILTER_USER:
            return Object.assign({}, state, { getMyDashboardDataFilterUser: payload });

        case GET_DASHBOARD_CRM_PROJECT_OUTSTANDING:
            return Object.assign({}, state, { getDashboardCrmProjectOutstanding: payload });

        case GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT:
                return Object.assign({}, state, { getDashboardCrmProjectOutstandingExport: payload });

        case GET_DASHBOARD_CRM_SITE_OUTSTANDING:
            return Object.assign({}, state, { getDashboardCrmSiteOutstanding: payload });
    
        // case DISPLAY_MESSAGE:
        //     return Object.assign({}, state, { messageInfo: payload });
            
        // case DISPLAY_ERROR:
        //     return Object.assign({}, state, { error: payload.error });

        // case SUCCESS_STATUS:
        //     return Object.assign({}, state, { status: payload.status });
            
        // case OTP_SENT_SUCCESS:
        //     return Object.assign({}, state, { otp_sent: true });
        
        // case OTP_VALID_FAILS:
        //     return Object.assign({}, state, { valid_otp: false });

        // case OTP_VALID_SUCCESS:
        //         return Object.assign({}, state, { valid_otp: true });
            
                
        default:  
            return state;
    }
}

export default Report_reducer;