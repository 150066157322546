import React, {useEffect, useState} from 'react'
import { Routes, Navigate, Route } from 'react-router-dom';
import {routes} from './routes';
import Login from '../../Pages/Login';
import Navbar from './index';
import { useStore } from 'react-redux';
import {getItem} from '../../utils/crypto';

const  MainRouter = (props) =>{
    const [isAuth, setAuth ] = useState(false)
    const loginNotRequiedPaths = ['/courtCase/:reportDate', 
                '/nextHearing/:reportDate', '/liaison-reports/:reportDate',
                '/liaison_own_report/:reportDate', '/liaison_all_report/:reportDate',
                '/liaison_shc_report/:reportDate', '/liaison_kns_report/:reportDate',
                '/task-wise-quantity/:reportDate', '/task-wise-date/:reportDate',
                '/project-date-change/:reportDate', '/overal-machinenary-planning/:reportDate',
                '/kethos-machinenary-planning/:reportDate', 'resource-type-planning','/birthdayWish','/workAnniversary']
                
    useEffect(()=>{
        try{
            const user = JSON.parse(localStorage.getItem("user"));
            // console.log("user", user)
            if(user && user['id']){
                setAuth(true)
            }
        }catch(err){
            setAuth(false)
        }
    })

    return (
        <div className="app-container bg-light">
            <div className="container pt-4 pb-4">
                <Routes>
                    {routes.map(({ path, Component, exact, state={}, isNavbar}) => {
                        return <Route path={path} exact={exact} key={path} element={isAuth ? 
                        <React.Suspense fallback={<div>Loading...</div>}>
                            {isNavbar ? <Navbar>         
                                    <Component {...props}/>           
                            </Navbar>
                            :  
                            // <div style={{padding : "2%", overflow : "auto"}}>
                                    <Component {...props}/>
                                // </div>  
                            }
                        </React.Suspense>
                        : loginNotRequiedPaths.includes(path) ?
                            <React.Suspense fallback={<div>Loading...</div>}>
                                <Component {...props}/> 
                             </React.Suspense>
                            : <Login />} />
                        })}
                </Routes>
            </div>
        </div>
    );
}

export default MainRouter;