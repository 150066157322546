export const GET_COURSE_SUBJECT = 'GET_COURSE_SUBJECT';
export const GET_COURSE_DEPARTMENT = 'GET_COURSE_DEPARTMENT';
export const GET_COURSE_CATEGORY = 'GET_COURSE_CATEGORY';
export const GET_COURSE_LEVEL='GET_COURSE_LEVEL';
export const GET_COURSE_EXAM_TYPE='GET_COURSE_EXAM_TYPE';
//abhi
// export const GET_COURSE_DEPARTMENT="GET_COURSE_DEPARTMENT";
// export const GET_COURSE_CATEGORY = "GET_COURSE_CATEGORY";
// export const GET_COURSE_LEVEL = "GET_COURSE_LEVEL";
// export const GET_COURSE_EXAM_TYPE ="GET_COURSE_EXAM_TYPE";
export const GET_COURSE ="GET_COURSE";
export const GET_STUDENT="GET_STUDENT";
export const GET_STUDENT_DETAIL = "GET_STUDENT_DETAIL";
export const GET_APF_TASK_PLAN_QUERY_TEMPLETE="GET_APF_TASK_PLAN_QUERY_TEMPLETE"
export const GET_EXAM_RESULT="GET_EXAM_RESULT";
export const GET_RESULT_GRADE="GET_RESULT_GRADE";
export const GET_EXAM_RESULT_FILTER_SESSION = 'GET_EXAM_RESULT_FILTER_SESSION';
export const GET_EXAM_RESULT_FILTER_DEPARTMENT = "GET_EXAM_RESULT_FILTER_DEPARTMENT";
export const GET_EXAM_RESULT_FILTER_COURSE = "GET_EXAM_RESULT_FILTER_COURSE";
export const GET_EXAM_RESULT_FILTER_SEMISTER = "GET_EXAM_RESULT_FILTER_SEMISTER";
export const GET_DYNAMIC_FORM_DATA = "GET_DYNAMIC_FORM_DATA";
export const GET_SENSOR_DATA="GET_SENSOR_DATA";